import * as statera from '@vimscore/statera-client';
import {AccountSearchForm} from "../../../components/account-search/account-search.component";
import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {CreatePersonDialogComponent} from "./create-person-dialog/create-person-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-inspector',
  templateUrl: './inspector-page.component.html',
  styleUrls: ['./inspector-page.component.css']
})
export class InspectorPageComponent implements OnInit {
  accountSearchForm = new AccountSearchForm()

  constructor(private consoleService: statera.ConsoleService,
              private dialog: MatDialog,
              private router: Router,
              private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.accountSearchForm.accountInfo.valueChanges.forEach((account: statera.AccountInfo) => {
      this.router.navigate([account.personUuid], { relativeTo: this.route });
    })
  }

  showCreatePerson() {
    const self = this;

    this.dialog.open(CreatePersonDialogComponent, {
      data: {}
    }).afterClosed().subscribe({
      next(person: statera.PersonDto) {
        if (person.uuid) {
          self.router.navigate([person.uuid], { relativeTo: self.route });
        }
      }
    })
  }
}
