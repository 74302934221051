<mat-toolbar>
  <a mat-icon-button routerLink="../..">
    <mat-icon>arrow_back</mat-icon>
  </a>
  <span>Vis Exercise: {{exerciseUuid}}</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<div class="basic-container">
  <p>vis-exercise-page works!</p>
</div>
