import * as statera from '@vimscore/statera-client';
import {CapacityMinutes} from "../../utils/capacity-minutes";
import {duration2number} from "../../utils/duration";

/**
 * This only operates in whole minutes (for now).
 */
export class CapacityMinutesForm {
  strength: number = 0
  endurance: number = 0
  balance: number = 0
  flexibility: number = 0

  constructor(strength: number, endurance: number, balance: number, flexibility: number) {
    this.strength = strength;
    this.endurance = endurance;
    this.balance = balance;
    this.flexibility = flexibility;
  }

  toObject(): CapacityMinutes {
    return CapacityMinutes.fromMinutes(this.strength, this.endurance, this.balance, this.flexibility)
  }

  static fromDto(minutes: statera.CapacityMinutesDto): CapacityMinutesForm {
    return new CapacityMinutesForm(
      duration2number(minutes.strength),
      duration2number(minutes.endurance),
      duration2number(minutes.balance),
      duration2number(minutes.flexibility))
  }
}
