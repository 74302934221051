import {Injectable} from '@angular/core';
import {PlatformLocation} from '@angular/common';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable()
export class Settings {
  _4tuneHost = 'localhost:8101';
  _4tuneUrl = 'http://' + this._4tuneHost;
  stateraHost = 'localhost:8090';
  stateraUrl = 'http://' + this.stateraHost;

  private readonly localeSubject: BehaviorSubject<string>;
  private readonly _locale: Observable<string>;

  constructor(pl: PlatformLocation) {
    if (pl.protocol == 'https:') {
      this.stateraHost = pl.hostname.replace('console', 'statera');
      this.stateraUrl = pl.protocol + '//' + this.stateraHost;
      this._4tuneHost = pl.hostname.replace('console', '4tune');
      this._4tuneUrl = pl.protocol + '//' + this._4tuneHost;
    }

    this.localeSubject = new BehaviorSubject<string>("en_US");
    this._locale = this.localeSubject.asObservable();
  }

  get locale(): Observable<string> {
    return this._locale;
  }

  get localeValue(): string {
    return this.localeSubject.value;
  }
}
