<div class="basic-container">
  <p>
    Workouts from period: {{ from|date:'shortDate' }} - {{ to|date:'shortDate' }}.
  </p>

  <app-spin [active]="busy"></app-spin>

  <p *ngIf="!busy && workouts.length == 0">
    The user does not have any workouts.
  </p>

  <table *ngIf="workouts.length > 0" class="vs-table">
    <thead>
    <tr>
      <th>Date</th>
      <th>Inspect</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let w of workouts">
      <td class="text-right">{{w.createdDate|date:'medium'}}</td>
      <td><a [routerLink]="['workout', w.uuid]">Show</a></td>
    </tr>
    </tbody>
  </table>
</div>
