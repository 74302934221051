<mat-toolbar>
  <span>Spond</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<div class="basic-container">

  <mat-tab-group animationDuration="0ms"
                 [selectedIndex]="selectedIndex">
    <mat-tab label="Import Spond Data">
      <input class="form-control" #membersFileInput
             type="file"
             [multiple]="false"
             accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"/>

      <button mat-button type="button" class="mat-raised-button mat-primary" (click)="importSpondMembers(membersFileInput)">
        Import Spond Members
        <app-spin [active]="spondMembers.busy"></app-spin>
      </button>
      <app-job-response-table [response]="spondMembers.jobResponse"></app-job-response-table>
    </mat-tab>
    <mat-tab label="Import Email Mappings">
      <input class="form-control" #mappingsFileInput
             type="file"
             [multiple]="false"
             accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"/>

      <button mat-button type="button" class="mat-raised-button mat-primary" (click)="importEmailMappings(mappingsFileInput)">
        Import Email Mappings
        <app-spin [active]="emailMappings.busy"></app-spin>
      </button>
      <app-job-response-table [response]="emailMappings.jobResponse"></app-job-response-table>
    </mat-tab>
  </mat-tab-group>

</div>
