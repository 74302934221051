import {Component, OnInit} from '@angular/core';
import {QRCodeElementType} from "angularx-qrcode";
import {SafeUrl} from "@angular/platform-browser";
import * as URI from "uri-js";

@Component({
  selector: 'app-qr-code-page',
  templateUrl: './qr-code-page.component.html',
  styleUrls: ['./qr-code-page.component.css']
})
export class QrCodePageComponent implements OnInit {
  baseUrl: string = "https://vimscore.com";
  url: string;
  elementType: QRCodeElementType = "svg";
  qrCodeSrc!: SafeUrl
  fileName: string
  width: number = 500;

  enableGa: boolean = true

  utmId: string = "";
  utmSource: string = "";
  utmMedium: string = "";
  utmCampaign: string = "";
  utmTerm: string = "";
  utmContent: string = "";

  constructor() {
    this.onBaseUrl();
  }

  ngOnInit(): void {
    this.baseUrl = "https://www.vimscore.com";

    // this.baseUrl = "https://www.vimscore.com/n-bar";
    // this.utmSource = "n-bar";
    // this.utmMedium = "box";

    this.onBaseUrl();
  }

  onQrCodeUrlChange(url: SafeUrl) {
    this.qrCodeSrc = url
  }

  download(parent: any): void {
    console.log("parent", parent);

    let parentElement = null

    if (this.elementType == "img") {
      parentElement = parent.qrcElement
        .nativeElement
        .querySelector("img")
        .src;
    }

    if (parentElement) {
      let blobData = QrCodePageComponent.convertBase64ToBlob(parentElement)
      const blob = new Blob([blobData], { type: "image/png" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      link.download = this.fileName;
      link.click()
    }
  }

  private static convertBase64ToBlob(Base64Image: string) {
    const parts = Base64Image.split(";base64,")
    const imageType = parts[0].split(":")[1]
    const decodedData = window.atob(parts[1])
    const uInt8Array = new Uint8Array(decodedData.length)
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    return new Blob([uInt8Array], { type: imageType })
  }

  onBaseUrl() {
    if (this.enableGa) {
      const components = URI.parse((this.baseUrl || "").trim());

      let fileName = "qr-code";

      let q = components.query || "";

      if (this.utmId.length > 0) {
        fileName += "-utm_id=" + this.utmId
        q += "&utm_id=" + URI.escapeComponent(this.utmId)
      }
      if (this.utmSource.length > 0) {
        fileName += "-utm_source=" + this.utmSource
        q += "&utm_source=" + URI.escapeComponent(this.utmSource)
      }
      if (this.utmMedium.length > 0) {
        fileName += "-utm_medium=" + this.utmMedium
        q += "&utm_medium=" + URI.escapeComponent(this.utmMedium)
      }
      if (this.utmCampaign.length > 0) {
        fileName += "-utm_campaign=" + this.utmCampaign
        q += "&utm_campaign=" + URI.escapeComponent(this.utmCampaign)
      }
      if (this.utmTerm.length > 0) {
        fileName += "-utm_term=" + this.utmTerm
        q += "&utm_term=" + URI.escapeComponent(this.utmTerm)
      }
      if (this.utmContent.length > 0) {
        fileName += "-utm_content=" + this.utmContent
        q += "&utm_content=" + URI.escapeComponent(this.utmContent)
      }

      if (components.query === undefined) {
        components.query = q.substring(1)
      } else {
        components.query = components.query + q;
      }

      this.url = URI.serialize(components);

      this.fileName = fileName;
    } else {
      this.url = (this.baseUrl || "").trim();
      this.fileName = "qr-code";
    }
  }
}
