import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";

@Component({
  selector: 'app-loader[observable]',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  done = false;

  @Input()
  observable: Observable<any> | Promise<any>

  constructor() {}

  ngOnInit(): void {
    const self = this;
    // console.log("Loading: observable: ", this.observable)

    if (this.observable instanceof Observable) {
      this.observable.subscribe(
        (_: any) => {
          self.done = true
        },
        (_: any) => {
          self.done = true
        },
        () => {
          self.done = true
        }
      )
    } else if (this.observable instanceof Promise) {
      this.observable.finally(() => {
        self.done = true;
      })
    }
  }
}
