import {Component, OnInit} from '@angular/core';
import * as statera from '@vimscore/statera-client';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-inspect-activities',
  templateUrl: './inspect-activities.component.html',
  styleUrls: ['./inspect-activities.component.css']
})
export class InspectActivitiesComponent implements OnInit {
  private personUuid: string
  busy: boolean = false
  activities: statera.ActivitySessionDto[] = null;
  from: string = '2020-01-01'
  to: string = '2029-12-31'

  constructor(private route: ActivatedRoute,
              private activityService: statera.ActivityService) { }

  ngOnInit(): void {
    this.personUuid = this.route.snapshot.paramMap.get('personUuid');
    this.fetchData();
  }

  fetchData() {
    const self = this;

    this.busy = true;
    self.activityService.getActivityCalendar(this.personUuid, this.from, this.to).subscribe({
      next(activities: statera.ActivitySessionDto[]) {
        activities.sort((a, b) => -a.createdDate.localeCompare(b.createdDate))
        self.activities = activities;
      },
      async complete() {
        self.busy = false;
      },
    })
  }
}
