import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from "rxjs";
import {Settings} from "./settings";

@Injectable()
export class AcceptLanguageHttpInterceptor implements HttpInterceptor {

  private readonly settings: Settings;

  constructor(settings: Settings) {
    this.settings = settings;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const acceptLanguage = this.settings.localeValue;

    if (acceptLanguage) {
      req = req.clone({
        headers: req.headers.set("Accept-Language", acceptLanguage)
      });
    }

    return next.handle(req);
  }
}

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: true,
    });

    return next.handle(req);
  }
}
