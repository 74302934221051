// Angular
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDialogModule} from "@angular/material/dialog";
import {MatDividerModule} from "@angular/material/divider";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatRadioModule} from "@angular/material/radio";
import {MatTabsModule} from "@angular/material/tabs";
import {MatToolbarModule} from "@angular/material/toolbar";
import {NgModule} from "@angular/core";
import {RefreshDialogComponent} from './challenge-page/refresh-dialog.component';
import {RouterModule} from "@angular/router";

// App
import {AddChallengeDialog} from "./challenges-page/add-challenge-dialog.component";
import {AddChallengeMemberDialog} from "./challenge-page/add-member-dialog.component";
import {AddTeamDialogComponent} from "./challenge-page/add-team-dialog.component";
import {ChallengePageComponent} from "./challenge-page/challenge-page.component";
import {ChallengeTeamPageComponent} from './challenge-team-page/challenge-team-page.component';
import {ChallengeWeekPageComponent} from './challenge-week-page/challenge-week-page.component';
import {ChallengesPageComponent} from "./challenges-page/challenges-page.component";
import {ConsoleComponentsModule} from "../../components/console-components.module";
import {ConsolePipesModule} from "../../pipes/console-pipes.module";
import {EditChallengeDetailsDialogComponent} from './challenge-page/edit-challenge-details-dialog/edit-challenge-details-dialog.component';
import {EditChallengeRulesDialogComponent} from './challenge-page/edit-challenge-rules-dialog/edit-challenge-rules-dialog.component';
import {EditTeamRulesDialogComponent} from './challenge-team-page/edit-team-rules-dialog/edit-team-rules-dialog.component';

@NgModule({
  declarations: [
    AddChallengeDialog,
    AddChallengeMemberDialog,
    AddTeamDialogComponent,
    ChallengePageComponent,
    ChallengeTeamPageComponent,
    ChallengeWeekPageComponent,
    ChallengesPageComponent,
    EditChallengeDetailsDialogComponent,
    EditChallengeRulesDialogComponent,
    EditTeamRulesDialogComponent,
    RefreshDialogComponent,
  ],
  exports: [
    ChallengeWeekPageComponent
  ],
  imports: [
    ConsoleComponentsModule,
    ConsolePipesModule,

    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatRadioModule,
    MatTabsModule,
    MatToolbarModule,
    ReactiveFormsModule,
    RouterModule,
  ]
})
export class ChallengeModule {
}
