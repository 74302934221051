import {Location} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import * as statera from '@vimscore/statera-client';

@Component({
  selector: 'app-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.scss']
})
export class PeriodComponent implements OnInit {

  personUuid: string;
  periodUuid: string;
  period: statera.VimPeriodDto;
  readonly selfLink: string
  weeks: statera.VimWeekDto[];

  constructor(private route: ActivatedRoute, private router: Router, private location: Location) {
    this.selfLink = this.location.prepareExternalUrl(this.location.path());
  }

  ngOnInit(): void {
    this.personUuid = this.route.snapshot.paramMap.get('personUuid');
    this.periodUuid = this.route.snapshot.paramMap.get('periodUuid');

    this.route.data.subscribe((data: {
      period: statera.VimPeriodDto,
    }) => {
      this.period = data.period;
      this.weeks = this.period.weeks.reverse();
    });
  }
}
