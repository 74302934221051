import {Component, Input} from '@angular/core';
import * as statera from '@vimscore/statera-client';

@Component({
  selector: 'app-job-response-table',
  templateUrl: './job-response-table.component.html',
  styleUrls: ['./job-response-table.component.css']
})
export class JobResponseTableComponent {

  @Input()
  response: statera.JobResponseDto

  constructor() {}
}
