import {Component, OnInit} from '@angular/core';
import {DateRange} from "@angular/material/datepicker";
import {HttpErrorResponse} from "@angular/common/http";
import {saveAs} from 'file-saver';
import * as statera from '@vimscore/statera-client';
import {DateAdapter} from "@angular/material/core";

@Component({
  selector: 'app-activities-report',
  templateUrl: './activities-report.component.html',
})
export class ActivitiesReportComponent implements OnInit {

  range: DateRange<Date>

  humanMode: boolean = false
  busy: String
  output: String

  constructor(private consoleService: statera.ConsoleService, private dateAdapter: DateAdapter<Date>) {
    let to = new Date();
    let from = new Date(new Date().setDate(to.getDate() - 7));
    this.range = new DateRange(from, to)
  }

  ngOnInit(): void {
  }

  selectionChanged($event: DateRange<Date>) {
    this.range = $event
  }

  setCurrentWeek() {
    this.range = this.createWeekRange(0)
    return false;
  }

  setPreviousWeek() {
    this.range = this.createWeekRange(-7)
    return false;
  }

  createWeekRange(days: number): DateRange<Date> {
    let today = this.dateAdapter.today()
    today.setHours(0, 0, 0, 0)

    let dow = this.dateAdapter.getDayOfWeek(today);
    let delta = this.dateAdapter.getFirstDayOfWeek() - dow + days;

    let start = this.dateAdapter.addCalendarDays(today, delta)
    let end = this.dateAdapter.addCalendarDays(today, delta + 6)

    return new DateRange(start, end)
  }

  run() {
    const req: statera.ActivityReportRequestDto = {
      from: this.range.start.toISOString(),
      to: this.range.end.toISOString(),
      humanMode: this.humanMode
    }

    const self = this;

    self.busy = 'activities';
    self.output = null;

    this.consoleService.activityReport(req, "response").subscribe({
      next(value) {
        let blob = value.body;

        let filename: string = null
        const cd = value.headers.get("content-disposition");
        if (cd) {
          const m = cd.match("filename=\"\([^\"]*\)\"");
          if (m) {
            filename = m && m[1];
          }
        }

        saveAs(blob, filename);
      },
      error(error) {
        self.busy = null;
        if (error instanceof HttpErrorResponse) {
          self.output = error.message;
        } else {
          self.output = "Unknown error"
        }
      },
      complete() {
        self.busy = null;
        self.output = null;
      },
    })
  }
}
