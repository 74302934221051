<form>
  <p>
    <mat-checkbox [(ngModel)]="req.dumpCells" name="dumpCells">Dump cells</mat-checkbox>
  </p>
  <p>
    <mat-checkbox [(ngModel)]="req.dumpCellExercises" name="dumpCellExercises">
      Dump cell exercises
    </mat-checkbox>
  </p>
  <p>
    <mat-checkbox [(ngModel)]="req.dumpExercises" name="dumpExercises">Dump exercises</mat-checkbox>
  </p>
  <p>
    <mat-checkbox [(ngModel)]="req.filterExercises" name="filterExercises">Filter exercises</mat-checkbox>
  </p>
  <button
    mat-button type="submit"
    [disabled]="busy"
    (click)="runDump4tune()">
    Create report

    <app-spin [active]="busy"></app-spin>
  </button>
  <span *ngIf="output">{{ output }}</span>
</form>
