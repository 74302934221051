import * as statera from '@vimscore/statera-client';
import structuredClone from "@ungap/structured-clone";
import {ActivatedRoute, Router} from "@angular/router";
import {AddChallengeMemberDialog} from "./add-member-dialog.component";
import {AddTeamDialogComponent} from "./add-team-dialog.component";
import {Component, OnInit} from '@angular/core';
import {ConfirmDialogComponent} from "../../../components/confirm-dialog/confirm-dialog.component";
import {EditChallengeDetailsDialogComponent} from "./edit-challenge-details-dialog/edit-challenge-details-dialog.component";
import {EditChallengeRulesDialogComponent} from "./edit-challenge-rules-dialog/edit-challenge-rules-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {RefreshDialogComponent} from "./refresh-dialog.component";
import {ReportRunningComponent, runReport} from "../../report/report-utils";
import {TabIndex} from "src/app/utils/fragment";
import {YearWeek} from 'src/app/utils/year-week';
import {duration2number} from "src/app/utils/duration";
import {errorDialog} from "src/app/utils/dialog";
import {firstValueFrom, Observable, switchMap} from "rxjs";

@Component({
  selector: 'app-challenge-page',
  templateUrl: './challenge-page.component.html',
  styleUrls: ['./challenge-page.component.css']
})
export class ChallengePageComponent implements OnInit {
  private challengeUuid: string;

  challenge: statera.ChallengeDetailsDto
  teams: { [key: string]: statera.ChallengeTeamDto }

  yearWeek = YearWeek.today();

  tab: TabIndex

  reportState: ReportRunningComponent = {
    busy: false,
    output: null
  }

  constructor(private route: ActivatedRoute,
              private router: Router,
              private challengeService: statera.ChallengeService,
              private dialog: MatDialog) {
    this.tab = new TabIndex(this.route, this.router);
  }

  ngOnInit(): void {
    const self = this
    this.challengeUuid = this.route.snapshot.paramMap.get('challengeUuid');
    this.route.data.subscribe((data: {
      challenge: statera.ChallengeDetailsDto
    }) => {
      self.setChallenge(data.challenge)
    });
  }

  private setChallenge(challenge: statera.ChallengeDetailsDto) {
    challenge.teams.sort((a, b) => a.name.localeCompare(b.name));
    challenge.members.sort((a, b) => a.name.localeCompare(b.name));
    this.challenge = challenge
    this.teams = challenge.teams.reduce((map, t) => (map[t.uuid] = t) ? map : map, {});
  }

  progress(m: statera.ChallengeMemberWeekDto, capacity: "strength" | "endurance" | "balance" | "flexibility"): number {
    let given: statera.DurationDto;
    if (capacity == "strength") {
      given = m.given.strength
    } else if (capacity == "endurance") {
      given = m.given.endurance
    } else if (capacity == "balance") {
      given = m.given.balance
    } else if (capacity == "flexibility") {
      given = m.given.flexibility
    }

    let done: statera.DurationDto;
    if (capacity == "strength") {
      done = m.done.strength
    } else if (capacity == "endurance") {
      done = m.done.endurance
    } else if (capacity == "balance") {
      done = m.done.balance
    } else if (capacity == "flexibility") {
      done = m.done.flexibility
    }

    return 100 * duration2number(done) / duration2number(given);
  }

  openAddMemberDialog() {
    const self = this;

    this.dialog.open(AddChallengeMemberDialog, {
      data: {
        challenge: this.challenge,
      }
    }).afterClosed().subscribe(this.refreshObserver(self))
  }

  openCreateTeamDialog() {
    const self = this;

    this.dialog.open(AddTeamDialogComponent, {
      data: {
        challenge: this.challengeUuid,
      }
    }).afterClosed().subscribe({
      next(res: statera.ChallengeDetailsDto | boolean) {
        if (typeof res !== "object") {
          return;
        }

        self.setChallenge(res);
      }
    })
  }

  async openEditDetailsDialog() {
    const self = this;

    let getChallengeDetails: (any) => Observable<statera.ChallengeDetailsDto> = _ => self.challengeService.getChallengeDetails(self.challengeUuid);

    const data = {
      challenge: this.challenge,
    }
    const res = await this.dialog.open(EditChallengeDetailsDialogComponent, { data })
      .afterClosed().pipe(
        switchMap(getChallengeDetails)
      );

    this.setChallenge(await firstValueFrom(res))
  }

  openEditRulesDialog() {
    const self = this;

    this.dialog.open(EditChallengeRulesDialogComponent, {
      data: {
        challenge: this.challenge,
      }
    }).afterClosed().subscribe(this.refreshObserver(self));
  }

  openRefreshDialog() {
    const self = this;
    this.dialog.open(RefreshDialogComponent, {
      data: {
        challenge: this.challenge,
      }
    }).afterClosed().subscribe({
      next(res: statera.ChallengeDetailsDto | boolean) {
        if (typeof res !== "object") {
          return;
        }

        self.setChallenge(res);
      }
    })
  }

  openDeleteDialog() {
    const self = this;
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Delete challenge",
        content: "Are you sure you want to remove the challenge? All data related to this challenge will be removed.",
      }
    }).afterClosed().subscribe({
      next(res) {
        if (res !== true) {
          return;
        }

        self.challengeService.deleteChallenge(self.challengeUuid).subscribe({
          next() {
            self.router.navigate([".."], { relativeTo: self.route });
          }
        });
      }
    })
  }

  deleteMember(member: statera.ChallengeMemberDto) {
    const self = this;
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Delete member",
        content: `Are you sure you want to remove: ${member.name}?`,
      }
    }).afterClosed().subscribe({
      next(res) {
        if (res !== true) {
          return;
        }

        self.challengeService.leaveChallenge({
          challenge: self.challengeUuid,
          person: member.person,
        }).subscribe({
          async next() {
            const challenge = await self.challengeService.getChallengeDetails(self.challengeUuid).toPromise()
            self.setChallenge(challenge)
          },
          error: errorDialog(self.dialog)
        });
      }
    })
  }

  setAdminFlag(m: statera.ChallengeMemberDto, adminFlag: boolean) {
    const self = this;
    const dto = structuredClone(m)
    dto.adminFlag = adminFlag;
    this.challengeService.updateMember(this.challengeUuid, m.person, dto)
      .subscribe({
        next(res: statera.ChallengeDetailsDto | boolean) {
          if (typeof res !== "object") {
            return;
          }

          self.setChallenge(res);
        }
      });
  }

  createReport() {
    this.challengeService.getChallengeReport(this.challengeUuid, {
      humanMode: false,
    }, 'events', true).subscribe(runReport('challenge', this.reportState));
  }

  private refreshObserver(self: this) {
    return {
      next() {
        self.challengeService.getChallengeDetails(self.challengeUuid).subscribe({
          next(res: statera.ChallengeDetailsDto) {
            self.setChallenge(res);
          }
        })
      }
    };
  }
}
