<mat-toolbar>
  <a mat-icon-button routerLink="../..">
    <mat-icon>arrow_back</mat-icon>
  </a>
  <span>Person: {{personUuid}}</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<app-button-row>
  <button class="mat-button mat-primary" (click)="deleteActivity()">Delete activity</button>
</app-button-row>

<div class="basic-container">

  <h2>Activity: {{activity.createdDate|date:'medium'}}</h2>

  <dl class="vs-dl" style="width: 700px">
    <dt>Uuid</dt>
    <dd>{{activity.uuid}} <app-copy-to-clipboard></app-copy-to-clipboard>
    </dd>
    <dt>Start</dt>
    <dd>{{activity.start|date:'mediumTime'}}</dd>
    <dt>Stop</dt>
    <dd>{{activity.stop|date:'mediumTime'}}</dd>
    <dt>Duration</dt>
    <dd>{{activity.duration|duration:'pretty'}}</dd>
    <dt>Exercise count</dt>
    <dd>{{activity.parts.length}}</dd>
    <dt>Workout</dt>
    <dd>
      <a routerLink="../../workout/{{ activity.basedOnWorkout }}"
         [state]="{activity: activity.uuid}">{{ activity.basedOnWorkout }}</a>
    </dd>
  </dl>

  <h2>Minutes</h2>

  <dl class="vs-dl" style="width: 700px">
    <dt>Strength</dt>
    <dd>{{ minutes.strength|duration }}</dd>
    <dt>Endurance</dt>
    <dd>{{ minutes.endurance|duration }}</dd>
    <dt>Balance</dt>
    <dd>{{ minutes.balance|duration }}</dd>
    <dt>Flexibility</dt>
    <dd>{{ minutes.flexibility|duration }}</dd>
  </dl>

  <h2>Exercises</h2>

  <table class="vs-table full-width full-width-scroll">
    <thead>
    <tr style="vertical-align: top">
      <th class="text-right"></th>
      <th></th>
      <th>Part</th>
      <th>Completed</th>
      <th>Exercise duration</th>
      <th>Timer</th>
      <th>Repetitions</th>
      <th class="text-left">Name</th>
    </tr>
    </thead>
    <tbody *ngFor="let p of activity.parts; let i = index">
    <tr>
      <td class="text-right">#{{ i + 1 }}</td>
      <td class="capacity">
        <app-capacity-icon [capacity]="p.capacity"></app-capacity-icon>
        {{p.capacity|initialCase}}
      </td>
      <td>{{p.part|enum2string|initialCase}}</td>
      <td class="text-center">
        <mat-icon *ngIf="p.completed" [inline]="true">check_box</mat-icon>
        <mat-icon *ngIf="!p.completed" [inline]="true">check_box_outline_blank</mat-icon>
      </td>
      <td class="text-right">{{p.exerciseDuration|duration}}</td>
      <td class="text-right">{{p.timerDuration|duration}}</td>
      <td class="text-center">{{p.repetitions}}</td>
      <td>{{p.name}}</td>
    </tr>
    <tr *ngFor="let wl of p.weightSets; let setIndex = index">
      <td colspan="7"></td>
      <td>Set #{{ setIndex + 1 }}: {{wl.repetitions}} x {{ wl.weight / 1000 }} kg</td>
    </tr>
    </tbody>
  </table>
</div>
