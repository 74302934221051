import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AnimationItem, AnimationSegment} from 'lottie-web';
import {AnimationOptions, BMEnterFrameEvent} from 'ngx-lottie';
import * as statera from '@vimscore/statera-client';

@Component({
    selector: 'app-animation-page',
    templateUrl: './animation-page.component.html',
    styleUrls: ['./animation-page.component.css']
})
export class AnimationPageComponent implements OnInit {

    exerciseDescriptionId: string;
    animation: statera.AnimationDto;

    options: AnimationOptions = {
        autoplay: true,
        loop: false,
    };
    lottieStyles: Partial<CSSStyleDeclaration> = {
        maxWidth: '600px',
        margin: '0 auto',
        backgroundColor: 'lightgrey'
    };
    animationItem: AnimationItem;
    events: string[] = [];

    constructor(private route: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.exerciseDescriptionId = this.route.snapshot.paramMap.get('exerciseDescriptionId');

        this.route.data.subscribe((data: {
            animation: statera.AnimationDto,
        }) => {
            this.animation = data.animation;

            this.options = {
                ...this.options,
                path: data.animation.lottie
            };
        });
    }

    animationCreated(animationItem: AnimationItem): void {
        console.log('animationCreated', animationItem);
        this.animationItem = animationItem;
    }

    segmentStart(segment: AnimationSegment): void {
        this.events.push('segment start');
        console.log('segment start', segment);
    }

    enterFrame(event: BMEnterFrameEvent): void {
        // console.log("enter frame", event);
    }

    play() {
        if (!this.animationItem || !this.animationItem.isLoaded) {
            return;
        }

        this.animationItem.goToAndStop(0);
        this.animationItem.play();
    }
}
