import * as domain from 'src/app/utils/domain';
import * as statera from '@vimscore/statera-client';
import {Component} from '@angular/core';
import {busyDialog} from "../../../../utils/dialog";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-create-person-dialog',
  templateUrl: './create-person-dialog.component.html',
  styleUrls: ['./create-person-dialog.component.css']
})
export class CreatePersonDialogComponent {
  person: statera.CreatePersonReqDto
  busy = false
  error: string

  readonly genders = domain.genders
  readonly activityLevels = domain.activityLevels
  readonly ages = domain.ages

  constructor(private consoleService: statera.ConsoleService,
              readonly dialogRef: MatDialogRef<CreatePersonDialogComponent>) {
    this.person = {
      name: null,
      dateOfBirth: null,
      gender: null,
      activityLevel: null,
      assessment: {
        strength: 20,
        endurance: 20,
        balance: 20,
        flexibility: 20,
        testType: statera.TestType.SelfTest,
      }
    }
  }

  save() {
    this.busy = true
    this.consoleService.createPerson(this.person)
      .subscribe(busyDialog(this));
  }
}
