<app-button-row>
  <button class="mat-button mat-primary" (click)="showEditGoalsDialog()">Edit goals</button>
</app-button-row>

<div class="basic-container">
  <h2>Goal</h2>
  <dl class="vs-dl vs-dl-50-50" style="width: 500px">
    <dt>Updated date</dt>
    <dd>{{ goals.updatedDate | date:'medium' }}</dd>
    <dt>Activity minutes</dt>
    <dd>{{ goals.activityMinutes ? goals.activityMinutes : "Not set" }}</dd>
    <dt>Activity count</dt>
    <dd>{{ goals.activityCount ? goals.activityCount : "Not set" }}</dd>
    <dt>Strength minutes</dt>
    <dd>{{ goals.strengthMinutes ? goals.strengthMinutes : "Not set"}}</dd>
    <dt>Strength activities</dt>
    <dd>{{ goals.strengthActivities ? goals.strengthActivities : "Not set" }}</dd>
    <dt>Endurance minutes</dt>
    <dd>{{ goals.enduranceMinutes ? goals.enduranceMinutes : "Not set"}}</dd>
    <dt>Endurance activities</dt>
    <dd>{{ goals.enduranceActivities ? goals.enduranceActivities : "Not set" }}</dd>
    <dt>Balance minutes</dt>
    <dd>{{ goals.balanceMinutes ? goals.balanceMinutes : "Not set"}}</dd>
    <dt>Balance activities</dt>
    <dd>{{ goals.balanceActivities ? goals.balanceActivities : "Not set" }}</dd>
    <dt>Flexibility minutes</dt>
    <dd>{{ goals.flexibilityMinutes ? goals.flexibilityMinutes : "Not set" }}</dd>
    <dt>Flexibility activities</dt>
    <dd>{{ goals.flexibilityActivities ? goals.flexibilityActivities : "Not set" }}</dd>
  </dl>
</div>
