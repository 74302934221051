import * as statera from '@vimscore/statera-client';
import {AddChallengeDialog} from "./add-challenge-dialog.component";
import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-challenge-frontpage',
  templateUrl: './challenges-page.component.html',
  styleUrls: ['./challenges-page.component.css']
})
export class ChallengesPageComponent implements OnInit {

  challenges: Promise<Array<statera.ChallengeDto>> | null = null

  constructor(private challengeService: statera.ChallengeService,
              private dialog: MatDialog) {}

  ngOnInit(): void {
    this.challenges = this.fetch();
  }

  private async fetch(): Promise<Array<statera.ChallengeDto>> {
    const challenges: Array<statera.ChallengeDto> = await this.challengeService.getChallenges(true).toPromise();
    challenges.sort((a, b) => a.name.localeCompare(b.name));
    return challenges;
  }

  async openAddChallengeDialog() {
    const res = this.dialog.open(AddChallengeDialog, {}).afterClosed()

    await firstValueFrom(res);

    this.challenges = this.fetch();
  }
}
