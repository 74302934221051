import * as dialogs from "../../../dialogs";
import * as statera from '@vimscore/statera-client';
import {ActivatedRoute, Router} from '@angular/router';
import {BusyComponent, busyComponent} from "src/app/utils/dialog";
import {Component, OnInit} from '@angular/core';
import {EditEmailAddressDialogComponent} from "./edit-email-address-dialog/edit-email-address-dialog.component"
import {EditPersonDialogComponent} from "./edit-person-dialog/edit-person-dialog.component";
import {EditPhoneNumberDialogComponent} from "./edit-phone-number-dialog/edit-phone-number-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {firstValueFrom, switchMap, tap} from "rxjs";

@Component({
  selector: 'app-inspect-profile',
  templateUrl: './inspect-profile.component.html',
  styleUrls: ['./inspect-profile.component.css']
})
export class InspectProfileComponent implements OnInit {

  person: statera.PersonDto;
  flags: Set<String>;
  healthProfile: statera.HealthProfileDto;
  prescription: statera.PrescriptionDto;

  allPersonFlags: Array<String> = Object.values(statera.PersonFlag);
  flagsState: BusyComponent = { busy: false, error: null }

  constructor(private route: ActivatedRoute,
              private dialog: MatDialog,
              private router: Router,
              private personService: statera.PersonService,
              private consoleService: statera.ConsoleService) {}

  ngOnInit(): void {
    this.route.data.subscribe((data: {
      personInfo: statera.PersonInfoDto
    }) => {
      this.onInfo(data.personInfo);
    });
  }

  private refreshPerson() {
    const self = this;

    self.personService.getPerson(self.person.uuid).subscribe({
      next(res: statera.PersonDto) {
        self.person = res
      }
    })
  }

  showEditPersonDialog() {
    const self = this;

    this.dialog.open(EditPersonDialogComponent, {
      data: {
        person: self.person,
      }
    }).afterClosed().subscribe({
      next(res: boolean) {
        self.refreshPerson();
      }
    })
  }

  addPhoneNumberDialog() {
    const self = this;

    this.dialog.open(EditPhoneNumberDialogComponent, {
      data: {
        person: self.person,
      }
    }).afterClosed().subscribe({
      next(res: boolean) {
        self.refreshPerson();
      }
    })
  }

  addEmailAddressDialog() {
    const self = this;

    this.dialog.open(EditEmailAddressDialogComponent, {
      data: {
        person: self.person,
      }
    }).afterClosed().subscribe({
      next(res: boolean) {
        self.refreshPerson();
      }
    })
  }

  addExternalActivityDialog() {
    const self = this;

    this.dialog.open(dialogs.CreateExternalActivityDialogComponent, {
      data: {
        person: self.person,
      }
    }).afterClosed().subscribe({
      next(res: boolean) {
        self.refreshPerson();
      }
    })
  }

  async setPersonFlag(feature: String, state: boolean) {
    const ft = feature as statera.PersonFlag;

    console.log("ft", ft, "state", state);

    const res = (state
      ? this.consoleService.enablePersonFlag(this.person.uuid, ft)
      : this.consoleService.disablePersonFlag(this.person.uuid, ft))
      .pipe(
        switchMap(_ => this.consoleService.personInfo(this.person.uuid)),
        tap(busyComponent(this.flagsState))
      );

    const info = await firstValueFrom(res)
    this.onInfo(info);
  }

  private onInfo(personInfo: statera.PersonInfoDto) {
    this.person = personInfo.person;
    this.flags = new Set<String>(personInfo.personFlags);
    this.healthProfile = personInfo.healthProfile;

    let prescription = personInfo.prescriptions
      .sort((a, b) => -a.createdDate.localeCompare(b.createdDate));
    if (prescription.length > 0) {
      this.prescription = prescription[0];
    }
  }

  hasFlag(f: String): boolean {
    return this.flags.has(f)
  }
}
