import {Pipe, PipeTransform} from '@angular/core';
import {WorkoutPart} from '@vimscore/statera-client';

const map = {};

@Pipe({
  name: 'initialCase'
})
export class InitialCasePipe implements PipeTransform {

  transform(value: string): string {
    if (typeof value !== "string") {
      return value;
    }
    const s = value as string
    if (s.length == 0) {
      return s;
    }

    return s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase();
  }
}
