import * as statera from "@vimscore/statera-client"
import structuredClone from "@ungap/structured-clone"
import { Component, Inject } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"
import { busyDialog } from "../../../../../utils/dialog"

@Component({
  selector: "app-email-address-number-dialog",
  templateUrl: "./edit-email-address-dialog.component.html",
  styleUrls: ["./edit-email-address-dialog.component.css"],
})
export class EditEmailAddressDialogComponent {
  person: statera.PersonDto
  original: statera.PersonDto
  req: statera.AddEmailAddressConsoleReqDto = {}
  error: string
  busy: boolean

  constructor(private consoleService: statera.ConsoleService,
              readonly dialogRef: MatDialogRef<EditEmailAddressDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.busy = false
    this.original = structuredClone(data.person)
    this.person = structuredClone(data.person)

    this.req = {
      verified: true,
    }
  }

  save() {
    const self = this

    self.busy = true
    self.consoleService.addEmailAddress(self.original.uuid, this.req)
      .subscribe(busyDialog(self))
  }
}
