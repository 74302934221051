<mat-toolbar>
  <a mat-icon-button routerLink="../..">
    <mat-icon>arrow_back</mat-icon>
  </a>
  <span>Week: {{ yearWeek }}</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<app-button-row>
  <a class="mat-button mat-primary" [routerLink]="['..', prevWeek.toString()]">Prev week</a>
  <a class="mat-button mat-primary" [routerLink]="['..', nextWeek.toString()]">Next week</a>
</app-button-row>

<mat-tab-group animationDuration="0ms"
               [selectedIndex]="tab.index"
               (selectedIndexChange)="tab.onChange($event)">
  <mat-tab label="Members">
    <div class="basic-container">
      <table class="vs-table full-width-scroll vs-grey-odd">
        <thead>
        <tr>
          <th colspan="2">Name</th>
          <th colspan="3">Strength</th>
          <th colspan="3">Endurance</th>
          <th colspan="3">Balance</th>
          <th colspan="3">Flexibility</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let m of members">
          <td>
            <a mat-button class="mat-primary" routerLink="/inspector/{{ m.person }}">
              {{ m.name }}
            </a>
          </td>
          <td>
            <mat-icon *ngIf="m.completed" inline="true">check</mat-icon>
          </td>
          <ng-container *ngFor="let c of ['strength', 'endurance', 'balance', 'flexibility']">
            <td class="given">{{ m.given[c]|duration }}</td>
            <td class="done">{{ m.done[c]|duration}}</td>
            <td class="progress" *ngFor="let pct of [(m.done[c]|duration2float) / (m.given[c]|duration2float)]">
              <mat-progress-bar [value]="pct * 100"></mat-progress-bar> {{ pct | percent }}
            </td>
          </ng-container>
        </tr>
        </tbody>
      </table>
    </div>
  </mat-tab>

  <mat-tab label="Teams">
    <div class="basic-container">
      <table class="vs-table full-width-scroll vs-grey-odd">
        <thead>
        <tr>
          <th colspan="2">Team</th>
          <th colspan="3">Strength</th>
          <th colspan="3">Endurance</th>
          <th colspan="3">Balance</th>
          <th colspan="3">Flexibility</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let t of teams">
          <td>
            <a mat-button class="mat-primary" routerLink="../../team/{{ t.team }}">
              {{ t.name }}
            </a>
          </td>
          <td>
            <mat-icon *ngIf="t.completed" inline="true">check</mat-icon>
          </td>
          <ng-container *ngFor="let c of ['strength', 'endurance', 'balance', 'flexibility']">
            <td class="given">{{ t.given[c]|duration }}</td>
            <td class="done">{{ t.done[c]|duration}}</td>
            <td class="progress" *ngFor="let pct of [(t.done[c]|duration2float) / (t.given[c]|duration2float)]">
              <mat-progress-bar [value]="pct * 100"></mat-progress-bar>&nbsp;{{ pct | percent }}
            </td>
          </ng-container>
        </tr>
        </tbody>
      </table>
    </div>
  </mat-tab>
</mat-tab-group>
