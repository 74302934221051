import * as domain from "../../../../../utils/domain";
import * as statera from '@vimscore/statera-client';
import structuredClone from '@ungap/structured-clone';
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {busyDialog} from "../../../../../utils/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";

class EditPersonForm {
  public readonly name: FormControl
  public readonly dateOfBirth: FormControl
  public readonly age: FormControl
  public readonly gender: FormControl
  public readonly activityLevel: FormControl
  public readonly injuries: FormControl

  public readonly group: FormGroup

  constructor(person: statera.PersonDto) {
    this.name = new FormControl(person.name, [Validators.required])
    this.dateOfBirth = new FormControl(person.dateOfBirth, [])
    this.age = new FormControl(person.age, [])
    this.gender = new FormControl(person.gender, [Validators.required])
    this.activityLevel = new FormControl(person.activityLevel, [Validators.required])
    this.injuries = new FormControl(person.injuries, [Validators.required])

    this.group = new FormGroup({
      name: this.name,
      dateOfBirth: this.dateOfBirth,
      age: this.age,
      gender: this.gender,
      activityLevel: this.activityLevel,
      injuries: this.injuries,
    })
  }
}

@Component({
  selector: 'app-edit-person-dialog',
  templateUrl: './edit-person-dialog.component.html',
  styleUrls: ['./edit-person-dialog.component.css']
})
export class EditPersonDialogComponent {
  original: statera.PersonDto;
  error: string;
  busy: boolean;

  readonly ages = domain.ages
  readonly genders = domain.genders
  readonly activityLevels = domain.activityLevels

  form: EditPersonForm

  constructor(private personService: statera.PersonService,
              readonly dialogRef: MatDialogRef<EditPersonDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.busy = false
    this.original = structuredClone(data.person);

    this.form = new EditPersonForm(data.person)
  }

  save() {
    const self = this;

    self.original.name = this.form.name.value;
    self.original.dateOfBirth = this.form.dateOfBirth.value;
    self.original.age = this.form.age.value;
    self.original.gender = this.form.gender.value;
    self.original.activityLevel = this.form.activityLevel.value;

    console.log("self.original", self.original)

    self.busy = true
    self.personService.updatePerson(self.original.uuid, self.original)
      .subscribe(busyDialog(self));
  }
}
