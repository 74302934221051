import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private baseUrl = 'http://localhost:8080';

  constructor(private http: HttpClient) {}

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', `${this.baseUrl}/upload`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    if (true) {
      throw new Error('Deprecated');
    }
    // return this.http.request(req);
  }

  getFiles(): Observable<any> {
    if (true) {
      throw new Error('Deprecated');
    }
    // return this.http.get(`${this.baseUrl}/files`);
  }
}
