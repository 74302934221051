export class YearWeek {
  readonly year: number
  readonly week: number

  constructor(year: number, week: number) {
    this.year = year;
    this.week = week;
  }

  next(): YearWeek {
    return this.add(1);
  }

  previous(): YearWeek {
    return this.add(-1);
  }

  add(delta: number) {
    const y = this.year;
    const w = this.week + delta;

    const n = new Date(y, 0, w * 7);
    const nw = YearWeek.getWeekNumber(n);

    return new YearWeek(n.getFullYear(), nw);
  }

  toString(): string {
    return `${this.year}-${this.week}`
  }

  static today(): YearWeek {
    const d = new Date();
    return new YearWeek(d.getFullYear(), this.getWeekNumber(d));
  }

  static parse(string: string): YearWeek | undefined {
    const re = /^(\d{4})-(\d{1,2})$/;
    const match = re.exec(string);

    if (!match) {
      return undefined
    }

    return new YearWeek(parseInt(match[1]), parseInt(match[2]))
  }

  // https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php/6117889#6117889
  private static getWeekNumber(d: Date): number {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to the nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
  }

  compareTo(other: YearWeek): number {
    const diff = this.year - other.year;
    if (diff != 0) {
      return diff;
    }

    return this.week - other.week;
  }
}
