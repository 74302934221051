import structuredClone from '@ungap/structured-clone';
import * as statera from '@vimscore/statera-client';
import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {busyDialog} from "../../../../utils/dialog";
import {formatDate} from "@angular/common";

class EditChallengeForm {
  public readonly challengeName = new FormControl(null, [Validators.required])
  public readonly startDate = new FormControl(null, [Validators.required])
  public readonly endDate = new FormControl(null, [])

  readonly group = new FormGroup({
    challengeName: this.challengeName,
    startDate: this.startDate,
    endDate: this.endDate,
  })
}

type EditChallengeDialogData = {
  challenge: statera.ChallengeDetailsDto
}

@Component({
  selector: 'app-edit-challenge-details-dialog',
  templateUrl: './edit-challenge-details-dialog.component.html',
  styleUrls: ['./edit-challenge-details-dialog.component.css']
})
export class EditChallengeDetailsDialogComponent implements OnInit {

  form = new EditChallengeForm()
  challenge: statera.ChallengeDto
  busy = false
  error = ""

  constructor(private challengeService: statera.ChallengeService,
              readonly dialogRef: MatDialogRef<EditChallengeDetailsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: EditChallengeDialogData) {
    this.challenge = data.challenge
    this.form.challengeName.setValue(data.challenge.name)
    this.form.startDate.setValue(data.challenge.startDate)
    this.form.endDate.setValue(data.challenge.endDate)
  }

  ngOnInit(): void {
  }

  async save() {
    console.log("this.challenge", this.challenge);

    const endDate = this.form.endDate.value;

    let challenge = structuredClone(this.challenge) as statera.ChallengeDetailsDto
    challenge.name = this.form.challengeName.value
    challenge.startDate = formatDate(this.form.startDate.value, "YYYY-MM-ddTHH:mm:ssZZZZZ", "en_US")
    challenge.endDate = endDate && formatDate(endDate, "YYYY-MM-ddTHH:mm:ssZZZZZ", "en_US")

    console.log("challenge", challenge)

    this.challengeService.updateChallengeDetails(challenge.uuid, challenge)
      .subscribe(busyDialog(this))
  }
}
