<div class="basic-container" *ngFor="let prescription of prescriptions">
  <h2>Prescription</h2>

  <dl class="vs-dl" style="width: 700px">
  <dt>Created</dt>
  <dd>{{prescription.createdDate|date:"medium"}}</dd>
    <dt>Goal</dt>
    <dd>{{prescription.goal|enum2string|titlecase}}</dd>
    <dt>Activity level</dt>
    <dd>{{prescription.activityLevel|enum2string|titlecase}}</dd>
    <dt>Capacity scores</dt>
    <dd>
      Strength: {{prescription.capacityScores.strength}}<br>
      Endurance: {{prescription.capacityScores.endurance}}<br>
      Balance: {{prescription.capacityScores.balance}}<br>
      Flexibility: {{prescription.capacityScores.flexibility}}
    </dd>
  </dl>

  <table class="vs-table text-center" style="min-width: 40em;">
    <thead>
    <tr>
      <th></th>
      <th class="text-right">Strength</th>
      <th class="text-right">Endurance</th>
      <th class="text-right">Balance</th>
      <th class="text-right">Flexibility</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let p of prescription.phases">
      <th class="text-right">Phase {{p.number}}</th>
      <td class="text-right">{{p.capacityMinutes.strength|duration}}</td>
      <td class="text-right">{{p.capacityMinutes.endurance|duration}}</td>
      <td class="text-right">{{p.capacityMinutes.balance|duration}}</td>
      <td class="text-right">{{p.capacityMinutes.flexibility|duration}}</td>
    </tr>
    </tbody>
  </table>
</div>
