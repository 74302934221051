import * as statera from '@vimscore/statera-client';
import {Component, OnInit} from '@angular/core';
import {YearWeek} from "../../../../utils/year-week";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-inspect-person-week-summary',
  templateUrl: './inspect-person-week-summary.component.html',
  styleUrls: ['./inspect-person-week-summary.component.css']
})
export class InspectPersonWeekSummaryComponent implements OnInit {

  person: statera.PersonDto
  summary: statera.PersonWeekSummaryDto
  week: YearWeek

  constructor(private personService: statera.PersonService,
              private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data: {
      personInfo: statera.PersonInfoDto,
      goals: statera.PersonalGoalsDto,
      personWeekSummary: statera.PersonWeekSummaryDto,
    }) => {
      this.person = data.personInfo.person;
      this.summary = data.personWeekSummary;
      this.week = YearWeek.parse(this.summary.week)
    });
  }

  weekChanged(week: YearWeek): void {
    const self = this;

    this.week = week;

    self.personService.personWeekSummaryForWeek(self.person.uuid, this.week.toString()).subscribe({
      next(res: statera.PersonWeekSummaryDto) {
        self.summary = res
      }
    })
  }
}
