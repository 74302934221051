import * as statera from '@vimscore/statera-client';
import {Component} from '@angular/core';
import {runReport} from "../report-utils";

@Component({
  selector: 'app-exercises-report',
  templateUrl: './exercises-report.component.html',
  styleUrls: ['./exercises-report.component.css']
})
export class ExercisesReportComponent {
  output: string
  busy: boolean

  exercises: statera.CreateExercisesReportRequestDto = {
    humanMode: true
  }

  constructor(private simulatorService: statera.SimulatorService) {}

  runExercises() {
    this.output = null;
    this.simulatorService.createExercisesReport(this.exercises, 'events', true)
      .subscribe(runReport('exercises', this));
  }
}
