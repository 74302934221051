<mat-toolbar>
  <span>Jobs</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<mat-tab-group animationDuration="0ms"
               [selectedIndex]="tab.index"
               (selectedIndexChange)="tab.onChange($event)">
  <mat-tab label="Refresh weekly personal progress">
    <div class="basic-container">
      <p>
        <button class="mat-button mat-primary mat-raised-button" (click)="refreshWeeklyPersonalProgress()">
          Run
          <app-spin [active]="refreshWeeklyPersonalProgressJob.busy"></app-spin>
        </button>
      </p>

      <mat-divider></mat-divider>

      <app-job-response-table [response]="refreshWeeklyPersonalProgressJob.jobResponse"></app-job-response-table>
    </div>
  </mat-tab>
</mat-tab-group>
