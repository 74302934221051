import * as statera from '@vimscore/statera-client';
import {ActivatedRoute} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {catchError} from "rxjs/operators";
import {errorOperator} from "../../../utils/dialog";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss']
})
export class ResetPasswordPageComponent implements OnInit {

  token: string
  newPassword: string;
  busy=false
  error: string

  constructor(
    private authenticationService: statera.AuthenticationService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams["token"]
  }

  async setPassword() {
    console.log("setting password", this.newPassword)

    const res = this.authenticationService.continuePasswordRecovery({
      token: this.token,
      newPassword: this.newPassword,
    }).pipe(
      catchError(errorOperator(this)),
    );

    await firstValueFrom(res);
  }
}
