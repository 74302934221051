<div class="basic-container">
  <app-week-navigator [week]="week" (weekChange)="weekChanged($event)"></app-week-navigator>

  <table class="vs-table">
    <thead>
    <tr>
      <td>Goal name</td>
      <td>Goal</td>
      <td>Done</td>
      <td>Completed</td>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>Activity minutes</td>
      <td>{{ summary.activityMinutes.goal }}</td>
      <td>{{ summary.activityMinutes.done }}</td>
      <td>{{ summary.activityMinutes.completed | yesNo }}</td>
    </tr>
    <tr>
      <td>Activity Count</td>
      <td>{{ summary.activityCount.goal }}</td>
      <td>{{ summary.activityCount.done }}</td>
      <td>{{ summary.activityCount.completed | yesNo }}</td>
    </tr>
    <tr>
      <td>Strength minutes</td>
      <td>{{ summary.strengthMinutes.goal }}</td>
      <td>{{ summary.strengthMinutes.done }}</td>
      <td>{{ summary.strengthMinutes.completed | yesNo }}</td>
    </tr>
    <tr>
      <td>Strength activities</td>
      <td>{{ summary.strengthActivities.goal }}</td>
      <td>{{ summary.strengthActivities.done }}</td>
      <td>{{ summary.strengthActivities.completed | yesNo }}</td>
    </tr>
    <tr>
      <td>Endurance minutes</td>
      <td>{{ summary.enduranceMinutes.goal}}</td>
      <td>{{ summary.enduranceMinutes.done}}</td>
      <td>{{ summary.enduranceMinutes.completed | yesNo }}</td>
    </tr>
    <tr>
      <td>Endurance activities</td>
      <td>{{ summary.enduranceActivities.goal }}</td>
      <td>{{ summary.enduranceActivities.done }}</td>
      <td>{{ summary.enduranceActivities.completed | yesNo }}</td>
    </tr>
    <tr>
      <td>Balance minutes</td>
      <td>{{ summary.balanceMinutes.goal }}</td>
      <td>{{ summary.balanceMinutes.done }}</td>
      <td>{{ summary.balanceMinutes.completed | yesNo }}</td>
    </tr>
    <tr>
      <td>Balance activities</td>
      <td>{{ summary.balanceActivities.goal }}</td>
      <td>{{ summary.balanceActivities.done }}</td>
      <td>{{ summary.balanceActivities.completed | yesNo }}</td>
    </tr>
    <tr>
      <td>Flexibility minutes</td>
      <td>{{ summary.flexibilityMinutes.goal }}</td>
      <td>{{ summary.flexibilityMinutes.done }}</td>
      <td>{{ summary.flexibilityMinutes.completed | yesNo }}</td>
    </tr>
    <tr>
      <td>Flexibility activities</td>
      <td>{{ summary.flexibilityActivities.goal }}</td>
      <td>{{ summary.flexibilityActivities.done }}</td>
      <td>{{ summary.flexibilityActivities.completed | yesNo }}</td>
    </tr>
    </tbody>
  </table>
</div>
