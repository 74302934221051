import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

export interface ConfirmDialogData {
  title?: string
  content?: string
  cancelLabel?: string
  okLabel?: string
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {

  readonly title: string;
  readonly content: string;
  readonly cancelLabel: string;
  readonly okLabel: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
    this.title = this.data.title || "Confirm";
    this.content = this.data.content;
    this.cancelLabel = this.data.cancelLabel || "Cancel";
    this.okLabel = this.data.okLabel || "Ok";
  }
}
