<form [formGroup]="accountSearchForm.formGroup" class="person-form">
  <mat-form-field class="person-autocomplete">
    <mat-label>{{ label || "Account search" }}</mat-label>
    <input matInput formControlName="query" [matAutocomplete]="auto">

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)" [displayWith]="display">
      <mat-option *ngFor="let a of accounts$ | async" [value]="a">
        {{a.email}} ({{ a.personUuid }})
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
