import * as statera from '@vimscore/statera-client';
import {AuthenticationService} from '../../services/authentication.service';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {extractErrorMessage} from "../../utils/errors";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  message: string;
  working: boolean;

  constructor(private authenticationWebService: statera.AuthenticationService,
              private consoleService: statera.ConsoleService,
              private authenticationService: AuthenticationService,
              private router: Router) {}

  async ngOnInit() {
    const authenticatedUser = this.authenticationService.authenticatedUserValue;
    if (authenticatedUser) {
      await this.router.navigate(['']);
    }
  }

  login() {
    this.message = null;
    this.working = true;
    const req = {email: this.email, password: this.password};
    this.authenticationWebService.authenticate(req).subscribe(async res => {
      await this.delay(500);
      this.working = false;
      this.authenticationService.setJwtToken(res.token);
      await this.router.navigate(['']);
    }, err => {
      this.working = false;
      this.message = extractErrorMessage(err)
    });
  }

  delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
