<mat-toolbar>
  <a mat-icon-button routerLink="../..">
    <mat-icon>arrow_back</mat-icon>
  </a>
  <span>Person: {{personUuid}}</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<div class="basic-container">
  <h2>
    <a style="vertical-align: sub" href="{{selfLink}}">
      <mat-icon>link</mat-icon>
    </a>
    Vim Period: {{period.uuid}}
  </h2>

  <dl class="vs-dl" style="width: 700px">
    <dt>UUID</dt>
    <dd>{{period.uuid}} <app-copy-to-clipboard></app-copy-to-clipboard></dd>
    <dt>Start</dt>
    <dd>{{period.start}}</dd>
    <dt>Phase:
    <dd>{{period.phaseNumber}}</dd>
    <dt>Start:
    <dd>{{period.start|date:'mediumDate'}}</dd>
  </dl>

  <h2>Weeks</h2>

  <table class="vs-table">
    <tr>
      <td style="width: 10em"></td>
      <td>Goal</td>
      <td>Performed</td>
      <td style="width: 20em" class="text-center">Completion</td>
    </tr>
    <tbody *ngFor="let w of weeks; let i = index; let first = first">
    <tr>
      <th class="text-left" colspan="5" [style.paddingTop]="!first && '1em'">
        Week #{{ i + 1 }}<br>
        {{ w.start|date:'mediumDate' }}</th>
    </tr>
    <tr *ngFor="let pct of [(w.done.strength|duration2float) / (w.goal.strength|duration2float)]">
      <td class="text-right">Strength</td>
      <td class="text-right">{{ w.goal.strength|duration }}</td>
      <td class="text-right">{{ w.done.strength|duration}}</td>
      <td class="text-center">
        <mat-progress-bar [value]="pct * 100"></mat-progress-bar>&nbsp;{{ pct | percent }}
      </td>
    </tr>
    <tr *ngFor="let pct of [(w.done.endurance|duration2float) / (w.goal.endurance|duration2float)]">
      <td class="text-right">Endurance</td>
      <td class="text-right">{{ w.goal.endurance|duration }}</td>
      <td class="text-right">{{ w.done.endurance|duration}}</td>
      <td class="text-center">
        <mat-progress-bar [value]="pct * 100"></mat-progress-bar>&nbsp;{{ pct | percent }}
      </td>
  </tr>
    <tr *ngFor="let pct of [(w.done.balance|duration2float) / (w.goal.balance|duration2float)]">
      <td class="text-right">Balance</td>
      <td class="text-right">{{ w.goal.balance|duration }}</td>
      <td class="text-right">{{ w.done.balance|duration}}</td>
      <td class="text-center">
        <mat-progress-bar [value]="pct * 100"></mat-progress-bar>&nbsp;{{ pct | percent }}
      </td>
    </tr>
    <tr *ngFor="let pct of [(w.done.flexibility|duration2float) / (w.goal.flexibility|duration2float)]">
      <td class="text-right">Flexibility</td>
      <td class="text-right">{{ w.goal.flexibility|duration }}</td>
      <td class="text-right">{{ w.done.flexibility|duration}}</td>
      <td class="text-center">
        <mat-progress-bar [value]="pct * 100"></mat-progress-bar>&nbsp;{{ pct | percent }}
      </td>
    </tr>
    <tr *ngFor="let pct of [
        ([w.done.strength, w.done.endurance, w.done.balance, w.done.flexibility]|durationSum|duration2float) /
        ([w.goal.strength, w.goal.endurance, w.goal.balance, w.goal.flexibility]|durationSum|duration2float)]">
      <td class="text-right">Total</td>
      <td class="text-right">
        {{ [w.goal.strength, w.goal.endurance, w.goal.balance, w.goal.flexibility]|durationSum|duration}}
      </td>
      <td class="text-right">
        {{ [w.done.strength, w.done.endurance, w.done.balance, w.done.flexibility]|durationSum|duration}}
      </td>
      <td class="text-center">
        <mat-progress-bar [value]="pct * 100"></mat-progress-bar>&nbsp;{{ pct | percent }}
      </td>
    </tr>
    </tbody>
  </table>

</div>
