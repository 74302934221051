<mat-toolbar>
  <span>Animations</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<div class="basic-container">

  <form [formGroup]="form">
    <label>
      <input type="radio" value="exerciseDescriptionId" name="sortBy" formControlName="sortBy">
      4Tune Exercise Id
    </label>
    <label>
      <input type="radio" value="fileName" name="sortBy" formControlName="sortBy">
      File name
    </label>
    <label>
      <input type="radio" value="exerciseName" name="sortBy" formControlName="sortBy">
      Exercise name
    </label>
  </form>

  <table>
    <thead>
    <tr>
      <th>Exercise</th>
      <th>File</th>
      <th>Mov</th>
      <th>Lottie</th>
      <th></th>
      <th></th>
      <th>Name</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td colspan="7">
        <app-spin [active]="animations == null"></app-spin>
      </td>
    </tr>
    </tbody>
    <tbody>
    <tr *ngFor="let a of animations">
      <td>{{ a.externalExerciseId }}</td>
      <td>{{ a.fileName }}</td>
      <td><span *ngIf="a.mov"><mat-icon>check</mat-icon></span></td>
      <td><a *ngIf="a.lottie" [href]="a.lottie"><mat-icon>link</mat-icon></a></td>
      <td><a [href]="settings._4tuneUrl + '/#/excercise/' + a.externalExerciseId">4Tune</a></td>
      <td><a routerLink="./{{a.externalExerciseId}}">Animation</a></td>
      <td>{{ a.name }}</td>
    </tr>
    </tbody>
  </table>

</div>
