import localeNb from '@angular/common/locales/nb';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, Injectable, LOCALE_ID, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';

import {DateAdapter, MatNativeDateModule, NativeDateAdapter} from "@angular/material/core";
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from "@angular/material/tabs";
import {MatToolbarModule} from '@angular/material/toolbar';

import {JWT_OPTIONS, JwtModule} from '@auth0/angular-jwt';
import {LottieModule} from 'ngx-lottie';
import {QRCodeModule} from "angularx-qrcode";

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {ActivitiesReportComponent} from './pages/report/activities-report/activities-report.component';
import {ActivityComponent} from './pages/inspector/activity/activity.component';
import {AnimationPageComponent} from './pages/exercise/animation-page/animation-page.component';
import {AnimationsPageComponent} from './pages/exercise/animations-page/animations-page.component';
import {ChallengeModule} from "./pages/challenge/challenge.module";
import {ConsoleAccountPageComponent} from './pages/console-account/console-account-page/console-account-page.component';
import {ConsoleAccountsPageComponent} from './pages/console-account/console-accounts-page/console-accounts-page.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {Dump4tuneReportComponent} from './pages/report/dump4tune-report/dump4tune-report.component';
import {ExerciseDetailsDialog} from './pages/inspector/workout/workout-alternative-row.component';
import {ExercisesReportComponent} from './pages/report/exercises-report/exercises-report.component';
import {HomeComponent} from './pages/home/home.component';
import {InspectActivitiesComponent} from './pages/inspector/inspect-page/inspect-activities/inspect-activities.component';
import {InspectAssessmentsComponent} from './pages/inspector/inspect-page/inspect-assessments/inspect-assessments.component';
import {InspectPageComponent} from './pages/inspector/inspect-page/inspect-page.component';
import {InspectPeriodsComponent} from './pages/inspector/inspect-page/inspect-periods/inspect-periods.component';
import {InspectPersonWeekSummaryComponent} from './pages/inspector/inspect-page/inspect-person-week-summary/inspect-person-week-summary.component';
import {InspectPersonalGoalsComponent} from './pages/inspector/inspect-page/inspect-personal-goals/inspect-personal-goals.component';
import {InspectPrescriptionsComponent} from './pages/inspector/inspect-page/inspect-prescriptions/inspect-prescriptions.component';
import {InspectProfileComponent} from './pages/inspector/inspect-page/inspect-profile/inspect-profile.component';
import {InspectWorkoutsComponent} from './pages/inspector/inspect-page/inspect-workouts/inspect-workouts.component';
import {InspectorPageComponent} from './pages/inspector/inspector-page/inspector-page.component';
import {JobFrontPageComponent} from './pages/job/job-front-page/job-front-page.component';
import {LoginComponent} from './pages/login/login.component';
import {NbarExercisesPageComponent} from './pages/nbar-exercises/nbar-exercises-page/nbar-exercises-page.component';
import {NotFoundPageComponent} from './pages/not-found-page/not-found-page.component';
import {PeriodComponent} from './pages/inspector/period/period.component';
import {PickExercisesReportComponent} from './pages/report/pick-exercises-report/pick-exercises-report.component';
import {ProgramsReportComponent} from './pages/report/programs-report/programs-report.component';
import {QrCodePageComponent} from './pages/misc/qr-code-page/qr-code-page.component';
import {ReportsPageComponent} from './pages/report/reports-page/reports-page.component';
import {ResetPasswordPageComponent} from './pages/auth/reset-password-page/reset-password-page.component';
import {SpondPageComponent} from './pages/spond/spond-page/spond-page.component';
import {VisExercisePageComponent} from './pages/vis/vis-exercise-page/vis-exercise-page.component';
import {VisExercisesPageComponent} from './pages/vis/vis-exercises-page/vis-exercises-page.component';
import {WorkoutComponent} from './pages/inspector/workout/workout.component';
import * as dialogs from './pages/dialogs';

import {Settings} from './services/settings';
import * as auth from './services/authentication.service';
import {AcceptLanguageHttpInterceptor, AuthHttpInterceptor} from "./services/http";

import {BASE_PATH as Statera_BASE_PATH, Configuration as Statera_Configuration} from '@vimscore/statera-client';
import {ConsoleComponentsModule} from "./components/console-components.module";
import {ConsolePipesModule} from "./pipes/console-pipes.module";

registerLocaleData(localeNb);

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

function createConfiguration(): Statera_Configuration {
  let cfg = new Statera_Configuration();
  let orig = cfg.isJsonMime;
  cfg.isJsonMime = (mime: string): boolean => {
    return mime == "*/*" || orig(mime);
  }
  return cfg;
}

@Injectable()
export class VmsDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
}

@NgModule({
  declarations: [
    AppComponent,

    ActivitiesReportComponent,
    ActivityComponent,
    AnimationPageComponent,
    AnimationsPageComponent,
    ConsoleAccountPageComponent,
    ConsoleAccountsPageComponent,
    DashboardComponent,
    Dump4tuneReportComponent,
    ExerciseDetailsDialog,
    ExercisesReportComponent,
    HomeComponent,
    InspectActivitiesComponent,
    InspectAssessmentsComponent,
    InspectPageComponent,
    InspectPeriodsComponent,
    InspectPersonWeekSummaryComponent,
    InspectPersonalGoalsComponent,
    InspectPrescriptionsComponent,
    InspectProfileComponent,
    InspectWorkoutsComponent,
    InspectorPageComponent,
    InspectorPageComponent,
    JobFrontPageComponent,
    LoginComponent,
    NbarExercisesPageComponent,
    NotFoundPageComponent,
    PeriodComponent,
    PickExercisesReportComponent,
    ProgramsReportComponent,
    QrCodePageComponent,
    ReportsPageComponent,
    ResetPasswordPageComponent,
    SpondPageComponent,
    VisExercisePageComponent,
    VisExercisesPageComponent,
    WorkoutComponent,
    dialogs.CreateExternalActivityDialogComponent,
    dialogs.CreatePersonDialogComponent,
    dialogs.EditEmailAddressDialogComponent,
    dialogs.EditPersonDialogComponent,
    dialogs.EditPersonalGoalsDialogComponent,
    dialogs.EditPhoneNumberDialogComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,

    // StateraWebApiModule,
    // make sure to import the HttpClientModule in the AppModule only,
    // see https://github.com/angular/angular/issues/20575
    HttpClientModule,

    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: auth.jwtOptionsFactory,
        deps: [auth.AuthenticationService, Settings]
      }
    }),
    LottieModule.forRoot({ player: playerFactory }),
    QRCodeModule,

    BrowserAnimationsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,

    ChallengeModule,
    ConsoleComponentsModule,
    ConsolePipesModule,
    MatButtonToggleModule,
  ],
  providers: [
    Settings,
    {provide: HTTP_INTERCEPTORS, useClass: AcceptLanguageHttpInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
    {provide: Statera_BASE_PATH, useFactory: (settings: Settings) => settings.stateraUrl, deps: [Settings]},
    {provide: Statera_Configuration, useFactory: createConfiguration},
    {provide: LOCALE_ID, useValue: 'nb-NO'},
    {provide: DateAdapter, useClass: VmsDateAdapter},
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('nb-NO');
  }
}
