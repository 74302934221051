import {NgModule} from "@angular/core";

import {Cast4tuneDurationPipe} from './cast4tune-duration.pipe';
import {CmDurationPipe} from './cm-duration.pipe';
import {Duration2FloatPipe} from './duration2number.pipe';
import {DurationPipe} from './duration.pipe';
import {DurationSumPipe} from './duration-sum.pipe';
import {Enum2stringPipe} from "./enum2string.pipe";
import {From4tunePipe} from './from4tune.pipe';
import {InitialCasePipe} from "./initial-case.pipe";
import {NumberSumPipe} from './number-sum.pipe';
import {PhoneNumberPipe} from './phone-number.pipe';
import {YesNoPipe} from "./yesNo.pipe";

@NgModule({
  declarations: [
    Cast4tuneDurationPipe,
    CmDurationPipe,
    Duration2FloatPipe,
    DurationPipe,
    DurationSumPipe,
    Enum2stringPipe,
    From4tunePipe,
    InitialCasePipe,
    NumberSumPipe,
    PhoneNumberPipe,
    YesNoPipe,
  ],
  exports: [
    Cast4tuneDurationPipe,
    CmDurationPipe,
    Duration2FloatPipe,
    DurationPipe,
    DurationSumPipe,
    Enum2stringPipe,
    From4tunePipe,
    InitialCasePipe,
    NumberSumPipe,
    YesNoPipe,
    PhoneNumberPipe,
  ],
})
export class ConsolePipesModule {
}
