import * as statera from "@vimscore/statera-client"
import {Component, Inject, OnInit} from '@angular/core';
import {busyDialog} from "../../../../../utils/dialog";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-edit-personal-goals-dialog',
  templateUrl: './edit-personal-goals-dialog.component.html',
  styleUrls: ['./edit-personal-goals-dialog.component.css']
})
export class EditPersonalGoalsDialogComponent implements OnInit {
  person: statera.PersonDto
  goals: statera.PersonalGoalsDto
  error: string
  busy: boolean

  constructor(private personService: statera.PersonService,
              readonly dialogRef: MatDialogRef<EditPersonalGoalsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.busy = false
    this.person = data.person
    this.goals = data.goals
  }

  ngOnInit(): void {
  }

  save() {
    const self = this

    self.busy = true
    self.personService.updatePersonalGoals(self.person.uuid, this.goals)
      .subscribe(busyDialog(self))
  }
}
