import * as statera from '@vimscore/statera-client';
import {Duration, IDuration} from "./duration";

export class CapacityMinutes {
  public readonly strength: IDuration;
  public readonly endurance: IDuration;
  public readonly balance: IDuration;
  public readonly flexibility: IDuration;

  constructor(strength: IDuration, endurance: IDuration, balance: IDuration, flexibility: IDuration) {
    this.strength = strength;
    this.endurance = endurance;
    this.balance = balance;
    this.flexibility = flexibility;
  }

  public static fromDto(dto: statera.CapacityMinutesDto) {
    return new CapacityMinutes(dto.strength, dto.endurance, dto.balance, dto.flexibility);
  }

  public static fromMinutes(strength: number, endurance: number, balance: number, flexibility: number,) {
    return new CapacityMinutes(
      Duration.fromMinutes(strength),
      Duration.fromMinutes(endurance),
      Duration.fromMinutes(balance),
      Duration.fromMinutes(flexibility),
    );
  }

  public toDto(): statera.CapacityMinutesDto {
    return {
      strength: {
        minutes: this.strength.minutes,
        seconds: this.strength.seconds
      },
      endurance: {
        minutes: this.endurance.minutes,
        seconds: this.endurance.seconds
      },
      balance: {
        minutes: this.balance.minutes,
        seconds: this.balance.seconds
      },
      flexibility: {
        minutes: this.flexibility.minutes,
        seconds: this.flexibility.seconds
      },
    }
  }
}
