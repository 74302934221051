import * as statera from '@vimscore/statera-client';
import {Pipe, PipeTransform} from '@angular/core';
import {Duration} from '../utils/duration';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(duration: statera.DurationDto | number, mode: string = null): string {
    if (typeof duration === 'number') {
      const min = Math.floor(duration / 60);
      const sec = Math.round(duration % 60);
      duration = new Duration(min, sec);
    }

    return this.transformDuration(duration, mode);
  }

  transformDuration(duration: statera.DurationDto, mode: string): string {
    if (!duration) {
      return;
    }

    if (mode == 'pretty') {
      if (duration.minutes == 0) {
        return `${duration.seconds} s`;
      }

      if (duration.seconds == 0) {
        return `${duration.minutes} m`;
      }

      if (duration.seconds < 10) {
        return `${duration.minutes}.0${duration.seconds} m`;
      }

      return `${duration.minutes}.${duration.seconds} m`;
    }

    let str = `${duration.seconds}`;
    if (duration.seconds < 10) {
      str = `0${str}`;
    }
    return `${duration.minutes}.${str}`;
  }
}
