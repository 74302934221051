import * as statera from '@vimscore/statera-client';

export class JobState {
  busy = false
  jobResponse?: statera.JobResponseDto

  setBusy = () => {
    this.busy = true
    this.jobResponse = null
  }

  setResponse = (jobResponse: statera.JobResponseDto) => {
    this.busy = false
    this.jobResponse = jobResponse
  }

  setError = () => {
    this.busy = false
    this.jobResponse = {
      messages: [{timestamp: 0, level: "ERROR", message: "Failed"}]
    }
  }
}
