import * as statera from '@vimscore/statera-client';
import {Component} from '@angular/core';
import {DefaultTags, runReport} from "../report-utils";

@Component({
  selector: 'app-pick-exercises-report',
  templateUrl: './pick-exercises-report.component.html',
  styleUrls: ['./pick-exercises-report.component.css']
})
export class PickExercisesReportComponent {
  output: string
  busy: boolean

  environments: Array<statera.Environment> = Object.values(statera.Environment);

  pe: statera.CreatePickExercisesReportRequestDto = {
    exerciseTags: DefaultTags.exerciseTags,
    approvedOnly: DefaultTags.approvedOnly,
    environment: null,
    strengthTag: DefaultTags.strengthTag,
    strengthPicks: 1,
    enduranceTag: DefaultTags.enduranceTag,
    endurancePicks: 1,
    balanceTag: DefaultTags.balanceTag,
    balancePicks: 1,
    flexibilityTag: DefaultTags.flexibilityTag,
    flexibilityPicks: 1,
  }

  exercises: statera.CreateExercisesReportRequestDto = {
    humanMode: true
  }

  constructor(private simulatorService: statera.SimulatorService) {}

  runPickExercises() {
    this.output = null;
    this.simulatorService.createPickExercisesReport(this.pe, 'events', true)
      .subscribe(runReport('pick-exercises', this));
  }

  setPicks(picks: number) {
    this.pe.strengthPicks = picks;
    this.pe.endurancePicks = picks;
    this.pe.balancePicks = picks;
    this.pe.flexibilityPicks = picks;
  }
}
