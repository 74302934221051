import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import * as statera from '@vimscore/statera-client';

@Component({
  selector: 'app-vis-exercise-page',
  templateUrl: './vis-exercise-page.component.html',
  styleUrls: ['./vis-exercise-page.component.css']
})
export class VisExercisePageComponent implements OnInit {

  exerciseUuid: string
  animation: statera.AnimationDto

  constructor(private route: ActivatedRoute, private consoleService: statera.ConsoleService) { }

  ngOnInit(): void {
    this.exerciseUuid = this.route.snapshot.paramMap.get('exerciseUuid');

    this.consoleService.getAnimation(this.exerciseUuid)
      .subscribe((animation: statera.AnimationDto) => this.animation = animation)
  }
}
