import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  query: string;

  constructor() {}

  ngOnInit(): void {}

  // TODO: instagram, facebook, trello, hubspot, google drive
  services = [
    {
      name: "Grafana",
      description: "Logs",
      url: "https://grafana.ops.vimscore.com",
      logo: "/assets/images/other/grafana_icon.svg",
      width: 351,
      height: 365,
    },
    {
      name: "PgAdmin",
      description: "Database",
      url: "https://pgadmin.ops.vimscore.com",
      logo: "/assets/images/other/postgresql-logo.png",
      width: 256,
      height: 256,
    },
    {
      name: "SendGrid",
      description: "Outbound mail",
      url: "https://sendgrid.com",
      logo: "assets/images/other/sendgrid-logo.png",
      width: 771,
      height: 724,
    },
    {
      name: "Google analytics",
      description: "Web and app analytics",
      url: "https://analytics.google.com/analytics/web/#/",
      logo: "assets/images/other/google-analytics.svg",
      width: 192,
      height: 192,
    },
    {
      name: "Mailchimp",
      description: "Output mail and landing pages",
      url: "https://admin.mailchimp.com/",
      logo: "assets/images/other/mailchimp.png",
      width: 720,
      height: 900,
    },
    {
      name: "Firebase",
      description: "App analytics",
      url: "https://console.firebase.google.com/",
      logo: "assets/images/other/firebase.svg",
      width: 111,
      height: 151,
    },
  ]
}
