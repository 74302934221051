<app-button-row>
  <button class="mat-button mat-primary" (click)="fetchData()">Refresh</button>
</app-button-row>

<div class="basic-container">
  <p>
    Activities from period: {{ from|date:'shortDate' }} - {{ to|date:'shortDate' }}.
  </p>

  <p *ngIf="!busy && activities.length == 0">
    The user does not have any activities.
  </p>

  <table class="vs-table">
    <thead>
    <tr class="text-left">
      <th class="text-center">Date</th>
      <th class="text-center">Time</th>
      <th class="text-center">Duration</th>
      <th class="text-right">Activities</th>
      <th>Inspect</th>
    </tr>
    </thead>
    <tbody *ngIf="busy">
    <tr>
      <td colspan="5" class="text-center">
        <app-spin [active]="busy"></app-spin>
      </td>
    </tr>
    </tbody>
    <tbody *ngIf="!busy">
    <tr *ngFor="let a of activities">
      <td class="text-right">{{a.start|date:'mediumDate'}}</td>
      <td class="text-right">{{a.start|date:'mediumTime'}}</td>
      <td class="text-right">{{a.duration|duration}}</td>
      <td class="text-right">{{a.parts.length}}</td>
      <td><a [routerLink]="['activity', a.uuid]">Show</a></td>
    </tr>
    </tbody>
  </table>
</div>
