import * as statera from '@vimscore/statera-client';
import structuredClone from '@ungap/structured-clone';
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {busyDialog} from "src/app/utils/dialog";
import {CapacityMinutes} from "src/app/utils/capacity-minutes";

type EditTeamRulesDialogData = {
  team: statera.ChallengeTeamDto
}

@Component({
  selector: 'app-edit-team-rules-dialog',
  templateUrl: './edit-team-rules-dialog.component.html',
  styleUrls: ['./edit-team-rules-dialog.component.css']
})
export class EditTeamRulesDialogComponent {

  readonly team: statera.ChallengeTeamDto
  useGoal: boolean
  strength: number
  endurance: number
  balance: number
  flexibility: number

  busy = false
  error: string;

  constructor(private challengeService: statera.ChallengeService,
              readonly dialogRef: MatDialogRef<EditTeamRulesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: EditTeamRulesDialogData) {
    this.team = data.team
    this.useGoal = data.team.useGoal
    this.strength = data.team.goal.strength.minutes
    this.endurance = data.team.goal.endurance.minutes
    this.balance = data.team.goal.balance.minutes
    this.flexibility = data.team.goal.flexibility.minutes
  }

  save() {
    console.log("strength", this.strength);

    console.log("team", this.team);
    let team = structuredClone(this.team);
    team.useGoal = this.useGoal;
    team.goal = CapacityMinutes.fromMinutes(this.strength, this.endurance, this.balance, this.flexibility).toDto();
    console.log("team", team);
    this.challengeService
      .updateTeam(this.team.uuid, team)
      .subscribe(busyDialog(this))
  }
}
