import * as statera from '@vimscore/statera-client';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {Component, Inject} from '@angular/core';
import {Duration} from "../../../../../utils/duration";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {busyDialog} from "../../../../../utils/dialog";
import {formatDate} from "@angular/common";

export const nonZeroCapacityMinutes: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const strength: number = control.get("strength").value
  const endurance: number = control.get("endurance").value
  const balance: number = control.get("balance").value
  const flexibility: number = control.get("flexibility").value

  const minutes = strength + endurance + balance + flexibility;

  return minutes <= 0 ? { nonZeroCapacityMinutes: true } : null;
};

class CreateExternalActivityForm {
  public readonly name = new FormControl(null, [Validators.required])
  public readonly start = new FormControl(new Date(), [Validators.required])
  public readonly strength = new FormControl(0)
  public readonly endurance = new FormControl(0)
  public readonly balance = new FormControl(0)
  public readonly flexibility = new FormControl(0)

  readonly group = new FormGroup({
    name: this.name,
    start: this.start,
    strength: this.strength,
    endurance: this.endurance,
    balance: this.balance,
    flexibility: this.flexibility,
  }, nonZeroCapacityMinutes)
}

@Component({
  selector: 'app-create-external-activity-dialog',
  templateUrl: './create-external-activity-dialog.component.html',
  styleUrls: ['./create-external-activity-dialog.component.css']
})
export class CreateExternalActivityDialogComponent {
  personUuid: string

  form: CreateExternalActivityForm

  error: string
  busy: boolean

  constructor(private externalWorkoutService: statera.ExternalWorkoutService,
              readonly dialogRef: MatDialogRef<CreateExternalActivityDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.personUuid = data.person.uuid
    this.form = new CreateExternalActivityForm()
  }

  save() {
    const strength = this.form.strength.value
    const endurance = this.form.endurance.value
    const balance = this.form.balance.value
    const flexibility = this.form.flexibility.value
    const minutes = strength + endurance + balance + flexibility

    const start: Date = this.form.start.value
    const stop = new Date(start.getTime() + minutes * 60 * 1000);

    const req: statera.SaveExternalWorkoutReqDto = {
      name: this.form.name.value,
      start: formatDate(start, "YYYY-MM-ddTHH:mm:ssZZZZZ", "en_US"),
      stop: formatDate(stop, "YYYY-MM-ddTHH:mm:ssZZZZZ", "en_US"),
      strengthDuration: Duration.fromMinutes(strength).toDto(),
      enduranceDuration: Duration.fromMinutes(endurance).toDto(),
      balanceDuration: Duration.fromMinutes(balance).toDto(),
      flexibilityDuration: Duration.fromMinutes(flexibility).toDto(),
    }
    this.externalWorkoutService.saveExternalWorkout(this.personUuid, req)
      .subscribe(busyDialog(this))
  }
}
