<mat-toolbar>
  <span>Console Accounts</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<div class="basic-container">
  <table class="vs-table">
    <thead>
    <tr>
      <th class="text-left">Name</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let a of accounts">
      <td>{{ a.name }}</td>
      <td>
        <a [routerLink]="[a.consoleAccountId]">show</a>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<div class="basic-container">
  <h3>Create console account</h3>
  <app-account-search [accountSearchForm]="accountSearchForm"></app-account-search>
  <mat-form-field>
    <label>Name</label>
    <input matInput [(ngModel)]="createUser.name">
  </mat-form-field>
  <br/>
  <button mat-button class="mat-button mat-primary" (click)="doCreateAccount()">
    Create account
    <app-spin [active]="busy"></app-spin>
  </button>

  {{ error }}
</div>
