<div class="basic-container" *ngFor="let assessment of assessments">
  <h2>Assessment</h2>
  <dl class="vs-dl" style="width: 700px">
    <dt>Created</dt>
    <dd>{{assessment.createdDate|date:"medium"}}</dd>
    <dt>Test type</dt>
    <dd>{{assessment.testType|enum2string}}</dd>
    <dt>Capacity scores</dt>
    <dd>
      Strength: {{assessment.strength}}<br>
      Endurance: {{assessment.endurance}}<br>
      Balance: {{assessment.balance}}<br>
      Flexibility: {{assessment.flexibility}}
    </dd>
  </dl>
</div>

<div class="basic-container">
  <h2>Create new assessment</h2>
  <form [formGroup]="form.group">

    <mat-radio-group formControlName="testType">
      <ng-container *ngFor="let tt of testTypes">
        <mat-radio-button [value]="tt">{{ tt | enum2string }}</mat-radio-button><br/>
      </ng-container>
    </mat-radio-group>
    <br/>

    <mat-form-field>
      <mat-label>Strength</mat-label>
      <input matInput formControlName="strength" name="strength" type="number" min="0">
    </mat-form-field>
    <br/>

    <mat-form-field>
      <mat-label>Endurance</mat-label>
      <input matInput formControlName="endurance" name="endurance" type="number" min="0">
    </mat-form-field>
    <br/>

    <mat-form-field>
      <mat-label>Balance</mat-label>
      <input matInput formControlName="balance" name="balance" type="number" min="0">
    </mat-form-field>
    <br/>

    <mat-form-field>
      <mat-label>Flexibility</mat-label>
      <input matInput formControlName="flexibility" name="flexibility" type="number" min="0">
    </mat-form-field>
    <br/>
  </form>

  <p *ngIf="error">
    {{error}}
  </p>

  <button mat-button (click)="createAssessment()" [disabled]="form.group.invalid">
    Save
    <app-spin [active]="busy"></app-spin>
  </button>
</div>
