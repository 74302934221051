import {Component, ElementRef} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-copy-to-clipboard',
  template: '<mat-icon style="display: inline" [inline]="true" (click)="clip()">content_copy</mat-icon>',
  styleUrls: ['./copy-to-clipboard.component.css']
})
export class CopyToClipboardComponent {
  constructor(private clipboard: Clipboard,
              private parent: ElementRef,
              private snackBar: MatSnackBar) {}

  clip() {
    let text = this.parent.nativeElement.parentElement.innerText;
    text = text.replace("content_copy", "").trim();
    this.clipboard.copy(text);

    this.snackBar.open("UUID copied to clipboard", null, {
      duration: 3000
    });
  }
}
