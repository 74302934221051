import * as statera from '@vimscore/statera-client';
import structuredClone from '@ungap/structured-clone';
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {busyDialog} from "../../../../../utils/dialog";

@Component({
  selector: 'app-edit-phone-number-dialog',
  templateUrl: './edit-phone-number-dialog.component.html',
  styleUrls: ['./edit-phone-number-dialog.component.css']
})
export class EditPhoneNumberDialogComponent {
  person: statera.PersonDto;
  original: statera.PersonDto;
  pn: statera.PhoneNumberDto
  error: string;
  busy: boolean;

  constructor(private consoleService: statera.ConsoleService,
              readonly dialogRef: MatDialogRef<EditPhoneNumberDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.busy = false
    this.original = structuredClone(data.person);
    this.person = structuredClone(data.person);

    this.pn = {
      verified: true
    };
  }

  save() {
    const self = this;

    self.busy = true
    self.consoleService.addPhoneNumber(self.original.uuid, self.pn)
      .subscribe(busyDialog(self));
  }
}
