import * as statera from '@vimscore/statera-client';
import {Component} from '@angular/core';
import {runReport} from "../report-utils";

@Component({
  selector: 'app-dump4tune-report',
  templateUrl: './dump4tune-report.component.html',
  styleUrls: ['./dump4tune-report.component.css']
})
export class Dump4tuneReportComponent {
  req: statera.CreateDump4tuneReportRequestDto = {
    dumpCells: true,
    dumpCellExercises: true,
    dumpExercises: true,
    filterExercises: true,
  }

  output: string;
  busy: boolean;

  constructor(private simulatorService: statera.SimulatorService) {}

  runDump4tune() {
    this.output = null;
    this.simulatorService.createDump4tuneReport(this.req, 'events', true)
      .subscribe(runReport('4tune', this));
  }
}
