<h2 mat-dialog-title>Add challenge</h2>
<div mat-dialog-content>
  <form [formGroup]="form.group">
    <mat-form-field>
      <mat-label>Challenge name</mat-label>
      <input matInput formControlName="challengeName" name="name">
    </mat-form-field>
    <br/>

    <mat-checkbox formControlName="teamChallenge" name="teamChallenge" style="height: 50px">
      Team challenge
    </mat-checkbox>
    <br/>

    <mat-form-field appearance="fill">
      <mat-label>Start date</mat-label>
      <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" name="startDate">
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
    <br/>

    <mat-form-field appearance="fill">
      <mat-label>End date</mat-label>
      <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" name="endDate">
      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>
    <br/>

    <p *ngIf="error">
      {{error}}
    </p>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">Cancel</button>
  <button mat-button (click)="add()" [disabled]="!form.group.valid">
    Add
    <app-spin [active]="busy"></app-spin>
  </button>
</div>
