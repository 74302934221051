<mat-toolbar>
  <span>Vis Exercises</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<div class="basic-container">

  <app-spin [active]="busy"></app-spin>
</div>

<div class="basic-container" *ngIf="!busy">

  <table>
    <tr>
      <th style="width: 150px"></th>
      <td>
        <mat-checkbox
          [(ngModel)]="approvedOnly"
          (change)="search()"
          name="approvedOnly">
          Approved only
        </mat-checkbox>
      </td>
    </tr>
    <tr>
      <th>Capacity</th>
      <td>
        <mat-radio-group (change)="search()" [(ngModel)]="capacity" class="vs-horizontal" name="capacity">
          <mat-radio-button value="">All</mat-radio-button>
          <mat-radio-button *ngFor="let c of capacities" [value]="c">
            {{c | titlecase}}
          </mat-radio-button>
        </mat-radio-group>
      </td>
    </tr>
    <tr>
      <th>Capacity level</th>
      <td>
        <a (click)="clearCapacityLevelFilter()" href="">All</a>
        &nbsp;
        <mat-checkbox [(ngModel)]="capacityLevelBasic" (change)="search()" name="capacityLevelBasic">
          Basic
        </mat-checkbox>
        &nbsp;
        <mat-checkbox [(ngModel)]="capacityLevelActive" (change)="search()" name="capacityLevelActive">
          Active
        </mat-checkbox>
        &nbsp;
        <mat-checkbox [(ngModel)]="capacityLevelSporty" (change)="search()" name="capacityLevelSporty">
          Sporty
        </mat-checkbox>
        &nbsp;
        <mat-checkbox [(ngModel)]="capacityLevelExtreme" (change)="search()" name="capacityLevelExtreme">
          Extreme
        </mat-checkbox>
      </td>
    </tr>
    <tr>
      <th>Location</th>
      <td>
        <mat-checkbox [(ngModel)]="gym" (change)="search()" name="gym">Gym</mat-checkbox>&nbsp;
        <mat-checkbox [(ngModel)]="home" (change)="search()" name="home">Home</mat-checkbox>&nbsp;
        <mat-checkbox [(ngModel)]="outdoor" (change)="search()" name="outdoor">Outdoor</mat-checkbox>
      </td>
    </tr>
  </table>

</div>

<div class="basic-container" *ngIf="!busy">
  <table class="vs-table">
    <thead>
    <tr>
      <th>ID</th>
      <th>Capacity</th>
      <th>Approved</th>
      <th>Basic</th>
      <th>Active</th>
      <th>Sporty</th>
      <th>Extreme</th>
      <th>Gym</th>
      <th>Home</th>
      <th>Outdoor</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let e of exercises">
      <td><a routerLink="./{{e.externalId}}">{{e.externalId}}</a></td>
      <td>{{e.capacity}}</td>
      <td>{{e.approved}}</td>
      <td>{{e.capacityLevelBasic}}</td>
      <td>{{e.capacityLevelActive}}</td>
      <td>{{e.capacityLevelSporty}}</td>
      <td>{{e.capacityLevelExtreme}}</td>
      <td>{{e.gym}}</td>
      <td>{{e.home}}</td>
      <td>{{e.outdoor}}</td>
    </tr>
    </tbody>
  </table>

</div>
