import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from "@angular/router";
import {
  ConfirmDialogComponent,
  ConfirmDialogData
} from "../../../../components/confirm-dialog/confirm-dialog.component";
import * as statera from '@vimscore/statera-client';
import {DateAdapter} from "@angular/material/core";

@Component({
  selector: 'app-inspect-periods',
  templateUrl: './inspect-periods.component.html',
  styleUrls: ['./inspect-periods.component.css']
})
export class InspectPeriodsComponent {
  personInfo: statera.PersonInfoDto
  periods: Array<statera.VimPeriodDto> = []
  busy = false
  periodStart?: Date

  constructor(private route: ActivatedRoute,
              private dateAdapter: DateAdapter<Date>,
              private dialog: MatDialog,
              private periodService: statera.PeriodService,
              private consoleService: statera.ConsoleService) { }

  ngOnInit(): void {
    this.route.data.subscribe((data: {
      activities: Array<statera.ActivityAndWorkout>,
      personInfo: statera.PersonInfoDto
    }) => {
      this.personInfo = data.personInfo
      this.periods = data.personInfo.periods
    });
  }

  private onError = (err) => {
    const self = this

    self.busy = false;
    console.log("error", err);
  }

  private refreshData = () => {
    const self = this
    const personUuid = self.personInfo.person.uuid;

    console.log("Period cancelled, refreshing person info");

    self.consoleService.personInfo(personUuid).subscribe({
      next(personInfo: statera.PersonInfoDto) {
        console.log("Person info refreshed");
        self.personInfo = personInfo;
        self.periods = personInfo.periods;
        self.busy = false;
      },
      error: self.onError
    })
  }

  cancel() {
    const self = this
    const personUuid = self.personInfo.person.uuid;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Cancel period",
        content: "Are you sure you want to cancel this period?",
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== true) {
        return;
      }

      self.busy = true;

      this.periodService.cancelPeriod(personUuid).subscribe({
          next: self.refreshData,
          error: self.onError,
        }
      );
    });
  }

  canCancel(p: statera.VimPeriodDto) {
    return !p.completed && !p.cancelled;
  }

  formatDate(): string {
    const self = this;
    if(self.periodStart==null) {
      return ""
    }
    // return this.dateAdapter.format(self.periodStart, "DD/MM/YYYY")
    // return this.dateAdapter.createDate(self.periodStart.getFullYear(), self.periodStart.getMonth(), self.periodStart.getDate()).toUTCString();
    return `${self.periodStart.getFullYear()}-${
      (self.periodStart.getMonth()+1).toString().padStart(2, "0")}-${
      self.periodStart.getDate().toString().padStart(2, "0")}`;
  }

  createPeriod() {
    const self = this;
    const personUuid = self.personInfo.person.uuid;
    const req: statera.StartPeriodRequestDto = {
      start: self.formatDate(),
    };

    console.log("Starting period from ", self.periodStart)
    console.log("Starting period from ", req.start)

    self.periodService.startPeriod(personUuid, req).subscribe({
      next: self.refreshData,
      error: self.onError
    })
  }
}
