<mat-toolbar>
  <a mat-icon-button routerLink="../..">
    <mat-icon>arrow_back</mat-icon>
  </a>
  <span>Team: {{ team.name }}</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<app-button-row>
  <button mat-button class="mat-button mat-primary" (click)="openEditRulesDialog()">Edit rules</button>
</app-button-row>

<div class="basic-container">

  <dl class="vs-dl">
    <dt>Name</dt>
    <dd>{{ team.name }}</dd>
    <dt>UUID</dt>
    <dd>{{ team.uuid }}
      <app-copy-to-clipboard></app-copy-to-clipboard>
    </dd>
  </dl>

  <h2>Rules</h2>

  <dl class="vs-dl">
    <dt>Use team goal</dt>
    <dd>{{ team.useGoal | yesNo }}</dd>
    <dt>Strength</dt>
    <dd>{{ team.goal.strength | duration }}</dd>
    <dt>Endurance</dt>
    <dd>{{ team.goal.endurance | duration }}</dd>
    <dt>Balance</dt>
    <dd>{{ team.goal.balance | duration }}</dd>
    <dt>Flexibility</dt>
    <dd>{{ team.goal.flexibility | duration }}</dd>
  </dl>

  <h2>Members</h2>
  <div *ngIf="members.length == 0">
    Team is empty
  </div>

  <table class="vs-table" *ngIf="members.length > 0">
    <tbody>
    <tr *ngFor="let m of members">
      <td>
        <a routerLink="/inspector/{{ m.person }}">{{ m.name }}</a>
      </td>
    </tr>
    </tbody>
  </table>

</div>
