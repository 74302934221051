import * as statera from '@vimscore/statera-client';
import {Component, OnInit} from '@angular/core';
import {JobState} from "../../../components/job-response-table/job.state";

@Component({
  selector: 'app-spond-page',
  templateUrl: './spond-page.component.html',
  styleUrls: ['./spond-page.component.css']
})
export class SpondPageComponent implements OnInit {

  selectedIndex = 0;

  spondMembers = new JobState()
  emailMappings = new JobState()

  constructor(private spondService: statera.SpondService) { }

  ngOnInit(): void {
  }

  importSpondMembers(fileInput: HTMLInputElement) {
    const self = this;

    if (fileInput.files.length != 1) {
      return;
    }

    const file = fileInput.files[0];

    self.spondMembers.setBusy()
    self.spondService.spondImportMembers(file).subscribe({
      next: self.spondMembers.setResponse,
      error: self.spondMembers.setError
    })
  }

  importEmailMappings(fileInput: HTMLInputElement) {
    const self = this;

    if (fileInput.files.length != 1) {
      return;
    }

    const file = fileInput.files[0];

    self.emailMappings.setBusy()
    self.spondService.spondImportEmailMapping(file).subscribe({
      next: self.emailMappings.setResponse,
      error: self.emailMappings.setError,
    })
  }
}
