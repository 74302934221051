import * as statera from '@vimscore/statera-client';
import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {busyComponent} from "../../../utils/dialog";
import {firstValueFrom, tap} from "rxjs";
import {formatDate} from "@angular/common";

class AddChallengeForm {
  public readonly challengeName = new FormControl(null, [Validators.required])
  public readonly teamChallenge = new FormControl(false, [Validators.required])
  public readonly startDate = new FormControl(null, [Validators.required])
  public readonly endDate = new FormControl(null, [])

  readonly group = new FormGroup({
    challengeName: this.challengeName,
    teamChallenge: this.teamChallenge,
    startDate: this.startDate,
    endDate: this.endDate,
  })
}

@Component({
  templateUrl: './add-challenge-dialog.component.html',
})
export class AddChallengeDialog {
  busy = false
  error: string | null = null;

  form = new AddChallengeForm()

  constructor(private challengeService: statera.ChallengeService,
              private dialogRef: MatDialogRef<AddChallengeDialog>) { }

  async add() {
    const self = this;

    let endDate = this.form.endDate.value;
    const req: statera.CreateChallengeReqDto = {
      name: this.form.challengeName.value,
      teamChallenge: this.form.teamChallenge.value,
      startDate: formatDate(this.form.startDate.value, "YYYY-MM-ddTHH:mm:ssZZZZZ", "en_US"),
      endDate: endDate && formatDate(endDate, "YYYY-MM-ddTHH:mm:ssZZZZZ", "en_US"),
    };

    const res = self.challengeService.createChallenge(req)
      .pipe(
        tap(busyComponent(this)),
      );

    const result = await firstValueFrom(res);

    this.dialogRef.close(result);
  }
}
