<mat-toolbar>
  <span>QR code generator</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<div class="basic-container">

  <mat-form-field style="width: 100%">
    <mat-label>URL</mat-label>
    <input matInput [(ngModel)]="baseUrl" name="baseUrl" (input)="onBaseUrl()">
  </mat-form-field>

  <p>
    <button mat-raised-button
            (click)="download(parent)"
            *ngIf="elementType !== 'svg'">Download
    </button>

    <a mat-raised-button class="mat-primary"
       *ngIf="elementType === 'svg'"
       [download]="fileName"
       [href]="qrCodeSrc">Download</a>
  </p>

  <h2>Google Analytics</h2>

  <p>
    <mat-checkbox [(ngModel)]="enableGa" (input)="onBaseUrl()">
      Enable GA tracking
    </mat-checkbox>
  </p>

  <p>
    Both utm_source and utm_medium are required to be a valid Google Analytics URL. Read more about these fields
    <a href="https://ga-dev-tools.web.app/ga4/campaign-url-builder/">here</a>.
  </p>

  <p>
    <mat-form-field>
      <mat-label>utm_id</mat-label>
      <input matInput [(ngModel)]="utmId" name="utm_id" [disabled]="!enableGa" (input)="onBaseUrl()">
    </mat-form-field>

    The ads campaign id.
  </p>

  <p>
    <mat-form-field>
      <mat-label>utm_source</mat-label>
      <input matInput [(ngModel)]="utmSource" name="utm_source" [disabled]="!enableGa" (input)="onBaseUrl()">
    </mat-form-field>

    Identifies a search engine, newsletter name, or other source.
  </p>

  <p>
    <mat-form-field>
      <mat-label>utm_medium</mat-label>
      <input matInput [(ngModel)]="utmMedium" name="utm_medium" [disabled]="!enableGa" (input)="onBaseUrl()">
    </mat-form-field>

    Identifies a medium such as email or cost-per-click.
  </p>

  <p>
    <mat-form-field>
      <mat-label>utm_campaign</mat-label>
      <input matInput [(ngModel)]="utmCampaign" name="utm_campaign" [disabled]="!enableGa" (input)="onBaseUrl()">
    </mat-form-field>

    Identifies a specific product promotion or strategic campaign.
  </p>

  <p>
    <mat-form-field>
      <mat-label>utm_term</mat-label>
      <input matInput [(ngModel)]="utmTerm" name="utm_term" [disabled]="!enableGa" (input)="onBaseUrl()">
    </mat-form-field>

    Used for paid search. Use utm_term to note the keywords for this ad.
  </p>

  <p>
    <mat-form-field>
      <mat-label>utm_content</mat-label>
      <input matInput [(ngModel)]="utmContent" name="utm_content" [disabled]="!enableGa" (input)="onBaseUrl()">
    </mat-form-field>

    Used for A/B testing
  </p>

  <h2>Format</h2>
  <section>
    <mat-button-toggle-group name="elementType"
                             [(ngModel)]="elementType"
                             selected="elementType">
      <mat-button-toggle value="img">PNG</mat-button-toggle>
      <mat-button-toggle value="svg">SVG</mat-button-toggle>
    </mat-button-toggle-group>
  </section>

  <h2>Size</h2>
  <section>
    <mat-button-toggle-group name="width"
                             [(ngModel)]="width">
      <mat-button-toggle [value]="100">100</mat-button-toggle>
      <mat-button-toggle [value]="200">200</mat-button-toggle>
      <mat-button-toggle [value]="500">500</mat-button-toggle>
      <mat-button-toggle [value]="1000">1000</mat-button-toggle>
    </mat-button-toggle-group>
  </section>

  <p class="text-center" style="font-size: x-large; padding-top: 20px">
    QR code URL: <a href="{{ url }}">{{ url }}</a>
  </p>

  <p class="text-center">
    <qrcode #parent
            [elementType]="elementType"
            [qrdata]="url"
            [width]="width"
            [errorCorrectionLevel]="'M'"
            (qrCodeURL)="onQrCodeUrlChange($event)"></qrcode>
  </p>
</div>
