import * as statera from '@vimscore/statera-client';
import {Component, OnInit} from '@angular/core';
import {JobState} from "../../../components/job-response-table/job.state";
import {TabIndex} from "../../../utils/fragment";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-job-front-page',
  templateUrl: './job-front-page.component.html',
  styleUrls: ['./job-front-page.component.css']
})
export class JobFrontPageComponent implements OnInit {
  tab: TabIndex

  readonly refreshWeeklyPersonalProgressJob = new JobState()

  constructor(private route: ActivatedRoute,
              private router: Router,
              private jobService: statera.JobService) {
    this.tab = new TabIndex(this.route, this.router);
  }

  ngOnInit(): void {
  }

  refreshWeeklyPersonalProgress() {
    this.jobService.refreshWeeklyPersonalProgress()
      .subscribe({
        next: this.refreshWeeklyPersonalProgressJob.setResponse,
        error: this.refreshWeeklyPersonalProgressJob.setError
      });
  }
}
