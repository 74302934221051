import * as statera from '@vimscore/statera-client';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {BusyComponent, busyComponent, errorDialogOperator} from "src/app/utils/dialog";
import {firstValueFrom, tap} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {catchError} from "rxjs/operators";

@Component({
  selector: 'app-user-page',
  templateUrl: './console-account-page.component.html',
  styleUrls: ['./console-account-page.component.css']
})
export class ConsoleAccountPageComponent implements OnInit {

  account: statera.ConsoleAccountDto

  sendResetPasswordLinkState: BusyComponent = {busy: false, error: null}

  constructor(
    private authenticationService: statera.AuthenticationService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(({account}: { account: statera.ConsoleAccountDto }) => {
      this.account = account;
    });
  }

  async sendResetPasswordLink() {
    const res = this.authenticationService.startPasswordRecovery({
      email: this.account.email,
    }).pipe(
      catchError(errorDialogOperator(this.dialog)),
      tap(busyComponent(this.sendResetPasswordLinkState)),
    );

    await firstValueFrom(res)
  }
}
