<div class="basic-container">
  <mat-card class="login-component">
    <mat-card-content>
      <form #form="ngForm">
        <h2>Welcome to VimScore</h2>

        <p>
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="email" name="email" [(ngModel)]="email" required>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput type="password" [(ngModel)]="password" name="password" required>
          </mat-form-field>
        </p>

        <div class="form-buttons" style="height: 40px; /*background: lightgrey*/">
          <div>
            <button mat-button
                    (click)="login()"
                    [disabled]="!form.valid || working">Login
              <app-spin [active]="working"></app-spin>
            </button>
          </div>
          <!--
          <div>
            <mat-progress-bar *ngIf="working" mode="indeterminate"></mat-progress-bar>
          </div>
          -->
        </div>

        <p class="form-buttons message" *ngIf="message">
          {{message}}
        </p>

      </form>
    </mat-card-content>
  </mat-card>
</div>
