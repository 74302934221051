import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import * as statera from '@vimscore/statera-client';

@Component({
  selector: 'app-inspect-workouts',
  templateUrl: './inspect-workouts.component.html',
  styleUrls: ['./inspect-workouts.component.css']
})
export class InspectWorkoutsComponent implements OnInit {
  private personUuid: string
  public workouts: Array<statera.WorkoutDto> = []
  public busy: boolean = false
  public from: string = '2020-01-01'
  public to: string = '2029-12-31'

  constructor(private route: ActivatedRoute, private workoutService: statera.WorkoutService) {}

  ngOnInit(): void {
    this.personUuid = this.route.snapshot.paramMap.get('personUuid');
    this.fetchData();
  }

  private fetchData() {
    const self = this;

    this.busy = true;
    this.workoutService.getWorkouts(this.personUuid, this.from, this.to, false).subscribe({
      async next(workouts) {
        self.workouts = workouts;
        workouts.sort((a, b) => -a.createdDate.localeCompare(b.createdDate))
      },
      async complete() {
        self.busy = false;
      },
    });
  }
}
