<div class="dashboard">
  <header>
    <app-header-nav></app-header-nav>
  </header>
  <main>
    <router-outlet></router-outlet>
  </main>
  <footer>
    <app-footer></app-footer>
  </footer>
</div>
