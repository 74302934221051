import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-capacity-icon',
  template: '<mat-icon inline="true" style="display: inline" class="capacity-{{capacity|uppercase}}">fiber_manual_record</mat-icon>',
})
export class CapacityIconComponent implements OnInit {

  @Input()
  public capacity: string

  constructor() {}

  ngOnInit(): void {}
}
