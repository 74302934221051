import {NgModule} from '@angular/core'; // For debugging
import {RouterModule, Routes} from '@angular/router';
import {AuthenticatedGuard} from './services/authenticated.guard';
import * as resolvers from './services/resolvers';

import {ActivityComponent} from "./pages/inspector/activity/activity.component";
import {AnimationPageComponent} from './pages/exercise/animation-page/animation-page.component';
import {AnimationsPageComponent} from './pages/exercise/animations-page/animations-page.component';
import {ChallengePageComponent} from "./pages/challenge/challenge-page/challenge-page.component";
import {ChallengeTeamPageComponent} from "./pages/challenge/challenge-team-page/challenge-team-page.component";
import {ChallengeWeekPageComponent} from "./pages/challenge/challenge-week-page/challenge-week-page.component";
import {ChallengesPageComponent} from "./pages/challenge/challenges-page/challenges-page.component";
import {ConsoleAccountPageComponent} from './pages/console-account/console-account-page/console-account-page.component';
import {ConsoleAccountsPageComponent} from './pages/console-account/console-accounts-page/console-accounts-page.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {HomeComponent} from './pages/home/home.component';
import {InspectPageComponent} from './pages/inspector/inspect-page/inspect-page.component';
import {InspectorPageComponent} from './pages/inspector/inspector-page/inspector-page.component';
import {JobFrontPageComponent} from "./pages/job/job-front-page/job-front-page.component";
import {LoginComponent} from './pages/login/login.component';
import {NbarExercisesPageComponent} from "./pages/nbar-exercises/nbar-exercises-page/nbar-exercises-page.component";
import {NotFoundPageComponent} from "./pages/not-found-page/not-found-page.component";
import {PeriodComponent} from "./pages/inspector/period/period.component";
import {QrCodePageComponent} from "./pages/misc/qr-code-page/qr-code-page.component";
import {ReportsPageComponent} from "./pages/report/reports-page/reports-page.component";
import {ResetPasswordPageComponent} from "./pages/auth/reset-password-page/reset-password-page.component";
import {SpondPageComponent} from "./pages/spond/spond-page/spond-page.component";
import {VisExercisePageComponent} from "./pages/vis/vis-exercise-page/vis-exercise-page.component";
import {VisExercisesPageComponent} from "./pages/vis/vis-exercises-page/vis-exercises-page.component";
import {WorkoutComponent} from "./pages/inspector/workout/workout.component";

const dashboardRoutes: Routes = [{
  path: '', component: HomeComponent
}, {
  path: 'exercise/animations', component: AnimationsPageComponent,
}, {
  path: 'exercise/animations/:exerciseDescriptionId', component: AnimationPageComponent,
  resolve: {
    animation: resolvers.AnimationResolver,
  },
}, {
  path: 'inspector', component: InspectorPageComponent,
}, {
  path: 'inspector/:personUuid', component: InspectPageComponent,
  resolve: {
    goals: resolvers.PersonalGoalsResolver,
    personWeekSummary: resolvers.PersonWeekSummaryResolver,
    personInfo: resolvers.PersonInfoResolver,
  }
}, {
  path: 'inspector/:personUuid/activity/:activityUuid', component: ActivityComponent,
  resolve: {
    activity: resolvers.ActivityResolver,
  }
}, {
  path: 'inspector/:personUuid/workout/:workoutUuid', component: WorkoutComponent,
  resolve: {
    workout: resolvers.WorkoutResolver,
  }
}, {
  path: 'inspector/:personUuid/period/:periodUuid', component: PeriodComponent,
  resolve: {
    period: resolvers.PeriodResolver,
  }
}, {
  path: 'report', component: ReportsPageComponent,
}, {
  path: 'spond', component: SpondPageComponent,
}, {
  path: 'vis/exercise', component: VisExercisesPageComponent,
}, {
  path: 'vis/exercise/:exerciseUuid', component: VisExercisePageComponent,
}, {
  path: 'console-account',
  component: ConsoleAccountsPageComponent,
  resolve: {
    accounts: resolvers.ConsoleAccountsResolver
  },
}, {
  path: 'console-account/:consoleAccountId',
  component: ConsoleAccountPageComponent,
  resolve: {
    account: resolvers.ConsoleAccountResolver
  },
}, {
  path: 'misc/qr-code',
  component: QrCodePageComponent,
}, {
  path: 'job',
  component: JobFrontPageComponent,
},];

const nbarRoutes: Routes = [
  {
    path: 'nbar-exercises', component: NbarExercisesPageComponent,
  }
];

const challengeRoutes: Routes = [
  {
    path: 'challenge/:challengeUuid/week/:yearWeek', component: ChallengeWeekPageComponent,
  }, {
    path: 'challenge', component: ChallengesPageComponent
  }, {
    path: 'challenge/:challengeUuid', component: ChallengePageComponent,
    resolve: {
      challenge: resolvers.ChallengeDetailsResolver,
    }
  }, {
    path: 'challenge/:challengeUuid/team/:teamUuid', component: ChallengeTeamPageComponent,
    resolve: {
      challenge: resolvers.ChallengeDetailsResolver,
    }
  },
];

const authRoutes: Routes = [
  {path: 'login', component: LoginComponent},
  {
    path: 'auth/', redirectTo: '/',
  }, {
    path: 'auth/reset-password', component: ResetPasswordPageComponent,
  },
]

const mainRoutes = [
  ...dashboardRoutes,
  ...nbarRoutes,
  ...challengeRoutes,
];

const routes: Routes = [
  {path: '', component: DashboardComponent, children: mainRoutes, canActivate: [AuthenticatedGuard]},
  ...authRoutes,
  {path: '**', component: NotFoundPageComponent},
];

const enableTracing = false;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    useHash: false,
    relativeLinkResolution: 'legacy' // TODO: remove this, v11 upgrade artifact
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
