import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';

import {Spinner, SpinnerOptions} from 'spin.js';

@Component({
  selector: 'app-spin',
  templateUrl: './spin.component.html',
  styleUrls: ['./spin.component.scss']
})
export class SpinComponent implements OnInit, OnDestroy {

  private factory: Spinner;
  private target: HTMLElement;

  public _active: boolean;
  @Input() set active(value: boolean) {
    if (value === this._active) {
      return;
    }

    this._active = value;
    if (this.factory) {
      if (value) {
        this.start();
      } else {
        this.stop();
      }
    }
  }

  get active(): boolean {
    return this._active;
  }

  constructor(public renderer: Renderer2, private elementRef: ElementRef) {}

  public spinner: Spinner;

  ngOnInit(): void {
    const opts: SpinnerOptions = {
      lines: 10, // The number of lines to draw
      length: 32, // The length of each line
      width: 10, // The line thickness
      radius: 23, // The radius of the inner circle
      scale: 0.25, // Scales overall size of the spinner
      corners: 1, // Corner roundness (0..1)
      speed: 1, // Rounds per second
      rotate: 0, // The rotation offset
      animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
      direction: 1, // 1: clockwise, -1: counterclockwise
      color: '#2f2f2f', // CSS color or array of colors
      fadeColor: 'transparent', // CSS color or array of colors
      top: '50%', // Top position relative to parent
      left: '50%', // Left position relative to parent
      shadow: '0 0 1px transparent', // Box-shadow for the lines
      zIndex: 2000000000, // The z-index (defaults to 2e9)
      className: 'spinner', // The CSS class to assign to the spinner
      position: 'absolute', // Element positioning
    };

    this.factory = new Spinner(opts);
    const ne: Element = this.elementRef.nativeElement;
    this.target = ne.firstChild as HTMLElement;

    if (this._active) {
      this.start();
    }

    // const ms = 3000
    // new Promise(resolve => setTimeout(resolve, ms)).then(x => {
    //   console.log('done');
    //   this.spinner.stop();
    // })
  }

  ngOnDestroy(): void {
    this.stop();
  }

  delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private start(): void {
    if (this.spinner) {
      this.spinner.stop();
    }

    this.spinner = this.factory.spin(this.target);
  }

  private stop(): void {
    if (this.spinner) {
      this.spinner.stop();
      this.spinner = null;
    }
  }
}
