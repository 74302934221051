import * as statera from '@vimscore/statera-client';
import {Component} from '@angular/core';
import {DefaultTags, runReport} from "../report-utils";

@Component({
  selector: 'app-programs-report',
  templateUrl: './programs-report.component.html',
  styleUrls: ['./programs-report.component.css']
})
export class ProgramsReportComponent {

  output: string
  busy:boolean

  programs: statera.CreateProgramsReportRequestDto = {
    strengthTag: DefaultTags.strengthTag,
    enduranceTag: DefaultTags.enduranceTag,
    balanceTag: DefaultTags.balanceTag,
    flexibilityTag: DefaultTags.flexibilityTag,
    exerciseTags: DefaultTags.exerciseTags,
    environment: null,
    humanMode: true,
    approvedOnly: DefaultTags.approvedOnly,
  }

  constructor(private simulatorService: statera.SimulatorService) {}

  runPrograms() {
    this.output = null;
    this.simulatorService.createProgramsReport(this.programs, 'events', true)
      .subscribe(runReport('programs', this));
  }
}
