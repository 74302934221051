import * as statera from '@vimscore/statera-client';
import structuredClone from '@ungap/structured-clone';
import {Component, Inject, OnInit} from '@angular/core';
import {busyDialog} from "../../../../utils/dialog";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CapacityMinutesForm} from "../../../../components/form/capacity-minutes-form";

type EditChallengeRulesDialogData = {
  challenge: statera.ChallengeDetailsDto
}

@Component({
  selector: 'app-edit-challenge-rules-dialog',
  templateUrl: './edit-challenge-rules-dialog.component.html',
  styleUrls: ['./edit-challenge-rules-dialog.component.css']
})
export class EditChallengeRulesDialogComponent implements OnInit {
  challenge: statera.ChallengeDetailsDto
  mode: statera.ChallengeRulesMode
  minutes: CapacityMinutesForm

  busy = false
  error: string;

  constructor(private challengeService: statera.ChallengeService,
              readonly dialogRef: MatDialogRef<EditChallengeRulesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: EditChallengeRulesDialogData) {
    this.challenge = data.challenge
    console.log("data.challenge", data.challenge)
    this.mode = this.challenge.rulesMode
    this.minutes = CapacityMinutesForm.fromDto(this.challenge.minutes)
  }

  ngOnInit(): void {
  }

  save(): void {
    console.log("mode", this.mode);
    console.log("minutes", this.minutes);

    let challenge = structuredClone(this.challenge) as statera.ChallengeDetailsDto;
    challenge.rulesMode = this.mode;
    challenge.minutes = this.minutes.toObject().toDto();
    console.log("challenge.minutes", challenge.minutes)

    this.challengeService
      .updateChallengeDetails(this.challenge.uuid, challenge)
      .subscribe(busyDialog(this))
  }
}
