<mat-toolbar>
  <button mat-icon-button routerLink="..">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span>Animation: {{ animation.externalExerciseId }}</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<div class="basic-container">

  <h3>
    Exercise: {{animation.name}}
  </h3>

  <dl class="vs-dl">
    <dt>4Tune</dt>
    <dd></dd>
    <dt>Lottie</dt>
    <dd><a [href]="animation.lottie">Lottie file</a></dd>
  </dl>

  <p>{{animation.description}}</p>

  <!--
  <h3>Video animation</h3>

  <section *ngIf="animation.mov || animation.mp4">
    <p class="video-container">
      <video controls autoplay>
        <source src="{{animation.mp4}}" type="video/mp4" *ngIf="animation.mp4">
        <source src="{{animation.mov}}" type="video/quicktime" *ngIf="animation.mov">
        Your player is unable to play this movie.
      </video>
    </p>
  </section>

  <section *ngIf="!animation.mov && !animation.mp4">
    No video animation for {{ animation.exerciseId }}.
  </section>
  -->

  <h3>Animation</h3>

  <section *ngIf="animation.lottie">
    <ng-lottie
        [options]="options"
        (animationCreated)="animationCreated($event)"
        [styles]="lottieStyles"></ng-lottie>

    <button mat-button (click)="play()">Play</button>
  </section>
  <section *ngIf="!animation.lottie">
    No Lottie animation for {{ animation.exerciseId }}.
  </section>

</div>
