<h2 mat-dialog-title>Edit team rules</h2>
<div mat-dialog-content>
  <h2>Goal</h2>
  <mat-checkbox [(ngModel)]="useGoal" name="useGoal">
    Use goal
  </mat-checkbox>
  <br/>
  <mat-form-field>
    <mat-label>Strength</mat-label>
    <input matInput [(ngModel)]="strength" name="strength" type="number">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Endurance</mat-label>
    <input matInput [(ngModel)]="endurance" name="endurance" type="number">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Balance</mat-label>
    <input matInput [(ngModel)]="balance" name="balance" type="number">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Flexibility</mat-label>
    <input matInput [(ngModel)]="flexibility" name="flexibility" type="number">
  </mat-form-field>
  <br/>

  <p *ngIf="error">
    {{error}}
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">Cancel</button>
  <button mat-button (click)="save()">
    Save
    <app-spin [active]="busy"></app-spin>
  </button>
</div>
