import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numberSum'
})
export class NumberSumPipe implements PipeTransform {
  transform(numbers: number[]): number {
    let sum = 0;
    for (let i = 0; i < numbers.length; i++) {
      sum += numbers[i];
    }
    return sum;
  }
}
