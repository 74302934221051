<h2 mat-dialog-title>Edit challenge rules</h2>
<div mat-dialog-content>
  <h2>Mode</h2>
  <mat-radio-group [(ngModel)]="mode" name="mode">
    <mat-radio-button value="CHALLENGE_MINUTES">Challenge minutes</mat-radio-button>
    <mat-radio-button value="PERSONAL_MINUTES">Personal minutes</mat-radio-button>
    <mat-radio-button value="PERSONAL_GOAL_CAPACITY_MINUTES">Personal goal, capacity minutes</mat-radio-button>
  </mat-radio-group>
  <br/>
  <mat-form-field>
    <mat-label>Strength</mat-label>
    <input matInput [(ngModel)]="minutes.strength" name="strength" type="number" min="0">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Endurance</mat-label>
    <input matInput [(ngModel)]="minutes.endurance" name="endurance" type="number" min="0">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Balance</mat-label>
    <input matInput [(ngModel)]="minutes.balance" name="balance" type="number" min="0">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Flexibility</mat-label>
    <input matInput [(ngModel)]="minutes.flexibility" name="flexibility" type="number" min="0">
  </mat-form-field>
  <br/>

  <p *ngIf="error">
    {{error}}
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">Cancel</button>
  <button mat-button (click)="save()">
    Save
    <app-spin [active]="busy"></app-spin>
  </button>
</div>
