<mat-toolbar>
  <span>N-Bar Exercises</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<div class="basic-container" *ngIf="busy">
  <app-spin [active]="busy"></app-spin>
</div>

<div class="basic-container" *ngIf="!busy">

  <mat-card class="service" style="width: 500px; /*height: 120px;*/ margin-bottom: 30px" *ngFor="let e of exercises">
    <mat-card-title>{{ e.name }}</mat-card-title>

    <mat-card-content>
      <ul>
        <li>Exercise: {{ e.uuid }}</li>
        <li>Body part: {{ e.bodyPart }}</li>
      </ul>
      <p>{{ e.description }}</p>
    </mat-card-content>

    <iframe *ngIf="e.webVideo" [src]="trust(e.webVideo + '?width=500&title=false&byline=false')"
            width="500"
            height="300"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen></iframe>

  </mat-card>
</div>

<script src="https://player.vimeo.com/api/player.js"></script>
<script>
</script>
