<mat-toolbar>
  <span>Reports</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<div class="basic-container">
  <mat-tab-group animationDuration="0ms"
                 [selectedIndex]="selectedIndex">
    <mat-tab label="Activities">
      <app-activities-report></app-activities-report>
    </mat-tab>
    <mat-tab label="Programs report">
      <app-programs-report></app-programs-report>
    </mat-tab>
    <mat-tab label="Exercises report">
      <app-exercises-report></app-exercises-report>
    </mat-tab>
    <mat-tab label="Pick exercises report">
      <app-pick-exercises-report></app-pick-exercises-report>
    </mat-tab>
    <mat-tab label="Dump 4tune report">
      <app-dump4tune-report></app-dump4tune-report>
    </mat-tab>
  </mat-tab-group>
</div>
