import * as statera from '@vimscore/statera-client';
import {ActivatedRoute, Router} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {ConfirmDialogComponent} from "../../../components/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {addDurations, ZERO_DURATION} from "../../../utils/duration";
import {extractErrorMessage} from "../../../utils/errors";

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private activityService: statera.ActivityService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
  ) { }

  personUuid: string;
  activitySessionUuid: string;
  activity: statera.ActivitySessionDto
  minutes: statera.CapacityMinutesDto

  ngOnInit(): void {
    this.personUuid = this.route.snapshot.paramMap.get('personUuid');
    this.activitySessionUuid = this.route.snapshot.paramMap.get('activityUuid');

    this.route.data.subscribe((data: {
      activity: statera.ActivitySessionDto,
    }) => {
      this.activity = data.activity;
      this.minutes = {
        strength: this.activity.parts.filter(p => p.capacity == statera.Capacity.Strength).map(p => p.exerciseDuration).reduce(addDurations, ZERO_DURATION),
        endurance: this.activity.parts.filter(p => p.capacity == statera.Capacity.Endurance).map(p => p.exerciseDuration).reduce(addDurations, ZERO_DURATION),
        balance: this.activity.parts.filter(p => p.capacity == statera.Capacity.Balance).map(p => p.exerciseDuration).reduce(addDurations, ZERO_DURATION),
        flexibility: this.activity.parts.filter(p => p.capacity == statera.Capacity.Flexibility).map(p => p.exerciseDuration).reduce(addDurations, ZERO_DURATION),
      };
    });
  }

  deleteActivity(): void {
    const self = this
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Delete activity",
        content: "Are you sure you want to remove the activity? The user's goal and challenge progress will be affected",
      }
    }).afterClosed().subscribe({
      next(res) {
        if (res !== true) {
          return;
        }

        self.activityService.deleteActivity(self.personUuid, self.activitySessionUuid).subscribe({
          next(res) {
            self.router.navigate(["../.."], { relativeTo: self.route });
          },
          error(res) {
            self.snackBar.open("Could not remove activity: " + extractErrorMessage(res), "Error", {
              duration: 5000,
            });
          }
        });
      }
    })
  }
}
