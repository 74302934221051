<mat-toolbar>
  <a mat-icon-button routerLink="../..">
    <mat-icon>arrow_back</mat-icon>
  </a>
  <span>Person: {{personUuid}}</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<div class="basic-container">
  <h2>Workout</h2>

  <ng-container *ngIf="activityUuid">
    Back to <a routerLink="../../activity/{{activityUuid}}">activity</a>.
  </ng-container>

  <dl class="vs-dl">
    <dt>UUID</dt>
    <dd>{{ workout.uuid }} <app-copy-to-clipboard></app-copy-to-clipboard></dd>
    <dt>Created date</dt>
    <dd>{{ workout.createdDate|date:'medium' }}</dd>
    <dt>Requested duration</dt>
    <dd>{{ workout.requestedDuration|duration:'pretty' }}</dd>
  </dl>

  <h2>Exercises</h2>

  <p>
    <mat-checkbox
      [(ngModel)]="showAlternatives"
      (change)="onShowAlternativesChange($event)"
      name="showAlternatives">
      Show alternatives
    </mat-checkbox>
    <br/>
    <mat-checkbox
      [(ngModel)]="showEffortDetails"
      (change)="onShowEffortDetailsChange($event)"
      name="showEffortDetails">
      Show effort details
    </mat-checkbox>
  </p>

  <table class="vs-table full-width full-width-scroll">
    <thead>
    <tr>
      <th style="min-width: 3em">Group</th>
      <th style="min-width: 5em">Part</th>
      <th>Capacity</th>
      <th>Duration</th>
      <th *ngIf="showEffortDetails">Sets</th>
      <th *ngIf="showEffortDetails">Reps</th>
      <th *ngIf="showEffortDetails">Effort<br/>per rep</th>
      <th *ngIf="showEffortDetails">Recovery<br/>per rep</th>
      <th *ngIf="showEffortDetails">Recovery<br/>per set</th>
      <th *ngIf="showEffortDetails">Effort</th>
      <th *ngIf="showEffortDetails">Recovery after</th>
      <th class="text-left">Effort</th>
      <th></th>
      <th class="text-left">Exercises</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let g of workout.groups; odd as odd; even as even; index as groupIndex">
      <ng-container *ngFor="let e of g.exercises; index as index; first as first">
        <tr>
          <td *ngIf="first" class="text-center group"
              [rowSpan]="showAlternatives ? countAlternatives(g) : g.exercises.length"
              [class.repeated-group]="g.repetitions > 1">
            {{ groupIndex + 1 }}<br/>
            <span *ngIf="g.repetitions > 1">
              {{g.repetitions}} reps
            </span>
          </td>
          <td *ngIf="!first" style="display:none"><!-- css trickery --></td>
          <td class="text-center">{{ e.part|enum2string|titlecase }}</td>
          <td>
            <app-capacity-icon [capacity]="e.capacity"></app-capacity-icon>
            {{ e.capacity | initialCase }}
          </td>
          <td>{{e.duration|duration:'pretty'}}</td>
          <td *ngIf="showEffortDetails" class="text-center">{{e.sets}}</td>
          <td *ngIf="showEffortDetails" class="text-center">{{e.repetitions}}</td>
          <td *ngIf="showEffortDetails" class="text-center">{{e.effortPerRepetition|duration:'pretty'}}</td>
          <td *ngIf="showEffortDetails" class="text-center">{{e.recoveryBetweenRepetitions|duration:'pretty'}}</td>
          <td *ngIf="showEffortDetails" class="text-center">{{e.recoveryBetweenSets|duration:'pretty'}}</td>
          <td *ngIf="showEffortDetails" class="text-center">{{e.timeEffort|duration:'pretty'}}</td>
          <td *ngIf="showEffortDetails" class="text-center">{{e.recoveryAfterEffort|duration:'pretty'}}</td>
          <td>{{e.style}}</td>
          <td>
            <ng-container *ngIf="e.style == 'TIME'">
              {{ e.duration|duration:'pretty' }}
            </ng-container>
            <ng-container *ngIf="e.style == 'TIME_REP'">
              {{e.repetitions}} x {{e.durationPerRepetition|duration:'pretty'}}
            </ng-container>
            <ng-container *ngIf="e.style == 'SET_REP'">
              {{e.sets}}  x {{e.repetitions}} <span *ngIf="e.weight">({{e.weight}} kg)</span>
            </ng-container>
          </td>
          <td>{{ (descriptions[e.alternatives[0]]||{}).name }}</td>
        </tr>
        <ng-container *ngFor="let a of (showAlternatives ? e.alternatives.slice(1) : [])">
          <tr>
            <td [colSpan]="5 + (showEffortDetails ? 7 : 0)"></td>
            <td>{{ descriptions[a].name }}</td>
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
    </tbody>
  </table>
</div>
