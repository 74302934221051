import {ActivatedRoute, Router} from "@angular/router";

function parseFragment(str: string) {
  return (str || "")
    .split("&")
    .map(part => part.split("="))
    .reduce((result, [key, value]: string[]) => {
      result[key] = decodeURIComponent(value);
      return result;
    }, {});
}

export class TabIndex {
  private _index: number

  get index(): number {
    return this._index;
  }

  constructor(private route: ActivatedRoute,
              private router: Router) {
    this.route.fragment.subscribe(this.fragmentChanged)
  }

  fragmentChanged = (str: string) => {
    const fragment = parseFragment(str || "");

    try {
      this._index = Number.parseInt(fragment["tab"])
    } catch (e) {
    }
  }

  onChange($event: number) {
    // noinspection JSIgnoredPromiseFromCall
    this.router.navigate(["./"], {fragment: `tab=${$event}`, relativeTo: this.route})
  }
}
