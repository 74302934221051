import {Component, OnInit} from '@angular/core';
import {FileUploadService} from 'src/app/services/file-upload.service';
import {Observable} from 'rxjs';
import {SimulatorService} from '@vimscore/statera-client';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  message = '';

  fileInfos: Observable<any>;

  constructor(private fileUploadService: FileUploadService,
              private simulatorService: SimulatorService) {}

  ngOnInit(): void {
    this.fileInfos = this.fileUploadService.getFiles();
  }

  selectFile(event): void {
    this.selectedFiles = event.target.files;
  }

  upload(): void {
    throw new Error('Deimplemented');
    /*
    const simulateFromExcel = this.simulatorService.simulateFromExcel(this.selectedFiles.item(0), 'response');
    console.log('Upload started');
    const event = (value: HttpResponse<Blob>) => {
      console.log('value', value);
      if (value instanceof HttpResponse) {
        console.log('typeof body', typeof value.body);
        console.log('body', value.body);
        console.log('body.type', value.type);

        const filename = 'wat.xlsx';

        // let binaryData = [value.body];
        const binaryData = value.body;
        const downloadLink = document.createElement('a');
        // downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: null}));
        downloadLink.href = window.URL.createObjectURL(value.body);
        if (filename) {
          downloadLink.setAttribute('download', filename);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // this.message = value.body.message;
        // this.fileInfos = this.fileUploadService.getFiles();
      }
    };
    const onError = (err: any) => {console.error('error', err); };
    const onComplete = (err: any) => {console.error('error', err); };
    throw new Error('TODO');
    */
    // simulateFromExcel
    //   .subscribe(event, onError);
    // console.log('Subscribed!');

    // this.progress = 0;
    //
    // this.currentFile = this.selectedFiles.item(0);
    // this.fileUploadService.upload(this.currentFile).subscribe(
    //   event => {
    //     if (event.type === HttpEventType.UploadProgress) {
    //       this.progress = Math.round(100 * event.loaded / event.total);
    //     } else if (event instanceof HttpResponse) {
    //       this.message = event.body.message;
    //       this.fileInfos = this.fileUploadService.getFiles();
    //     }
    //   },
    //   _ => {
    //     this.progress = 0;
    //     this.message = 'Could not upload the file!';
    //     this.currentFile = undefined;
    //   });
    //
    // this.selectedFiles = undefined;
  }
}
