import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatCheckboxChange} from "@angular/material/checkbox";
import * as statera from '@vimscore/statera-client';

@Component({
  selector: 'app-workout',
  templateUrl: './workout.component.html',
  styleUrls: ['./workout.component.css']
})
export class WorkoutComponent implements OnInit {

  personUuid: string
  workoutUuid: string
  workout: statera.WorkoutDto
  descriptions: { [key: string]: statera.ExerciseDescriptionDto; }
  showAlternatives: boolean;
  showEffortDetails: boolean;
  activityUuid: string

  constructor(private router: Router, private route: ActivatedRoute) {
    this.activityUuid = router.getCurrentNavigation().extras.state?.activity

    this.showAlternatives = (sessionStorage.getItem("workout-component.showAlternatives") || "") === "true";
    this.showEffortDetails = (sessionStorage.getItem("workout-component.showEffortDetails") || "") === "true";
  }

  ngOnInit(): void {
    this.personUuid = this.route.snapshot.paramMap.get('personUuid');
    this.workoutUuid = this.route.snapshot.paramMap.get('workoutUuid');

    this.route.data.subscribe((data: {
      workout: statera.WorkoutDto,
    }) => {
      this.workout = data.workout;
      this.descriptions = {};
      data.workout.exerciseDescriptions.forEach(d => this.descriptions[d.uuid] = d)
    })
  }

  countAlternatives(g: statera.WorkoutExerciseGroupDto): number {
    return g.exercises.reduce((sum, e) => sum + e.alternatives.length, 0)
  }

  onShowAlternativesChange(e: MatCheckboxChange) {
    sessionStorage.setItem("workout-component.showAlternatives", e.checked.toString())
  }

  onShowEffortDetailsChange(e: MatCheckboxChange) {
    sessionStorage.setItem("workout-component.showEffortDetails", e.checked.toString())
  }
}
