<h2 mat-dialog-title>Edit Phone Number</h2>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Phone number</mat-label>
    <input matInput [(ngModel)]="pn.phoneNumber" name="phoneNumber" placeholder="123 45 678">
  </mat-form-field><br/>
  <mat-checkbox [(ngModel)]="pn.verified" name="verified">
    Verified
  </mat-checkbox>

  <br/>

  <p *ngIf="error">
    {{error}}
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">Cancel</button>
  <button mat-button (click)="save()">
    Save
    <app-spin [active]="busy"></app-spin>
  </button>
</div>
