import {Pipe, PipeTransform} from '@angular/core';
import {Duration, sumDurations} from '../utils/duration';
import * as statera from '@vimscore/statera-client';

@Pipe({
  name: 'cmDuration'
})
export class CmDurationPipe implements PipeTransform {
  transform(cm: statera.CapacityMinutesDto): Duration {
    return sumDurations([cm.strength, cm.endurance, cm.balance, cm.flexibility]);
  }
}
