<h2 mat-dialog-title>Edit person</h2>
<div mat-dialog-content>
  <form [formGroup]="form.group">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" name="name">
    </mat-form-field>
    <br/>

    <h3>Age</h3>
    <p>
      Only specify Age if you don't have the date of birth.
    </p>
    <mat-form-field>
      <mat-label>Date of birth (YYYY-MM-DD)</mat-label>
      <input matInput formControlName="dateOfBirth" name="dateOfBirth">
    </mat-form-field>
    <br/>

    <mat-radio-group formControlName="age" name="age">
      <ng-container *ngFor="let a of ages">
        <mat-radio-button [value]="a">
          {{a|enum2string}}
        </mat-radio-button>
        <br/>
      </ng-container>
    </mat-radio-group>

    <h3>Gender</h3>
    <mat-radio-group formControlName="gender" name="gender">
      <ng-container *ngFor="let g of genders">
        <mat-radio-button [value]="g">
          {{g.toString()|titlecase}}
        </mat-radio-button>
        <br/>
      </ng-container>
    </mat-radio-group>

    <h3>Activity level</h3>
    <mat-radio-group formControlName="activityLevel" name="activityLevel">
      <ng-container *ngFor="let al of activityLevels">
        <mat-radio-button [value]="al">
          {{al.toString()|titlecase}}
        </mat-radio-button>
        <br/>
      </ng-container>
    </mat-radio-group>
  </form>

  <p *ngIf="error">
    {{error}}
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">Cancel</button>
  <button mat-button (click)="save()">
    Save
    <app-spin [active]="busy"></app-spin>
  </button>
</div>
