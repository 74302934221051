import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DateRange} from '@angular/material/datepicker';

@Component({
  selector: 'app-inline-date-range',
  template: '<mat-calendar' +
    '  [selected]="selectedRangeValue"\n' +
    '  (selectedChange)="selectedChange($event)">\n' +
    '</mat-calendar>'
})
export class InlineDateRangeComponent implements OnInit {
  @Input() start: Date | null
  @Input() end: Date | null
  @Input() selectedRangeValue: DateRange<Date> | undefined;
  @Output() selectedRangeValueChange = new EventEmitter<DateRange<Date>>();

  selectedChange(m: any) {
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Date>(m, null);
    } else {
      const start = this.selectedRangeValue.start;
      const end = m;
      if (end < start) {
        this.selectedRangeValue = new DateRange<Date>(end, start);
      } else {
        this.selectedRangeValue = new DateRange<Date>(start, end);
      }
    }
    this.selectedRangeValueChange.emit(this.selectedRangeValue);
  }

  ngOnInit(): void {
    if (this.start != null && this.end != null) {
      this.selectedChange(this.start);
      this.selectedChange(this.end);
    }
  }
}
