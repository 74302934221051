import * as statera from '@vimscore/statera-client';

/**
 * Also implemented by DurationDto
 */
export interface IDuration {
  readonly minutes: number;
  readonly seconds: number;
}

export const ZERO_DURATION: IDuration = {minutes: 0, seconds: 0};

export class Duration implements IDuration {
  private readonly _minutes: number;
  private readonly _seconds: number;

  constructor(minutes: number, seconds: number) {
    this._minutes = minutes;
    this._seconds = seconds;
  }

  get minutes(): number {
    return this._minutes;
  }

  get seconds(): number {
    return this._seconds;
  }

  public toString(): string {
    const s = this._seconds.toLocaleString(undefined, {minimumIntegerDigits: 2});
    return `${this._minutes}:${s}`;
  }

  public static fromSeconds(seconds: number): Duration {
    return new Duration(seconds / 60, seconds % 60);
  }

  public static fromMinutes(minutes: number): Duration {
    return new Duration(minutes, 0);
  }

  public toDto(): statera.DurationDto {
    return {
      minutes: this.minutes,
      seconds: this.seconds,
    }
  }
}

export function addDurations(a: IDuration, b: IDuration): Duration {
  if (a == null) {
    if (b == null) {
      return null;
    }
    return new Duration(b.seconds, b.minutes);
  } else if (b == null) {
    return new Duration(a.minutes, a.seconds);
  }

  let m = a.minutes + b.minutes;
  let s = a.seconds + b.seconds;

  m += Math.floor(s / 60);
  s = s % 60;
  return new Duration(m, s);
}

export function sumDurations(durations: IDuration[]): Duration {
  let minutes = 0, seconds = 0;

  for (const d of durations) {
    minutes += d.minutes;
    seconds += d.seconds;

    minutes += Math.floor(seconds / 60);
    seconds = seconds % 60;
  }

  return new Duration(minutes, seconds);
}

export function parseDuration(start: string, stop: string): Duration {
  const seconds = (Date.parse(stop) - Date.parse(start)) / 1000;
  const m = Math.floor(seconds / 60);
  const s = seconds % 60;
  return new Duration(m, s);
}

export function duration2number(duration: IDuration): number {
  let s = '' + duration.seconds;
  if (duration.seconds < 10) {
    s = '0' + s;
  }

  return parseFloat(duration.minutes + '.' + s);
}
