<h2 mat-dialog-title>Edit Personal Goals</h2>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Activity minutes</mat-label>
    <input matInput [(ngModel)]="goals.activityMinutes" name="activityMinutes" type="number">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Activity count</mat-label>
    <input matInput [(ngModel)]="goals.activityCount" name="activityCount" type="number">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Strength minutes</mat-label>
    <input matInput [(ngModel)]="goals.strengthMinutes" name="strengthMinutes" type="number">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Strength activities</mat-label>
    <input matInput [(ngModel)]="goals.strengthActivities" name="strengthActivities" type="number">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Endurance minutes</mat-label>
    <input matInput [(ngModel)]="goals.enduranceMinutes" name="enduranceMinutes" type="number">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Endurance activities</mat-label>
    <input matInput [(ngModel)]="goals.enduranceActivities" name="enduranceActivities" type="number">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Balance minutes</mat-label>
    <input matInput [(ngModel)]="goals.balanceMinutes" name="balanceMinutes" type="number">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Balance activities</mat-label>
    <input matInput [(ngModel)]="goals.balanceActivities" name="balanceActivities" type="number">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Flexibility minutes</mat-label>
    <input matInput [(ngModel)]="goals.flexibilityMinutes" name="flexibilityMinutes" type="number">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Flexibility activities</mat-label>
    <input matInput [(ngModel)]="goals.flexibilityActivities" name="flexibilityActivities" type="number">
  </mat-form-field>
  <br/>

  <p *ngIf="error">
    {{error}}
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">Cancel</button>
  <button mat-button (click)="save()">
    Save
    <app-spin [active]="busy"></app-spin>
  </button>
</div>
