<div class="basic-container">
  <table class="vs-table" *ngIf="periods.length > 0">
    <thead>
    <tr>
      <th>Start</th>
      <th>Completed</th>
      <th>Cancelled</th>
      <th># of weeks</th>
      <th>Inspect</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let p of periods">
      <td>{{ p.start | date:'mediumDate' }}</td>
      <td>{{ p.completed | date:'medium' }}</td>
      <td>{{ p.cancelled | date:'medium' }}</td>
      <td class="text-center">{{ p.weeks.length }}</td>
      <td><a [routerLink]="['period', p.uuid]">Show</a></td>
      <td>
        <button mat-flat-button color="warn" (click)="cancel()" *ngIf="canCancel(p)">
          Cancel
          <app-spin [active]="busy"></app-spin>
        </button>
      </td>
    </tr>
    </tbody>
  </table>

  <h2>Create new period</h2>
  <div style="width: 250px; padding-bottom: 50px">
    <mat-card class="demo-inline-calendar-card">
      <mat-calendar [(selected)]="periodStart"></mat-calendar>
    </mat-card>
  </div>
  <p>
    <button mat-flat-button color="primary" (click)="createPeriod()" [disabled]="periodStart == null">
      Create period starting:
      <!--    {{periodStart | date:'mediumDate'}}-->
      "{{formatDate()}}"
    </button>
  </p>
</div>
