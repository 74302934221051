<div *ngIf="currentFile" class="progress">
  <div
    class="progress-bar progress-bar-info progress-bar-striped"
    role="progressbar"
    attr.aria-valuenow="{{ progress }}"
    aria-valuemin="0"
    aria-valuemax="100"
    [ngStyle]="{ width: progress + '%' }"
  >
    {{ progress }}%
  </div>
</div>

<label class="btn btn-default">
  <input type="file" (change)="selectFile($event)"
         accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"/>
</label>

<button class="btn btn-success" [disabled]="!selectedFiles" (click)="upload()">
  Upload
</button>

<div class="alert alert-light" role="alert">{{ message }}</div>

<!--
<div class="card">
  <div class="card-header">List of Files</div>
  <ul class="list-group list-group-flush" *ngFor="let file of fileInfos | async">
    <li class="list-group-item">
      <a href="{{ file.url }}">{{ file.name }}</a>
    </li>
  </ul>
</div>
-->
