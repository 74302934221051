<app-button-row>
  <button class="mat-button mat-primary" (click)="showEditPersonDialog()">Edit person</button>
  <button class="mat-button mat-primary" (click)="addPhoneNumberDialog()">Add phone number</button>
  <button class="mat-button mat-primary" (click)="addEmailAddressDialog()">Add email address</button>
  <button class="mat-button mat-primary" (click)="addExternalActivityDialog()">Add external activity</button>
</app-button-row>

<div class="basic-container">
  <h2>Account</h2>
  <dl class="vs-dl" style="width: 700px">
    <ng-container *ngFor="let e of person.emailAddresses">
      <dt>Email</dt>
      <dd>
        <a href="mailto:{{e.address}}">{{e.address}}</a>, verified={{e.verified}}
      </dd>
    </ng-container>
    <ng-container *ngIf="person.emailAddresses.length == 0">
      <dt>Email</dt>
      <dd>No email addresses registered</dd>
    </ng-container>

    <ng-container *ngFor="let pn of person.phoneNumbers">
      <dt>Phone numbers</dt>
      <dd>
        <a href="tel:{{pn.phoneNumber}}">{{pn.phoneNumber | phoneNumber}}</a>, verified={{pn.verified}}
      </dd>
    </ng-container>
    <ng-container *ngIf="person.phoneNumbers.length == 0">
      <dt>Phone numbers</dt>
      <dd>No phone numbers registered</dd>
    </ng-container>
  </dl>

  <h2>Person</h2>
  <dl class="vs-dl" style="width: 700px">
    <dt>UUID</dt>
    <dd>{{person.uuid}}
      <app-copy-to-clipboard></app-copy-to-clipboard>
    </dd>
    <dt>Created</dt>
    <dd>{{person.created|date:'medium'}}</dd>
    <dt>Name</dt>
    <dd>{{person.name}}</dd>
    <dt>Gender</dt>
    <dd>{{person.gender|titlecase}}</dd>
    <dt>Activity level</dt>
    <dd>{{person.activityLevel|titlecase}}</dd>
    <dt>Date of birth</dt>
    <dd>{{person.dateOfBirth|date}}</dd>
    <dt>Age</dt>
    <dd>{{person.age|enum2string}}</dd>
    <dt>Injuries</dt>
    <dd>{{person.injuries}}</dd>
  </dl>

  <h2>Person Flags</h2>
  <dl class="vs-dl" style="width: 700px">
    <ng-container *ngFor="let f of allPersonFlags">
      <dt>{{ f }}</dt>
      <dd>
        {{ hasFlag(f) | yesNo }}
        <button mat-flat-button
                (click)="setPersonFlag(f, !hasFlag(f))"
                [disabled]="flagsState.busy">
          {{ !hasFlag(f) ? "Enable" : "Disable" }}
          <app-spin [active]="flagsState.busy"></app-spin>
        </button>
      </dd>
    </ng-container>
  </dl>

  <h2>Health profile</h2>
  <dl class="vs-dl" style="width: 700px">
    <dt>Capacity scores</dt>
    <dd>
    <span *ngIf="!healthProfile || !healthProfile.capacityScores">
      No capacity scores in health profile.
    </span>
      <span *ngIf="healthProfile && healthProfile.capacityScores">
    Strength: {{healthProfile.capacityScores.strength}}<br>
    Endurance: {{healthProfile.capacityScores.endurance}}<br>
    Balance: {{healthProfile.capacityScores.balance}}<br>
    Flexibility: {{healthProfile.capacityScores.flexibility}}
    </span>
    </dd>
  </dl>

  <h2>Prescription</h2>
  <p *ngIf="prescription == null">
    The user does not have an active prescription.
  </p>

  <dl class="vs-dl" style="width: 700px" *ngIf="prescription">
    <dt>Created</dt>
    <dd>{{prescription.createdDate|date:"medium"}}</dd>
    <dt>Goal</dt>
    <dd>{{prescription.goal|enum2string|titlecase}}</dd>
    <dt>Activity level</dt>
    <dd>{{prescription.activityLevel|enum2string|titlecase}}</dd>
    <dt>Capacity scores</dt>
    <dd>
      Strength: {{prescription.capacityScores.strength}}<br>
      Endurance: {{prescription.capacityScores.endurance}}<br>
      Balance: {{prescription.capacityScores.balance}}<br>
      Flexibility: {{prescription.capacityScores.flexibility}}
    </dd>
  </dl>

  <table class="vs-table text-center" style="min-width: 40em;" *ngIf="prescription">
    <thead>
    <tr>
      <th></th>
      <th class="text-right">Strength</th>
      <th class="text-right">Endurance</th>
      <th class="text-right">Balance</th>
      <th class="text-right">Flexibility</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let p of prescription.phases">
      <th class="text-right">Phase {{p.number}}</th>
      <td class="text-right">{{p.capacityMinutes.strength|duration}}</td>
      <td class="text-right">{{p.capacityMinutes.endurance|duration}}</td>
      <td class="text-right">{{p.capacityMinutes.balance|duration}}</td>
      <td class="text-right">{{p.capacityMinutes.flexibility|duration}}</td>
    </tr>
    </tbody>
  </table>
</div>
