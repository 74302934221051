import {Component} from '@angular/core';
import * as statera from '@vimscore/statera-client';
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {EditTeamRulesDialogComponent} from "./edit-team-rules-dialog/edit-team-rules-dialog.component";

@Component({
  selector: 'app-challenge-team-page',
  templateUrl: './challenge-team-page.component.html',
  styleUrls: ['./challenge-team-page.component.css']
})
export class ChallengeTeamPageComponent {
  challenge: statera.ChallengeDetailsDto;
  team: statera.ChallengeTeamDto;
  members: Array<statera.ChallengeMemberDto>;

  private readonly challengeUuid: string;
  private readonly teamUuid: string;

  constructor(private route: ActivatedRoute,
              private dialog: MatDialog,
              private challengeService: statera.ChallengeService) {
    this.challengeUuid = this.route.snapshot.paramMap.get('challengeUuid');
    this.teamUuid = this.route.snapshot.paramMap.get('teamUuid');

    this.route.data.subscribe((data: {
      challenge: statera.ChallengeDetailsDto
    }) => {
      this.setChallenge(data.challenge);
    });
  }

  private setChallenge(challenge: statera.ChallengeDetailsDto) {
    this.challenge = challenge
    this.team = this.challenge.teams.find(t => t.uuid == this.teamUuid)
    this.members = this.challenge.members.filter(m => m.team == this.teamUuid)
  }

  openEditRulesDialog() {
    const self = this;

    this.dialog.open(EditTeamRulesDialogComponent, {
      data: {
        challenge: this.challengeUuid,
        team: this.team,
      }
    }).afterClosed().subscribe(this.refreshObserver(self));
  }

  private refreshObserver(self: this) {
    return {
      next() {
        self.challengeService.getChallengeDetails(self.challengeUuid).subscribe({
          next(res: statera.ChallengeDetailsDto) {
            self.setChallenge(res);
          }
        })
      }
    };
  }
}
