<div style="width: 200px">
  <app-inline-date-range
    [selectedRangeValue]="range"
    (selectedRangeValueChange)="selectionChanged($event)"></app-inline-date-range>
</div>

<p>
  <mat-checkbox [(ngModel)]="humanMode" name="humanMode">Human mode</mat-checkbox>
</p>
<p>
  Presets:
  <a href (click)="setCurrentWeek()">Current week</a>&nbsp;
  <a href (click)="setPreviousWeek()">Previous week</a>
</p>

<p>
  <button mat-button
          type="submit"
          [disabled]="!!busy"
          (click)="run()">
    Create

    <app-spin [active]="busy == 'activities'"></app-spin>
  </button>
</p>

<p *ngIf="output != null">
  Error: {{output}}
</p>
