import * as statera from '@vimscore/statera-client';
import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {debounceTime, distinctUntilChanged, filter, Observable, switchMap} from "rxjs";

export class AccountSearchForm {

  public readonly query = new FormControl(null)
  public readonly accountInfo = new FormControl(null)

  readonly formGroup = new FormGroup({
    query: this.query,
    accountInfo: this.accountInfo,
  })

  reset() {
    this.query.setValue('')
    this.accountInfo.setValue(null)
  }
}

@Component({
  selector: 'app-account-search',
  templateUrl: './account-search.component.html',
  styleUrls: ['./account-search.component.css']
})
export class AccountSearchComponent implements OnInit {

  @Input()
  label?: string

  @Input()
  accountSearchForm: AccountSearchForm

  accounts$: Observable<statera.AccountInfo[]>;

  constructor(private consoleService: statera.ConsoleService) { }

  ngOnInit(): void {
    this.accounts$ = this.accountSearchForm.query.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(150),
      filter((name) => !!name),
      switchMap<string, Observable<Array<statera.AccountInfo>>>(name => {
        console.log("query: ", name);
        return this.consoleService.accountSearch(name);
      })
    );
  }

  optionSelected($event: MatAutocompleteSelectedEvent) {
    console.info("optionSelected", $event);
    this.accountSearchForm.accountInfo.setValue($event.option.value)
  }

  display(account: statera.AccountInfo): string {
    return account == null
      ? null
      : account.email || account.personUuid
  }
}
