import * as statera from '@vimscore/statera-client';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { finalize, firstValueFrom } from "rxjs";

@Component({
  selector: 'app-nbar-exercises-page',
  templateUrl: './nbar-exercises-page.component.html',
  styleUrls: ['./nbar-exercises-page.component.css']
})
export class NbarExercisesPageComponent implements OnInit {
  busy: boolean = false
  exercises: Array<statera.NbarExerciseDto>;

  constructor(private nbarService: statera.NbarService,
              private sanitizer: DomSanitizer,
  ) { }

  async ngOnInit(): Promise<void> {
    this.busy = true;
    const res = this.nbarService.nbarExerciseQuery().pipe(
      finalize(() => this.busy = false)
    );

    this.exercises = await firstValueFrom(res);
    this.exercises.sort((a, b) => a.name.localeCompare(b.name))
  }

  trust(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
