<p>
  This is a report of all programs that can be generated.
</p>

<form>
  <p>
    <mat-form-field>
      <mat-label>Strength</mat-label>
      <input matInput type="text" [(ngModel)]="programs.strengthTag" id="strengthTag" name="strengthTag">
    </mat-form-field>
    <a href="#" (click)="programs.strengthTag = 'vs-300'; false">vs-300</a>&nbsp;
    <a href="#" (click)="programs.strengthTag = 'test2'; false">test2</a>
  </p>
  <p>
    <mat-form-field>
      <mat-label>Endurance</mat-label>
      <input matInput type="text" [(ngModel)]="programs.enduranceTag" id="enduranceTag" name="enduranceTag">
    </mat-form-field>
    <a href="#" (click)="programs.enduranceTag = 'vs-300-endurance'; false">vs-300-endurance</a>&nbsp;
    <a href="#" (click)="programs.enduranceTag = 'vs-300'; false">vs-300</a>&nbsp;
    <a href="#" (click)="programs.enduranceTag = 'test2'; false">test2</a>
  </p>
  <p>
    <mat-form-field>
      <mat-label>Balance</mat-label>
      <input matInput type="text" [(ngModel)]="programs.balanceTag" id="balanceTag" name="balanceTag">
    </mat-form-field>
    <a href="#" (click)="programs.balanceTag = 'vs-300'; false">vs-300</a>&nbsp;
    <a href="#" (click)="programs.balanceTag = 'test2'; false">test2</a>
  </p>
  <p>
    <mat-form-field>
      <mat-label>Flexibility</mat-label>
      <input matInput type="text" [(ngModel)]="programs.flexibilityTag" id="flexibilityTag" name="flexibilityTag">
    </mat-form-field>
    <a href="#" (click)="programs.flexibilityTag = 'vs-300'; false">vs-300</a>&nbsp;
    <a href="#" (click)="programs.flexibilityTag = 'test2'; false">test2</a>
  </p>
  <p>
    <mat-form-field>
      <mat-label>Exercise tags</mat-label>
      <input matInput type="text" [(ngModel)]="programs.exerciseTags" name="exerciseTags">
    </mat-form-field>
    <a href="#" (click)="programs.exerciseTags = ''; false">Blank</a>&nbsp;
    <a href="#" (click)="programs.exerciseTags = 'VS300'; false">VS300</a>&nbsp;
    <a href="#" (click)="programs.exerciseTags = 'VS300, VS300-2'; false">VS300 and VS300-2</a>
    <a href="#" (click)="programs.exerciseTags = 'VS300, VS300-2, VimHome'; false">VS300, VS300-2, VimHome</a>
    <a href="#" (click)="programs.exerciseTags = 'VimHome'; false">VimHome</a>
  </p>

  <p>
    <mat-checkbox [(ngModel)]="programs.approvedOnly" name="approvedOnly">Approved only</mat-checkbox>
  </p>
  <p>
    <mat-checkbox [(ngModel)]="programs.humanMode" name="humanMode">Human mode</mat-checkbox>
  </p>

  <button
    mat-button type="submit"
    [disabled]="busy"
    (click)="runPrograms()">
    Create report

    <app-spin [active]="busy"></app-spin>
  </button>
  <span *ngIf="output">{{ output }}</span>
</form>
