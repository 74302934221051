import {ActivatedRoute} from '@angular/router';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-inspect',
  templateUrl: './inspect-page.component.html',
  styleUrls: ['./inspect-page.component.css']
})
export class InspectPageComponent implements OnInit {

  selectedIndex: number
  personUuid: string

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.personUuid = this.route.snapshot.paramMap.get('personUuid');

    let parts = (sessionStorage.getItem("inspector-tab") || "").split(":")
    if (parts.length == 2 && parts[0] == this.personUuid) {
      this.selectedIndex = Number.parseInt(parts[1]) || 0
    }
  }

  onTabChanged(index: number) {
    sessionStorage.setItem("inspector-tab", `${this.personUuid}:${index}`)
  }
}
