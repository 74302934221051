import {Component} from '@angular/core';
import {AuthenticatedUser, AuthenticationService} from '../../services/authentication.service';
import {Settings} from '../../services/settings';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.css']
})
export class HeaderNavComponent {
  authenticatedUser: AuthenticatedUser;

  constructor(public settings: Settings,
              private authenticationService: AuthenticationService,
              private router: Router) {
    authenticationService.authenticatedUser.subscribe(u => this.authenticatedUser = u);
  }

  async logout(): Promise<void> {
    this.authenticationService.logout();
    await this.router.navigate(['login']);
  }
}
