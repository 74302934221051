import {Pipe, PipeTransform} from '@angular/core';
import {Duration, IDuration, sumDurations} from '../utils/duration';

@Pipe({
  name: 'durationSum'
})
export class DurationSumPipe implements PipeTransform {
  transform(durations: IDuration[]): Duration {
    return sumDurations(durations);
  }
}
