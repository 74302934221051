<mat-toolbar>
  <span>Challenge</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<app-button-row>
  <button class="mat-button mat-primary" (click)="openAddChallengeDialog()">Create challenge</button>
</app-button-row>

<div class="basic-container">

  <app-loader style="min-width: 200px" [observable]="challenges">
    <table class="vs-table" style="min-width: 200px">
      <thead>
      <tr>
        <th>Name</th>
        <th>Type</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let c of challenges | async">
        <td>
          <a routerLink="{{c.uuid}}">{{c.name}}</a>
        </td>
        <td>
          {{c.teamChallenge ? 'Team' : 'Individual' }}
        </td>
      </tr>
      </tbody>
    </table>
  </app-loader>
</div>
