import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DateRange} from "@angular/material/datepicker";
import {YearWeek} from "../../utils/year-week";

@Component({
  selector: 'app-week-navigator',
  templateUrl: './week-navigator.component.html',
  styleUrls: ['./week-navigator.component.css']
})
export class WeekNavigatorComponent {

  @Input() week: YearWeek | undefined;
  @Output() weekChange = new EventEmitter<YearWeek>();

  next(): void {
    if (!this.week) {
      return
    }

    this.week = this.week.next()
    this.weekChange.emit(this.week)
  }

  prev(): void {
    if (!this.week) {
      return
    }

    this.week = this.week.previous()
    this.weekChange.emit(this.week)
  }
}
