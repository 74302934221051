<h2 mat-dialog-title>Refresh {{challenge.name}}</h2>
<div mat-dialog-content style="min-width: 800px; min-height: 10em">
  <mat-checkbox [(ngModel)]="refreshAll" name="refreshAll">
    Refresh all weeks
  </mat-checkbox>
  <br/>
  <mat-radio-group [(ngModel)]="yearWeek" name="yearWeek" [disabled]="refreshAll">
    <mat-radio-button
      style="min-width: 7em"
      *ngFor="let w of weeks" [value]="w">
      {{w}}
    </mat-radio-button>
  </mat-radio-group>

  <app-job-response-table [response]="job.jobResponse"></app-job-response-table>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">Cancel</button>
  <button mat-button class="mat-primary" (click)="refresh()" [disabled]="job.busy">
    Do refresh
    <app-spin [active]="job.busy"></app-spin>
  </button>
</div>
