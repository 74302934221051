import * as statera from '@vimscore/statera-client';
import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {busyDialog} from "../../../utils/dialog";

@Component({
  templateUrl: './add-team-dialog.component.html',
})
export class AddTeamDialogComponent {
  challenge: string
  name = ""
  busy = false
  error: string;

  constructor(private challengeService: statera.ChallengeService,
              readonly dialogRef: MatDialogRef<AddTeamDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.challenge = data.challenge
  }

  add() {
    const self = this;

    const req: statera.CreateChallengeTeamReqDto = {
      challenge: self.challenge,
      name: self.name,
    };

    self.busy = true;
    self.error = null;
    self.challengeService.createTeam(req).subscribe(busyDialog(this));
  }
}
