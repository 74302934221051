import * as statera from '@vimscore/statera-client';
import {Component, Inject, OnInit} from '@angular/core';
import {JobState} from "../../../components/job-response-table/job.state";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {YearWeek} from "../../../utils/year-week";

@Component({
  templateUrl: './refresh-dialog.component.html',
})
export class RefreshDialogComponent implements OnInit {
  challenge: statera.ChallengeDetailsDto

  refreshAll = false
  yearWeek?: YearWeek

  readonly job = new JobState()
  weeks: Array<YearWeek>

  constructor(private jobService: statera.JobService,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.challenge = data.challenge

    const start = YearWeek.parse(this.challenge.startWeek)
    const today = YearWeek.today()
    this.weeks = [];
    for (let week = start; week.compareTo(today) <= 1; week = week.next()) {
      this.weeks.push(week);
    }
  }

  ngOnInit(): void {
  }

  refresh() {
    this.job.setBusy()

    const yearWeek = this.refreshAll || this.yearWeek == null ? null : this.yearWeek.toString();

    this.jobService.refreshChallenge(this.challenge.uuid, yearWeek).subscribe({
      next: this.job.setResponse,
      error: this.job.setError
    })
  }
}
