<mat-toolbar>
  <a mat-icon-button routerLink="..">
    <mat-icon>arrow_back</mat-icon>
  </a>
  <span>Console Account</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<div class="basic-container">
  <dl class="vs-dl">
    <dt>Name</dt>
    <dd>{{ account.name }}</dd>
    <dt>Email</dt>
    <dd>{{ account.email }}</dd>
  </dl>

  <button mat-button (click)="sendResetPasswordLink()" [disabled]="!account.email">
    Send reset password link
  </button>
</div>
