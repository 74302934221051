import {Pipe, PipeTransform} from '@angular/core';
import {Capacity, CapacityLevel} from '@vimscore/statera-client';

const map = {
  // Capacity
  STR: Capacity.Strength,
  RES: Capacity.Endurance,
  NMO: Capacity.Balance,
  FLX: Capacity.Flexibility,

  // Fitness level => Capacity level
  BASIC: CapacityLevel.Basic,
  ACTIVE: CapacityLevel.Active,
  SPORT: CapacityLevel.Sporty,
  EXTREME: CapacityLevel.Extreme,
};

@Pipe({
  name: 'from4tune'
})
export class From4tunePipe implements PipeTransform {

  transform(value: string): string {
    const alternate = map[value];
    return alternate || value;
  }
}
