import {Pipe, PipeTransform} from '@angular/core';
import {duration2number, IDuration} from '../utils/duration'

// TODO: Rename to Duration2number
@Pipe({
  name: 'duration2float'
})
export class Duration2FloatPipe implements PipeTransform {

  transform(duration: IDuration): number {
    return duration2number(duration);
  }
}
