import {Pipe, PipeTransform} from '@angular/core';
import {ActivityLevel, Age, Goal, WorkoutPart, TestType} from '@vimscore/statera-client';

const map = {};

map[ActivityLevel.Sedentary] = "sedentary";
map[ActivityLevel.Sporty] = "sporty";
map[ActivityLevel.Vigorous] = "vigorous";

map[Age.Age1824] = "18 - 24";
map[Age.Age2534] = "25 - 34";
map[Age.Age3549] = "34 - 49";
map[Age.Age5059] = "50 - 59";
map[Age.Age6069] = "60 - 69";
map[Age.Age70] = "70+";

map[Goal.Wellness] = "wellness";

map[WorkoutPart.WarmUp] = "warm up";
map[WorkoutPart.Main] = "main";
map[WorkoutPart.CoolDown] = "cool down";

map[TestType.SelfTest] = "Self test";
map[TestType.VimScoreTest] = "VimScore test";
map[TestType.Default] = "Default";

@Pipe({
  name: 'enum2string'
})
export class Enum2stringPipe implements PipeTransform {

  transform(value: string): string {
    const alternate = map[value];
    return alternate || value;
  }
}
