<h2 mat-dialog-title>Add team</h2>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Team name</mat-label>
    <input matInput [(ngModel)]="name" name="name">
  </mat-form-field>

  <br/>

  <p *ngIf="error">
    {{error}}
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">Cancel</button>
  <button mat-button (click)="add()">
    Add
    <app-spin [active]="busy"></app-spin>
  </button>
</div>
