import * as dialogs from "../../../dialogs";
import * as statera from '@vimscore/statera-client';
import structuredClone from '@ungap/structured-clone';
import {ActivatedRoute} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-inspect-personal-goals',
  templateUrl: './inspect-personal-goals.component.html',
  styleUrls: ['./inspect-personal-goals.component.css']
})
export class InspectPersonalGoalsComponent implements OnInit {

  person: statera.PersonDto
  goals: statera.PersonalGoalsDto

  constructor(private personService: statera.PersonService,
              private route: ActivatedRoute,
              private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data: {
      personInfo: statera.PersonInfoDto,
      goals: statera.PersonalGoalsDto,
    }) => {
      this.person = data.personInfo.person;
      this.goals = data.goals;
    });
  }

  showEditGoalsDialog() {
    const self = this;

    this.dialog.open(dialogs.EditPersonalGoalsDialogComponent, {
      data: structuredClone({
        person: self.person,
        goals: self.goals,
      })
    }).afterClosed().subscribe({
      next(_: boolean) {
        self.refresh();
      }
    })
  }

  private refresh() {
    const self = this;

    self.personService.getPersonalGoals(self.person.uuid).subscribe({
      next(res: statera.PersonalGoalsDto) {
        self.goals = res
      }
    })
  }
}
