import * as statera from '@vimscore/statera-client';
import {ActivatedRoute} from "@angular/router";
import {Component, OnInit} from "@angular/core";
import {busyComponent} from "src/app/utils/dialog";
import {firstValueFrom, switchMap, tap} from "rxjs";
import {AccountSearchForm} from "../../../components/account-search/account-search.component";

@Component({
  selector: 'app-user-page',
  templateUrl: './console-accounts-page.component.html',
  styleUrls: ['./console-accounts-page.component.css']
})
export class ConsoleAccountsPageComponent implements OnInit {

  accounts: Array<statera.ConsoleAccountDto>;

  createUser: statera.CreateConsoleAccountReqDto = {}

  busy: boolean
  error: string
  accountSearchForm = new AccountSearchForm()

  constructor(
    private consoleService: statera.ConsoleService,
    private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe((data: { accounts: Array<statera.ConsoleAccountDto> }) => {
      this.accounts = data.accounts;
    });
  }

  async doCreateAccount() {
    this.createUser.accountId = (this.accountSearchForm.accountInfo.value as statera.AccountInfo).accountId
    console.log("Creating account for ", this.createUser)

    let res = this.consoleService.createConsoleAccount(this.createUser).pipe(
      switchMap(_ => this.consoleService.getConsoleAccounts()),
      tap(busyComponent(this))
    )

    this.accounts = await firstValueFrom(res)
    this.createUser = {}
  }
}
