import * as statera from '@vimscore/statera-client';
import {AccountSearchForm} from "../../../components/account-search/account-search.component";
import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {busyDialog} from "../../../utils/dialog";

@Component({
  templateUrl: './add-member-dialog.component.html',
})
export class AddChallengeMemberDialog {
  challenge: statera.ChallengeDetailsDto
  teams: statera.ChallengeTeamDto[]
  team: statera.ChallengeTeamDto
  accountSearchForm = new AccountSearchForm()
  busy = false
  error: string;

  constructor(private challengeService: statera.ChallengeService,
              readonly dialogRef: MatDialogRef<AddChallengeMemberDialog>,
              @Inject(MAT_DIALOG_DATA) public data) {
    this.challenge = data.challenge
    this.challenge.teams.sort((a, b) => a.name.localeCompare(b.name))
  }

  add() {
    const self = this;

    const req: statera.JoinChallengeReqDto = {
      challenge: self.challenge.uuid,
      team: self.team && self.team.uuid,
      person: (this.accountSearchForm.accountInfo.value as statera.AccountInfo).personUuid,
    };

    self.busy = true;
    self.error = null;
    self.challengeService.joinChallenge(req).subscribe(busyDialog(self));
  }
}
