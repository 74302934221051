<p>
  This is a report of all exercises.
</p>

<form>
  <p>
    <mat-checkbox [(ngModel)]="exercises.humanMode" name="humanMode">Human mode</mat-checkbox>
  </p>

  <button
    mat-button type="submit"
    [disabled]="busy"
    (click)="runExercises()">
    Create report

    <app-spin [active]="busy"></app-spin>
  </button>
  <span *ngIf="output">{{ output }}</span>
</form>
