import {Injectable} from '@angular/core';
import {JwtConfig, JwtHelperService} from '@auth0/angular-jwt';
import {Settings} from './settings';
import {BehaviorSubject, Observable} from 'rxjs';

export function jwtOptionsFactory(authenticationService: AuthenticationService, settings: Settings): JwtConfig {
  return {
    tokenGetter: (req) => {
      return authenticationService.jwtToken;
    },
    allowedDomains: [
      settings.stateraHost,
      settings._4tuneHost,
    ],
  };
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  static helper = new JwtHelperService();
  private static tokenKey = 'token';
  private readonly authenticatedUserSubject: BehaviorSubject<AuthenticatedUser>;
  private _authenticatedUser: Observable<AuthenticatedUser>;

  constructor() {
    const user = AuthenticationService.loadUser();

    this.authenticatedUserSubject = new BehaviorSubject<AuthenticatedUser>(user);
    this._authenticatedUser = this.authenticatedUserSubject.asObservable();
  }

  get authenticatedUser(): Observable<AuthenticatedUser> {
    return this.authenticatedUserSubject;
  }

  get authenticatedUserValue(): AuthenticatedUser {
    return this.authenticatedUserSubject.value;
  }

  get jwtToken(): string {
    return localStorage.getItem(AuthenticationService.tokenKey);
  }

  private static loadUser(): AuthenticatedUser | null {
    const token = this.loadJwtToken();

    if (!token) {
      return null
    }

    const payload = this.helper.decodeToken(token);
    return new AuthenticatedUser(token, payload.email);
  }

  private static loadJwtToken(): string | null {
    const token = localStorage.getItem(AuthenticationService.tokenKey);

    if (!this.helper.isTokenExpired(token)) {
      return token;
    }

    localStorage.removeItem(AuthenticationService.tokenKey);

    return null;
  }

  setJwtToken(token: string): void {
    localStorage.setItem(AuthenticationService.tokenKey, token);
    const user = AuthenticationService.loadUser();
    this.authenticatedUserSubject.next(user);
  }

  logout(): void {
    localStorage.removeItem(AuthenticationService.tokenKey);
    this.authenticatedUserSubject.next(null);
  }
}

export class AuthenticatedUser {
  constructor(public jwtToken: string, public email: string) {}
}
