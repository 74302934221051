import * as domain from "../../../../utils/domain";
import * as statera from '@vimscore/statera-client';
import {ActivatedRoute} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {busyComponent, errorOperator} from "../../../../utils/dialog";
import {firstValueFrom, switchMap, tap} from "rxjs";
import {catchError} from "rxjs/operators";

class CreateAssessmentForm {
  public readonly testType = new FormControl("", [Validators.required])
  public readonly strength = new FormControl(null, [Validators.required])
  public readonly endurance = new FormControl(null, [Validators.required])
  public readonly balance = new FormControl(null, [Validators.required])
  public readonly flexibility = new FormControl(null, [Validators.required])

  readonly group = new FormGroup({
    testType: this.testType,
    strength: this.strength,
    endurance: this.endurance,
    balance: this.balance,
    flexibility: this.flexibility,
  })
}

@Component({
  selector: 'app-inspect-assessments',
  templateUrl: './inspect-assessments.component.html',
  styleUrls: ['./inspect-assessments.component.css']
})
export class InspectAssessmentsComponent implements OnInit {
  private personUuid: string
  busy: boolean = false
  error: string = ""
  assessments: Array<statera.AssessmentDto> = [];

  readonly testTypes = domain.testTypes;

  form = new CreateAssessmentForm()

  constructor(private route: ActivatedRoute,
              private personService: statera.PersonService) { }

  async ngOnInit() {
    this.personUuid = this.route.snapshot.paramMap.get('personUuid');
    await this.fetchData();
  }

  async fetchData() {
    const res = this.personService.assessments(this.personUuid, 0, 1000)
      .pipe(
        tap(busyComponent(this))
      );

    this.assessments = await firstValueFrom(res)
  }

  async createAssessment() {
    const assessment: statera.AssessmentDto = {
      testType: this.form.testType.value,
      strength: this.form.strength.value,
      endurance: this.form.endurance.value,
      balance: this.form.balance.value,
      flexibility: this.form.flexibility.value,
    };

    const res = this.personService.saveAssessment(this.personUuid, assessment)
      .pipe(
        switchMap((_) => this.personService.assessments(this.personUuid, 0, 1000)),
        catchError(errorOperator(this))
      )
    this.assessments = await firstValueFrom(res)
  }
}
