import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute} from '@angular/router';
import * as statera from '@vimscore/statera-client';
import {Settings} from "../../../services/settings";

@Component({
  selector: 'app-animations',
  templateUrl: './animations-page.component.html',
  styleUrls: ['./animations-page.component.css']
})
export class AnimationsPageComponent implements OnInit {

  form: FormGroup

  animations: Array<statera.AnimationDto> = null;
  private allAnimations: Array<statera.AnimationDto>;

  constructor(public settings: Settings, private route: ActivatedRoute, private formBuilder: FormBuilder,
              private consoleService: statera.ConsoleService) {
    this.form = this.formBuilder.group({
      sortBy: 'exerciseDescriptionId'
    });

    this.form.valueChanges.subscribe(() => this.onChange());
  }

  ngOnInit(): void {
    this.consoleService.getAnimations().subscribe(res => {
      this.allAnimations = res;
      this.onChange();
    });
  }

  sortByExerciseDescriptionId(a: statera.AnimationDto, b: statera.AnimationDto): number {
    return a.externalExerciseId.localeCompare(b.externalExerciseId)
  }

  sortByFileName(a: statera.AnimationDto, b: statera.AnimationDto): number {
    return a.fileName.localeCompare(b.fileName)
  }

  sortByExerciseName(a: statera.AnimationDto, b: statera.AnimationDto): number {
    return a.name.localeCompare(b.name)
  }

  onChange() {
    let sort
    let sortBy = this.form.get("sortBy").value;
    if (sortBy == "exerciseDescriptionId") {
      sort = this.sortByExerciseDescriptionId
    } else if (sortBy == "exerciseName") {
      sort = this.sortByExerciseName
    } else {
      sort = this.sortByFileName
    }
    this.animations = this.allAnimations.sort(sort);
  }
}

function andThen<A, B, C>(a: (A) => B, b: (B) => C): (A) => C {
  return (arg: A) => b(a(arg));
}

function and<A>(a: (A) => boolean, b: (A) => boolean): (A) => boolean {
  return (arg: A) => a(arg) && b(arg);
}
