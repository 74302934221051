import * as statera from '@vimscore/statera-client';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {YearWeek} from "../utils/year-week";
import {catchError} from 'rxjs/operators';

@Injectable({ providedIn: "root" })
export class ActivityResolver implements Resolve<statera.ActivitySessionDto> {

  constructor(private activityService: statera.ActivityService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<statera.ActivitySessionDto> {
    const activityUuid = route.paramMap.get('activityUuid');
    return this.activityService.getActivity(activityUuid);
  }
}

@Injectable({ providedIn: "root" })
export class ChallengeDetailsResolver implements Resolve<statera.ChallengeDetailsDto> {

  constructor(private challengeService: statera.ChallengeService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<statera.ChallengeDetailsDto> {
    const uuid = route.paramMap.get('challengeUuid');
    return this.challengeService.getChallengeDetails(uuid);
  }
}

@Injectable({ providedIn: "root" })
export class WorkoutResolver implements Resolve<statera.WorkoutDto> {

  constructor(private workoutService: statera.WorkoutService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<statera.WorkoutDto> {
    const uuid = route.paramMap.get('workoutUuid');
    return this.workoutService.getWorkout(uuid);
  }
}

@Injectable({ providedIn: "root" })
export class PeriodResolver implements Resolve<statera.VimPeriodDto> {

  constructor(private periodService: statera.PeriodService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<statera.VimPeriodDto> {
    const personUuid = route.paramMap.get('personUuid');
    const periodUuid = route.paramMap.get('periodUuid');
    return this.periodService.getPeriod(personUuid, periodUuid);
  }
}

@Injectable({ providedIn: "root" })
export class PersonInfoResolver implements Resolve<statera.PersonInfoDto> {

  constructor(private consoleService: statera.ConsoleService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<statera.PersonInfoDto> {
    const personUuid = route.paramMap.get('personUuid');
    return this.consoleService.personInfo(personUuid).pipe(
      catchError(res => {
        return res.status == 404 ? of(null) : throwError(res);
      }));
  }
}

@Injectable({ providedIn: "root" })
export class PersonResolver implements Resolve<statera.PersonDto> {

  constructor(private personService: statera.PersonService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<statera.PersonDto> {
    const personUuid = route.paramMap.get('personUuid');
    return this.personService.getPerson(personUuid).pipe(
      catchError(res => {
        return res.status == 404 ? of(null) : throwError(res);
      }));
  }
}

@Injectable({ providedIn: "root" })
export class PersonalGoalsResolver implements Resolve<statera.PersonalGoalsDto> {

  constructor(private personService: statera.PersonService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<statera.PersonalGoalsDto> {
    const personUuid = route.paramMap.get('personUuid');
    return this.personService.getPersonalGoals(personUuid).pipe(
      catchError(res => {
        return res.status == 404 ? of(null) : throwError(res);
      }));
  }
}

@Injectable({ providedIn: "root" })
export class PersonWeekSummaryResolver implements Resolve<statera.PersonWeekSummaryDto> {

  constructor(private personService: statera.PersonService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<statera.PersonWeekSummaryDto> {
    const personUuid = route.paramMap.get('personUuid');
    const yearWeek = YearWeek.today();
    return this.personService.personWeekSummaryForWeek(personUuid, yearWeek.toString()).pipe(
      catchError(res => {
        return res.status == 404 ? of(null) : throwError(res);
      }));
  }
}

@Injectable({ providedIn: "root" })
export class ConsoleAccountsResolver implements Resolve<Array<statera.ConsoleAccountDto>> {

  constructor(private consoleService: statera.ConsoleService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<statera.ConsoleAccountDto>> | Observable<never> {
    return this.consoleService.getConsoleAccounts();
  }
}

@Injectable({ providedIn: "root" })
export class ConsoleAccountResolver implements Resolve<statera.ConsoleAccountDto> {

  constructor(private consoleService: statera.ConsoleService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<statera.ConsoleAccountDto> | Observable<never> {
    const id = Number.parseInt(route.paramMap.get('consoleAccountId'));
    return this.consoleService.getConsoleAccount(id).pipe(
      catchError(res => {
        return res.status == 404 ? of(null) : throwError(res);
      }));
  }
}

@Injectable({ providedIn: "root" })
export class AnimationsResolver implements Resolve<Array<statera.AnimationDto>> {

  constructor(private consoleService: statera.ConsoleService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<statera.AnimationDto>> | Observable<never> {
    return this.consoleService.getAnimations().pipe(
      catchError(res => {
        return res.status == 404 ? of(null) : throwError(res);
      }));
  }
}

@Injectable({ providedIn: "root" })
export class AnimationResolver implements Resolve<statera.AnimationDto> {

  constructor(private consoleService: statera.ConsoleService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<statera.AnimationDto> | Observable<never> {
    const id = route.paramMap.get('exerciseDescriptionId');
    return this.consoleService.getAnimation(id).pipe(
      catchError(res => {
        return res.status == 404 ? of(null) : throwError(res);
      }));
  }
}
