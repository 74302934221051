import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const ok = !!this.authenticationService.authenticatedUserValue;

    if (!ok) {
      // noinspection JSIgnoredPromiseFromCall
      this.router.navigate(['login']);
      return false;
    }

    return true;
  }
}
