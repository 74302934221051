<h2 mat-dialog-title>Add external activity</h2>
<div mat-dialog-content>
  <form [formGroup]="form.group">

    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" name="name">
    </mat-form-field>
    <br/>

    <mat-form-field appearance="fill">
      <mat-label>Date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="start" name="start">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <br/>

    <mat-form-field>
      <mat-label>Strength</mat-label>
      <input matInput formControlName="strength" name="strength" type="number" min="0">
    </mat-form-field>
    <br/>

    <mat-form-field>
      <mat-label>Endurance</mat-label>
      <input matInput formControlName="endurance" name="endurance" type="number" min="0">
    </mat-form-field>
    <br/>

    <mat-form-field>
      <mat-label>Balance</mat-label>
      <input matInput formControlName="balance" name="balance" type="number" min="0">
    </mat-form-field>
    <br/>

    <mat-form-field>
      <mat-label>Flexibility</mat-label>
      <input matInput formControlName="flexibility" name="flexibility" type="number" min="0">
    </mat-form-field>
    <br/>

    <!--
    [classList]="[form.group.errors?.nonZeroCapacityMinutes && (form.group.touched || form.group.dirty) && 'mat-error']"
    -->
    <p>
      At least one capacity has to be given.
    </p>

    <p *ngIf="error">
      {{error}}
    </p>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">Cancel</button>
  <button mat-button (click)="save()" [disabled]="form.group.invalid">
    Save
    <app-spin [active]="busy"></app-spin>
  </button>
</div>
