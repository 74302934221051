<mat-toolbar>
  <a mat-icon-button routerLink="..">
    <mat-icon>arrow_back</mat-icon>
  </a>
  <span>Challenge: {{challenge.name}}</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<mat-tab-group animationDuration="0ms"
               [selectedIndex]="tab.index"
               (selectedIndexChange)="tab.onChange($event)">
  <mat-tab label="Overview">
    <app-button-row>
      <a class="mat-button mat-primary" routerLink="week/{{ yearWeek }}">Show weeks</a>
      <button class="mat-button mat-primary" (click)="openEditDetailsDialog()">Edit details</button>
      <button class="mat-button mat-primary" (click)="openEditRulesDialog()">Edit rules</button>
      <button class="mat-button mat-primary" (click)="openRefreshDialog()">Refresh</button>
      <button class="mat-button mat-primary" (click)="openDeleteDialog()">Delete challenge</button>
    </app-button-row>

    <div class="basic-container">
      <dl class="vs-dl" style="width: 700px">
        <dt>UUID</dt>
        <dd>{{challenge.uuid}}</dd>
        <dt>Name</dt>
        <dd>{{challenge.name}}</dd>
        <dt>Start date / week</dt>
        <dd>{{challenge.startDate | date:"medium" }} / {{challenge.startWeek}}</dd>
        <dt>End date</dt>
        <dd>{{challenge.endDate | date:"medium"}}</dd>
        <dt>Team challenge</dt>
        <dd style="text-transform: capitalize">{{challenge.teamChallenge | yesNo}}</dd>
      </dl>
    </div>
    <div class="basic-container">
      <h2>Rules</h2>
      <dl class="vs-dl" style="width: 700px">
        <dt>Rules mode</dt>
        <dd>{{challenge.rulesMode}}</dd>
        <dt>Minutes</dt>
        <dd>
          {{challenge.minutes.strength | duration:"pretty"}}<br/>
          {{challenge.minutes.endurance | duration:"pretty"}}<br/>
          {{challenge.minutes.balance | duration:"pretty"}}<br/>
          {{challenge.minutes.flexibility | duration:"pretty"}}
        </dd>
      </dl>
    </div>
    <div class="basic-container">
      <h2>Report</h2>
      <button mat-flat-button class="mat-primary" (click)="createReport()">
        Create report
        <app-spin [active]="reportState.busy"></app-spin>
      </button>
      <div>
        {{ reportState.output }}
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Members">
    <app-button-row>
      <button class="mat-button mat-primary" (click)="openAddMemberDialog()">Add member</button>
    </app-button-row>
    <div class="basic-container">
      <table class="vs-table vs-grey-odd">
        <thead>
        <tr>
          <th>Name</th>
          <th *ngIf="challenge.teamChallenge">Team</th>
          <th>Admin</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let m of challenge.members">
          <td>
            <a routerLink="/inspector/{{ m.person }}">
              {{ m.name }}
            </a>
          </td>
          <td *ngIf="challenge.teamChallenge">
            <a routerLink="./team/{{ m.team }}">
              {{ (teams[m.team] || {name: "Missing name"}).name }}
            </a>
          </td>
          <td>
            {{ m.adminFlag | yesNo }}
          </td>
          <td>
            <button mat-icon-button (click)="deleteMember(m)">
              <mat-icon inline>delete</mat-icon>
            </button>
            <button mat-button *ngIf="!m.adminFlag" (click)="setAdminFlag(m, true)">
              Add admin flag
            </button>
            <button mat-button *ngIf="m.adminFlag" (click)="setAdminFlag(m, false)">
              Remove admin flag
            </button>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
  </mat-tab>

  <mat-tab label="Teams" *ngIf="challenge.teamChallenge">

    <app-button-row>
      <button mat-button class="mat-button mat-primary" (click)="openCreateTeamDialog()">
        Create team
      </button>
    </app-button-row>

    <div class="basic-container">
      <div *ngIf="challenge.teams.length == 0">
        No Teams defined yet
      </div>
      <table class="vs-table" *ngIf="challenge.teams.length > 0">
        <thead>
        <tr>
          <th>Team</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let t of challenge.teams">
          <td>
            <a routerLink="team/{{ t.uuid }}">{{ t.name }}</a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </mat-tab>
</mat-tab-group>
