<h2 mat-dialog-title>Add team member</h2>
<div mat-dialog-content>

  <app-account-search [accountSearchForm]="accountSearchForm"></app-account-search>

  <ng-container *ngIf="challenge.teamChallenge">
    <p>
      <label>Team</label>
    </p>
    <p>
      <mat-radio-group [(ngModel)]="team" name="team" *ngIf="challenge.teamChallenge">
        <ng-container *ngFor="let t of challenge.teams">
          <mat-radio-button [value]="t">
            {{t.name}}
          </mat-radio-button>
          <br/>
        </ng-container>
      </mat-radio-group>
    </p>
  </ng-container>

  <p *ngIf="error">
    {{error}}
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">Cancel</button>
  <button mat-button (click)="add()">
    Add
    <app-spin [active]="busy"></app-spin>
  </button>
</div>
