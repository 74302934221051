import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-reports-page',
  templateUrl: './reports-page.component.html',
})
export class ReportsPageComponent implements OnInit {

  selectedIndex: number
  personUuid: string

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

}
