import {Component, OnInit} from '@angular/core';
import * as statera from '@vimscore/statera-client';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-inspect-prescriptions',
  templateUrl: './inspect-prescriptions.component.html',
  styleUrls: ['./inspect-prescriptions.component.css']
})
export class InspectPrescriptionsComponent implements OnInit {
  prescriptions: Array<statera.PrescriptionDto> = [];

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.data.subscribe((data: {
      activities: Array<statera.ActivityAndWorkout>,
      personInfo: statera.PersonInfoDto
    }) => {
      this.prescriptions = data.personInfo.prescriptions
        .sort((a, b) => -a.createdDate.localeCompare(b.createdDate));
    });
  }
}
