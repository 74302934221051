import {HttpErrorResponse} from "@angular/common/http";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Observable, of, PartialObserver} from "rxjs";
import {extractErrorMessage} from "./errors";

export interface BusyComponent {
  busy: boolean
  error: string
}

export function busyComponent<T>(self: BusyComponent): PartialObserver<T> {
  return {
    next(_: T): void {
      self.busy = false;
    },
    error(res) {
      self.busy = false;
      self.error = extractErrorMessage(res);
    }
  }
}

export function errorOperator<T>(self: BusyComponent) {
  return (err: any, _: Observable<T>): Observable<T> => {
    self.busy = false
    self.error = extractErrorMessage(err)
    return of(err)
  }
}

export interface BusyDialogComponent {
  busy: boolean
  error: string
  dialogRef: MatDialogRef<any>
}

export function busyDialog<T>(self: BusyDialogComponent): PartialObserver<T> {
  return {
    next(t: T): void {
      self.busy = false;
      self.dialogRef.close(t);
    },
    error(res) {
      self.busy = false;
      const error = extractErrorMessage(res)
      if (error) {
        self.error = error
      } else {
        self.dialogRef.close(false);
      }
    }
  }
}

export function errorDialog(dialog: MatDialog) {
  return (err) => {
    let error = "Unknown error";
    if (err instanceof HttpErrorResponse) {
      error = err.error;
    }

    console.log("Unexpected error: ", err);
    console.log("message", error);
    // dialog.open();
  }
}

export function errorDialogOperator<T>(_: MatDialog) {
  return (err: any, caught: Observable<T>): Observable<T> => {
    const error = extractErrorMessage(err);

    console.log("Unexpected error: ", err);
    console.log("message", error);
    // dialog.open();

    return of(err)
  }
}
