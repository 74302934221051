import {Pipe, PipeTransform} from '@angular/core';
import {Duration} from '../utils/duration';

@Pipe({
  name: 'cast4tuneDuration'
})
export class Cast4tuneDurationPipe implements PipeTransform {

  transform(str: string): Duration {
    switch (str) {
      case 'XXXS':
      case 'XXXS - 2 minutes': return new Duration(2, 0);
      case 'XXS':
      case 'XXS - 5 minutes': return new Duration(5, 0);
      case 'XS':
      case 'XS - 10 minutes': return new Duration(10, 0);
      case 'S':
      case 'S - 15 minutes': return new Duration(15, 0);
      case 'SM':
      case 'SM - 20 minutes': return new Duration(20, 0);
      case 'M':
      case 'M - 30 minutes': return new Duration(30, 0);
      case 'L':
      case 'L - 45 minutes': return new Duration(45, 0);
      case 'XL':
      case 'XL - 60 minutes': return new Duration(60, 0);
      case 'XXL':
      case 'XXL - 90 minutes': return new Duration(90, 0);
      case 'XXXL':
      case 'XXXL - 120 minutes': return new Duration(120, 0);
    }
    return null;
  }
}
