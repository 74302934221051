<mat-toolbar>
  <a mat-icon-button routerLink="..">
    <mat-icon>arrow_back</mat-icon>
  </a>
  <span>Person: {{personUuid}}</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>

<mat-tab-group animationDuration="0ms"
               [selectedIndex]="selectedIndex"
               (selectedIndexChange)="onTabChanged($event)">
  <mat-tab label="Profile">
    <app-inspect-profile></app-inspect-profile>
  </mat-tab>
  <mat-tab label="Personal Goals">
    <app-inspect-personal-goals></app-inspect-personal-goals>
  </mat-tab>
  <mat-tab label="Week Summaries">
    <app-inspect-person-week-summary></app-inspect-person-week-summary>
  </mat-tab>
  <mat-tab label="Assessments">
    <app-inspect-assessments></app-inspect-assessments>
  </mat-tab>
  <mat-tab label="Prescriptions">
    <app-inspect-prescriptions></app-inspect-prescriptions>
  </mat-tab>
  <mat-tab label="Periods">
    <app-inspect-periods></app-inspect-periods>
  </mat-tab>
  <mat-tab label="Workouts">
    <app-inspect-workouts></app-inspect-workouts>
  </mat-tab>
  <mat-tab label="Activities">
    <app-inspect-activities></app-inspect-activities>
  </mat-tab>
</mat-tab-group>
