import * as statera from '@vimscore/statera-client';
import {ActivatedRoute, Router} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {TabIndex} from "../../../utils/fragment";
import {YearWeek} from "../../../utils/year-week";

@Component({
  selector: 'app-challenge-week-page',
  templateUrl: './challenge-week-page.component.html',
  styleUrls: ['./challenge-week-page.component.css']
})
export class ChallengeWeekPageComponent implements OnInit {
  private readonly challengeUuid: string
  tab: TabIndex
  yearWeek: YearWeek
  prevWeek: YearWeek
  nextWeek: YearWeek
  week?: statera.ChallengeWeekDto

  members: Array<statera.ChallengeMemberWeekDto>=[]
  teams: Array<statera.ChallengeTeamWeekDto> = []

  constructor(private route: ActivatedRoute,
              private router: Router,
              private challengeService: statera.ChallengeService) {
    this.challengeUuid = this.route.snapshot.paramMap.get("challengeUuid");
    this.tab = new TabIndex(this.route, this.router);
    this.route.params.subscribe(this.onLoad);
  }

  ngOnInit(): void {
    this.onLoad({yearWeek: this.route.snapshot.paramMap.get("yearWeek")})
  }

  private onLoad = (params: { yearWeek: string }) => {
    this.yearWeek = YearWeek.parse(params.yearWeek);

    if (this.yearWeek) {
      this.prevWeek = this.yearWeek.previous();
      this.nextWeek = this.yearWeek.next();

      this.challengeService.getChallengeWeek(this.challengeUuid, this.yearWeek.toString())
        .subscribe((week) => {
          this.week = week

          this.teams = week.teams || []
          this.teams.sort((a, b) => a.name.localeCompare(b.name))
          this.members = week.members || []
          this.members.sort((a, b) => a.name.localeCompare(b.name))
        });
    }
  }
}
