<h2 mat-dialog-title>Create person</h2>
<div mat-dialog-content>
  <h3>General</h3>
  <p>
    Either email address or phone number is required. Both can be specified. The email address is the only way a user
    can log in with for now.
  </p>
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="person.name" name="name">
  </mat-form-field>
  <br/>

  <mat-form-field>
    <mat-label>Email</mat-label>
    <input matInput [(ngModel)]="person.emailAddress" name="emailAddress">
  </mat-form-field>
  <br/>

  <mat-form-field>
    <mat-label>Phone number</mat-label>
    <input matInput [(ngModel)]="person.phoneNumber" name="phoneNumber">
  </mat-form-field>
  <br/>

  <h3>Age</h3>
  <p>
    Only specify Age if you don't have the date of birth.
  </p>
  <mat-form-field>
    <mat-label>Date of birth (YYYY-MM-DD)</mat-label>
    <input matInput [(ngModel)]="person.dateOfBirth" name="dateOfBirth">
  </mat-form-field>
  <br/>

  <mat-radio-group [(ngModel)]="person.age" name="age">
    <ng-container *ngFor="let a of ages">
      <mat-radio-button [value]="a">
        {{a|enum2string}}
      </mat-radio-button>
      <br/>
    </ng-container>
  </mat-radio-group>

  <h3>Gender</h3>
  <mat-radio-group [(ngModel)]="person.gender" name="gender">
    <ng-container *ngFor="let g of genders">
      <mat-radio-button [value]="g">
        {{g.toString()|titlecase}}
      </mat-radio-button>
      <br/>
    </ng-container>
  </mat-radio-group>

  <h3>Activity Level</h3>
  <mat-radio-group [(ngModel)]="person.activityLevel" name="activityLevel">
    <ng-container *ngFor="let al of activityLevels">
      <mat-radio-button [value]="al">
        {{al.toString()|titlecase}}
      </mat-radio-button>
      <br/>
    </ng-container>
  </mat-radio-group>

  <h3>Capacity Scores</h3>
  <mat-form-field>
    <mat-label>Strength</mat-label>
    <input matInput type="number" [(ngModel)]="person.assessment.strength" name="strength">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Endurance</mat-label>
    <input matInput type="number" [(ngModel)]="person.assessment.endurance" name="endurance">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Balance</mat-label>
    <input matInput type="number" [(ngModel)]="person.assessment.balance" name="balance">
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Flexibility</mat-label>
    <input matInput type="number" [(ngModel)]="person.assessment.flexibility" name="flexibility">
  </mat-form-field>
</div>
<div mat-dialog-actions *ngIf="error">
  <p>
    {{error}}
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="">Cancel</button>
  <button mat-button (click)="save()">
    Save
    <app-spin [active]="busy"></app-spin>
  </button>
</div>
