import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatButtonModule} from "@angular/material/button";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDialogModule} from "@angular/material/dialog";
import {MatDividerModule} from "@angular/material/divider";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatToolbarModule} from "@angular/material/toolbar";
import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";

import {AccountSearchComponent} from './account-search/account-search.component';
import {ButtonRowComponent} from './button-row/button-row.component';
import {CapacityIconComponent} from './capacity-icon/capacity-icon.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {CopyToClipboardComponent} from './copy-to-clipboard/copy-to-clipboard.component';
import {FileUploadComponent} from './file-upload/file-upload.component';
import {FooterComponent} from './footer/footer.component';
import {HeaderNavComponent} from './header-nav/header-nav.component';
import {InlineDateRangeComponent} from './inline-date-range/inline-date-range.component';
import {JobResponseTableComponent} from './job-response-table/job-response-table.component';
import {LoaderComponent} from './loader/loader.component';
import {SpinComponent} from './spin/spin.component';
import {WeekNavigatorComponent} from './week-navigator/week-navigator.component';

@NgModule({
  declarations: [
    AccountSearchComponent,
    ButtonRowComponent,
    CapacityIconComponent,
    ConfirmDialogComponent,
    CopyToClipboardComponent,
    FileUploadComponent,
    FooterComponent,
    HeaderNavComponent,
    InlineDateRangeComponent,
    JobResponseTableComponent,
    LoaderComponent,
    SpinComponent,
    WeekNavigatorComponent,
  ],
  exports: [
    AccountSearchComponent,
    ButtonRowComponent,
    CapacityIconComponent,
    ConfirmDialogComponent,
    CopyToClipboardComponent,
    FileUploadComponent,
    FooterComponent,
    HeaderNavComponent,
    InlineDateRangeComponent,
    JobResponseTableComponent,
    LoaderComponent,
    SpinComponent,
    WeekNavigatorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    ReactiveFormsModule,
    RouterModule,
  ]
})
export class ConsoleComponentsModule {
}
