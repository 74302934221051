import {Component, OnInit} from '@angular/core';
import * as statera from '@vimscore/statera-client';

@Component({
  selector: 'app-vis-exercise-list-page',
  templateUrl: './vis-exercises-page.component.html',
  styleUrls: ['./vis-exercises-page.component.css']
})
export class VisExercisesPageComponent implements OnInit {

  public busy: boolean = false
  public allExercises: Array<statera.VisExerciseDto>
  public exercises: Array<statera.VisExerciseDto>
  public capacity = ""
  public capacities = ["STRENGTH", "ENDURANCE", "BALANCE", "FLEXIBILITY"]
  public capacityLevelBasic = false
  public capacityLevelActive = false
  public capacityLevelSporty = false
  public capacityLevelExtreme = false

  public gym = false
  public home = false
  public outdoor = false

  public approvedOnly: boolean = false

  constructor(private consoleService: statera.ConsoleService) { }

  ngOnInit(): void {
    const self = this;

    this.busy = true;
    this.consoleService.listVisExercises().subscribe({
      async next(exercises) {
        self.allExercises = exercises;
        self.search();
      },
      async complete() {
        self.busy = false;
      },
    })
  }

  approvedFilter(e: statera.VisExerciseDto): boolean {
    return !this.approvedOnly || e.approved;
  }

  capacityFilter(e: statera.VisExerciseDto): boolean {
    return this.capacity == "" || e.capacity == this.capacity;
  }

  clearCapacityLevelFilter(): boolean {
    this.capacityLevelBasic = false;
    this.capacityLevelActive = false;
    this.capacityLevelSporty = false;
    this.capacityLevelExtreme = false;

    this.search();

    return false;
  }

  capacityLevelFilter(e: statera.VisExerciseDto): boolean {
    if (!this.capacityLevelBasic &&
      !this.capacityLevelActive &&
      !this.capacityLevelSporty &&
      !this.capacityLevelExtreme) {
      return true;
    }

    return (!this.capacityLevelBasic || e.capacityLevelBasic) &&
      (!this.capacityLevelActive || e.capacityLevelActive) &&
      (!this.capacityLevelSporty || e.capacityLevelSporty) &&
      (!this.capacityLevelExtreme || e.capacityLevelExtreme);
  }

  gymFilter(e: statera.VisExerciseDto): boolean { return !this.gym || e.gym;}

  homeFilter(e: statera.VisExerciseDto): boolean { return !this.home || e.home;}

  outdoorFilter(e: statera.VisExerciseDto): boolean { return !this.outdoor || e.outdoor;}

  search(): void {
    this.exercises = this.allExercises
      .filter(this.approvedFilter, this)
      .filter(this.capacityFilter, this)
      .filter(this.capacityLevelFilter, this)
      .filter(this.gymFilter, this)
      .filter(this.homeFilter, this)
      .filter(this.outdoorFilter, this)
      .sort((a, b) => a.externalId.localeCompare(b.externalId))
  }
}
