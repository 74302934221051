<mat-toolbar color="primary">
  <a mat-icon-button routerLink="/" routerLinkActive="active"><mat-icon>home</mat-icon></a>
  <a mat-icon-button routerLink="/inspector" routerLinkActive="active"><mat-icon>face</mat-icon></a>
  <a mat-icon-button routerLink="/users" routerLinkActive="active"><mat-icon>verified_user</mat-icon></a>
  <a mat-icon-button routerLink="/exercise/animations" routerLinkActive="active"><mat-icon>accessibility_new</mat-icon></a>
  <a mat-button href="{{ settings._4tuneUrl}}?jwt={{ authenticatedUser && authenticatedUser.jwtToken }}">4tune</a>
  <span style="flex: 1 1 auto;"></span>
  <span>{{ authenticatedUser && authenticatedUser.email }}</span>
  <button mat-button (click)="logout()">logout</button>
</mat-toolbar>
