import * as statera from '@vimscore/statera-client';

export const capacities: Array<statera.Capacity> = [
  statera.Capacity.Strength,
  statera.Capacity.Endurance,
  statera.Capacity.Balance,
  statera.Capacity.Flexibility,
];

export const genders: Array<statera.Gender> = [
  statera.Gender.Male,
  statera.Gender.Female,
  statera.Gender.Other,
];

export const activityLevels: Array<statera.ActivityLevel> = [
  statera.ActivityLevel.Sedentary,
  statera.ActivityLevel.Sporty,
  statera.ActivityLevel.Vigorous,
];

export const ages: Array<statera.Age> = [
  statera.Age.Age1824,
  statera.Age.Age2534,
  statera.Age.Age3549,
  statera.Age.Age5059,
  statera.Age.Age6069,
  statera.Age.Age70,
];

export const testTypes: Array<statera.TestType> = [
  statera.TestType.VimScoreTest,
  statera.TestType.SelfTest,
];
