<mat-toolbar>
  <span>VimScore Console</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>
<div class="basic-container">

  <ul>
    <li>
      <a routerLink="/inspector">Inspector</a>
    </li>
    <li>
      <a routerLink="/console-account">Console accounts</a>
    </li>
    <li>
      <a routerLink="/vis/exercise">Exercises</a>
    </li>
    <li>
      <a routerLink="/exercise/animations">Animations</a>
    </li>
    <li>
      <a routerLink="/report">Reports</a>
    </li>
    <li>
      <a routerLink="/spond">Spond</a>
    </li>
    <li>
      <a routerLink="/challenge">Challenge</a>
    </li>
    <li>
      <a routerLink="/nbar-exercises">N-Bar</a>
    </li>
    <li>
      <a routerLink="/misc/qr-code">QR Code</a>
    </li>
    <li>
      <a routerLink="/job">Jobs</a>
    </li>
  </ul>

  <h2>Services</h2>

  <mat-card class="service" style="width: 100px; height: 120px; padding-bottom: 30px" *ngFor="let s of services">
    <mat-card-header style="min-height: 4em">
      <mat-card-title>{{s.name}}</mat-card-title>
    </mat-card-header>
    <div class="mat-card-image text-center" style="display: block">
      <a href="{{s.url}}">
        <img src="{{ s.logo }}"
             alt="{{ s.name }} logo"
             title="{{ s.description }}"
             style="box-sizing: content-box; max-height: 75px; max-width: 75px;"
        >
      </a>
    </div>
  </mat-card>

</div>
