<mat-toolbar>
  <span>Inspector</span>
  <span style="flex: 1 1 auto;"></span>
</mat-toolbar>
<app-button-row>
  <button class="mat-button mat-primary" (click)="showCreatePerson()">Create person</button>
</app-button-row>
<div class="basic-container">
  <h2>Account lookup</h2>
  <form #form="ngForm">
    <app-account-search [accountSearchForm]="accountSearchForm"></app-account-search>
  </form>
</div>
